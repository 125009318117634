import React from 'react'

import Speedometr from "./Speedometr";

import './CrashAnimate.scss'

export default function CrashChart({setX}) {

    return (
        <div className="crash-chart">
            <div className="crash-chart-background">
                <Speedometr setX={setX} />
            </div>
        </div>
    )
}

