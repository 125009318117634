import React from 'react';

import MoneyBlock from "../../../components/UI/MoneyBlock/MoneyBlock";

import './Statistic.scss';
const Statistic = ({weeklyWin, weeklyBet, forAllTime}) => {
  let winrate = ((weeklyWin / weeklyBet) * 100).toFixed(2);
  const moneyStatistics = [
    {title: 'Rakeback', time: 'total', num: (forAllTime || 0).toFixed(2), isCoin: true},
    {title: 'Win rate', time: 'past 7 days', num: (isNaN(winrate) ? "0" : winrate) + "%", isCoin: false},
    {title: 'Total bets', time: 'past 7 days', num: (weeklyBet).toFixed(2), isCoin: true},
    {title: 'Total win', time: 'past 7 days', num: (weeklyWin).toFixed(2), isCoin: true},
  ]

  return (
    <div className="rakeback__statistic statistic">
      <div className="statistic__title">Statistics</div>
      <div className="statistic__wrapper">
        {moneyStatistics.map(card =>
          <MoneyBlock money={card.num} title={card.title} subtitle={card.time} icon={card.isCoin} key={card.title} />
        )}
      </div>
    </div>
  );
};

export default Statistic;