import {io} from "socket.io-client";
import {store} from '../index'
import {
    addRound,
    clearChat,
    showError,
    getAppDataHandler,
    addRoulleteRound,
    addStreetraceRound,
    deleteWithdraw,
    showTrade,
    updateBalance,
    changeLevel,
    deleteMessage,
    showTrackRace,
    getMinesBattleCurrentRoundHandler,
    getMinesBattleHandler, setStage
} from "../redux/actions";
import {
    ADD_CASHOUT,
    ADD_GAME, ADD_MINES_GAME,
    ADD_ROULLETE_BET,
    ADD_STREETRACE_BET,
    CHANGE_BETS_ALL,
    CHANGE_LANG,
    CLEAR_ROULLETE_BETS_ALL,
    CHANGE_START,
    CURRENT_X,
    DELETE_SKIN, GAME_OVER,
    JOIN_GAME_RACE,
    JOIN_GAME_MINES,
    ONLINE,
    REMOVE_GAME, REMOVE_MINES,
    SEND_MESSAGE,
    SET_ROULLETE_STAGE,
    SET_STREETRACE_STAGE, UPDATE_BALANCE, UPDATE_SETTINGS,
    UPDATE_STATE,
    UPDATE_WITHDRAW,
    WINNER_ROULLET,
    WINNER_STREETRACE, UPDATE_STREETRACE_TIME, CLEAR_STREETRACE_BETS_ALL, UPDATE_ROULLETE_TIME, CLEAR_CURRENT_GAME
} from "../redux/types";

const socket = io('https://csgorace.com')

export function initSockets() {
    // socket listeners

    // mines
    socket.on('minesBattleCreate', data => {
        const user = store.getState().app.user

        data.p[0].b_a /= 100;
        if (user?.steamId === data.p[0].s_i) {
            store.dispatch({type: UPDATE_BALANCE, payload: -data.p[0].b_a})
        }
        store.dispatch({type: ADD_MINES_GAME, payload: data})
    })

    socket.on('minesBattleCancel', data => {
        const user = store.getState().app.user

        if (user?.steamId === data.p[0].s_i) {
            store.dispatch({type: UPDATE_BALANCE, payload: data.p[0].b_a / 100})
            store.dispatch({type: CLEAR_CURRENT_GAME})
        }
        store.dispatch({type: REMOVE_MINES, payload: data.r_i})
        store.dispatch(getMinesBattleHandler())

    })
    socket.on('minesBattleBet', data => {
        const user = store.getState().app.user

        if (user?.steamId === data.p[1].s_i) {
            const settings = {
                b_a: data.p[1].b_a / 100,
                f_t: data.f_t,
                s: data.s,
                f_s: data.f_s,
                a: data.a,
                m: data.m_m,
                b: false
            }
            store.dispatch({type: UPDATE_SETTINGS, payload: {...settings}})
        }

        if (user?.steamId === data.p[0].s_i) {
            store.dispatch(changeLevel(store.getState().app.server.mainXP * data.p[0].b_a))
        } else if (user?.steamId === data.p[1].s_i) {
            store.dispatch(changeLevel(store.getState().app.server.mainXP * data.p[0].b_a))
            store.dispatch({type: UPDATE_BALANCE, payload: -data.p[0].b_a / 100})
        }

        store.dispatch(getMinesBattleHandler())
        if (user?.steamId === data.p[0].s_i || user?.steamId === data.p[1].s_i) {
            store.dispatch(getMinesBattleCurrentRoundHandler(data.r_i))
        }
    })

    socket.on('minesBattleMove', data => {
        const user = store.getState().app.user
        const game = store.getState().mines.games.find(game => game.r_i === data.r_i);
        const currentGame = store.getState().mines.current_game;

        // Играем ли мы в принципе, для просмотра игр
        const isMeInGameNow = store.getState().mines.games.findIndex(game => user?.steamId === game?.p[0]?.s_i || user?.steamId === game?.p[1]?.s_i) !== -1;
        const onMines = store.getState().mines.on_mines

        if (!isNaN(data.v)) {
            data.v /= 100
        }

        if (onMines) {
            if (game && (user?.steamId === game?.p[0]?.s_i || user?.steamId === game?.p[1]?.s_i)) {
                if (data?.w) {
                    data.w_a /= 100

                    if (data.w === user?.steamId) store.dispatch({type: UPDATE_BALANCE, payload: data.w_a})

                    if (Object.keys(currentGame).length) store.dispatch({type: GAME_OVER, payload: data});

                } else {
                    store.dispatch(getMinesBattleCurrentRoundHandler(data.r_i))
                }
            } else if (!isMeInGameNow) {
                if (!Object.keys(currentGame).length) {
                    if (data?.w) {
                        setTimeout(() => {
                            store.dispatch({type: REMOVE_MINES, payload: data.r_i})
                        }, 5000);
                    } else {
                        store.dispatch(getMinesBattleHandler())
                    }
                } else if (Object.keys(currentGame).length && currentGame.r_i === data.r_i) {
                    if (data?.w) {
                        data.w_a /= 100

                        store.dispatch({type: GAME_OVER, payload: data});
                    } else {
                        store.dispatch(getMinesBattleCurrentRoundHandler(data.r_i))
                    }
                }
            }
        }

    })

    // app
    socket.on('languageChange', data => {
        store.dispatch({type: CHANGE_LANG, payload: data})
        store.dispatch(clearChat())
        store.dispatch(getAppDataHandler())
    })

    socket.on('chatMsg', data => {
        store.dispatch({type: SEND_MESSAGE, payload: {messages: [{...data}], update: true}})
    })

    socket.on('deleteMessage', data => {
        store.dispatch(deleteMessage(data))
    })

    socket.on('onlineUsers', data => {
        store.dispatch({type: ONLINE, payload: data})
    })

    // race

    socket.on('raceCreate', data => {
        data.f_p.b_a /= 100;
        data.state = 0;
        const user = store.getState().app.user
        const race = store.getState().race.race

        if (user?.steamId === data.f_p.s_i) {
            store.dispatch(updateBalance(data.f_p.b_a * -1, race))
        }
        store.dispatch({type: ADD_GAME, payload: {game: data}})
    })
    socket.on('raceCancel', data => {
        const game = store.getState().race.games.find(item => item.r_i === data.r_i)
        const race = store.getState().race.race
        const user = store.getState().app.user
        if (game && user?.steamId === game.f_p.s_i) {
            store.dispatch(updateBalance(game.f_p.b_a, race))
        }
        store.dispatch({type: REMOVE_GAME, payload: {...data}})
    })

    socket.on('raceResult', data => {
        data.s_p.b_a /= 100;
        data.f_p.b_a /= 100;
        data.s_p.w /= 100;
        data.f_p.w /= 100;
        data.w_a /= 100;
        const user = store.getState().app.user
        const race = store.getState().race.race

        store.dispatch({type: UPDATE_STATE, payload: {r_i: data.r_i, state: 1}})
        if (user?.steamId === data.s_p.s_i) {
            store.dispatch(changeLevel(store.getState().app.server.raceXP * data.s_p.b_a * 100))
            store.dispatch(updateBalance(data.s_p.b_a * -1, race))
            store.dispatch(showTrackRace(data))
        } else if (user?.steamId === data.f_p.s_i) {
            store.dispatch(changeLevel(store.getState().app.server.raceXP * data.s_p.b_a * 100))
            store.dispatch(showTrackRace(data))
        }
        setTimeout(() => {
            store.dispatch({type: UPDATE_STATE, payload: {r_i: data.r_i, state: 2}})
        }, 11000);
        setTimeout(() => {
            if (user?.steamId === data.w) {
                store.dispatch(updateBalance(data.w_a, race))
            }
        }, 13000)
        store.dispatch({type: JOIN_GAME_RACE, payload: data})
    })


    // crash

    socket.on('crashStart', data => {
        store.dispatch({type: CHANGE_START, payload: data.m})
        store.dispatch(setStage(1))
    })

    socket.on('crashWithdraw', data => {
        store.dispatch({type: CHANGE_START, payload: data.m})
        store.dispatch(setStage(2))
    })

    socket.on('crashResult', data => {
        store.dispatch({type: CHANGE_START, payload: data.m})
        store.dispatch({type: CURRENT_X, payload: data.X})
        store.dispatch(addRound(data))
        store.dispatch(setStage(0))

        setTimeout(() => {
            store.dispatch({type: CURRENT_X, payload: 1})
        }, 2000)
    })

    socket.on('crashBet', data => {
        data.b_a /= 100;
        const user = store.getState().app.user
        const crash = store.getState().crash.crash
        if (user?.steamId === data.s_i) {
            store.dispatch(updateBalance(data.b_a * -1, crash))
        }
        store.dispatch({type: CHANGE_BETS_ALL, payload: {data, clear: false}})
    })

    socket.on('crashCashout', data => {
        data.w /= 100;
        data.b_a /= 100;
        const user = store.getState().app.user
        const crash = store.getState().crash.crash

        if (user?.steamId === data.s_i) {
            store.dispatch(updateBalance(data.w > crash?.maxPayout ? crash?.maxPayout : data.w, crash))
        }
        store.dispatch({type: ADD_CASHOUT, payload: data})
    })

    // roullete

    socket.on("rouletteStart", data => {
        const roulette = store.getState().roullete.roullete
        if (roulette) {
            store.dispatch({type: WINNER_ROULLET, payload: null})
            store.dispatch({type: UPDATE_ROULLETE_TIME, payload: data.m})
            store.dispatch({type: SET_ROULLETE_STAGE, payload: 1})
        }
    })
    socket.on('rouletteResult', data => {
        const user = store.getState().app.user
        const spinningStage = store.getState().roullete.spinningStage
        const myBets = store.getState().roullete.betsAll.filter(item => item.s_i === user?.steamId)

        setTimeout(() => {
            myBets.forEach(myBet => {
                if (myBet && data.c === myBet.c) {
                    if (myBet.c === "Green") {
                        store.dispatch(updateBalance(myBet.b_a * 14))
                    } else {
                        store.dispatch(updateBalance(myBet.b_a * 2))
                    }
                }
            })
            store.dispatch({type: SET_ROULLETE_STAGE, payload: 0})
            store.dispatch(addRoulleteRound(data))
            store.dispatch({type: CLEAR_ROULLETE_BETS_ALL})
        }, spinningStage)
        store.dispatch({type: UPDATE_ROULLETE_TIME, payload: data.m})
        store.dispatch({type: WINNER_ROULLET, payload: data.c})
        store.dispatch({type: SET_ROULLETE_STAGE, payload: 2})
    })

    socket.on('rouletteBet', data => {
        data.b_a /= 100;
        const user = store.getState().app.user
        const roulette = store.getState().roullete.roullete
        if (user?.steamId === data.s_i) {
            store.dispatch(updateBalance(data.b_a * -1, roulette))
        }
        store.dispatch({type: ADD_ROULLETE_BET, payload: data})
    })


    // streetrace

    socket.on("streetraceStart", data => {
        const streetrace = store.getState().streetrace.streetrace
        if (streetrace) {
            store.dispatch({type: WINNER_STREETRACE, payload: null})
            store.dispatch({type: UPDATE_STREETRACE_TIME, payload: data.m})
            store.dispatch({type: SET_STREETRACE_STAGE, payload: 1})
        }
    })
    socket.on('streetraceResult', data => {
        const user = store.getState().app.user
        const streetrace = store.getState().streetrace.streetrace
        const myBets = store.getState().streetrace.betsAll.filter(item => item.s_i === user?.steamId)

        if (streetrace) {
            setTimeout(() => {
                myBets.forEach(myBet => {
                    if (myBet && data.c === myBet.c) {
                        store.dispatch(updateBalance(myBet.b_a * 3.75 > streetrace.maxPayout ? streetrace.maxPayout : myBet.b_a * 3.75, streetrace))
                    }
                })
                store.dispatch({type: SET_STREETRACE_STAGE, payload: 0})
                store.dispatch(addStreetraceRound(data))
                store.dispatch({type: CLEAR_STREETRACE_BETS_ALL})
            }, streetrace.spinningStage)

            store.dispatch({type: UPDATE_STREETRACE_TIME, payload: data.m})
            store.dispatch({type: WINNER_STREETRACE, payload: data.c})
            store.dispatch({type: SET_STREETRACE_STAGE, payload: 2})
        }
    })

    socket.on('streetraceBet', data => {
        data.b_a /= 100;

        const user = store.getState().app.user
        const streetrace = store.getState().streetrace.streetrace

        if (user?.steamId === data.s_i) {
            store.dispatch(updateBalance(data.b_a * -1, streetrace))
        }

        store.dispatch({type: ADD_STREETRACE_BET, payload: data})
    })

    // withdraw

    socket.on('waxpeerWithdrawal', data => {
        store.dispatch({type: DELETE_SKIN, payload: data})
    })

    socket.on('statusWaxpeer', data => {
        if (data.s === 1) {
            store.dispatch({type: UPDATE_WITHDRAW, payload: data})
            if (!window.location.pathname.includes('withdraw')) {
                store.dispatch(showTrade(data.i))
            }
        } else if (data.s === 2 || data.s === 3) {
            store.dispatch(deleteWithdraw(data))
        }
    })

    socket.on('connect_error', error => {
        store.dispatch(showError({text: 'Server is temporarily down', translate: false}))
    })
    socket.on('msg', error => {
        store.dispatch(showError({text: error, translate: false}))
    })
}

export default socket
