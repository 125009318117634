import React, {useEffect, useState} from 'react'

import {useTranslation} from 'react-i18next'

import {ReactComponent as Volume} from '../../../assets/volume.svg'
import {ReactComponent as Mute} from '../../../assets/volume_mute.svg'

import Popup from "../../Popups/PopupInfo/Popup";

import './GameHeader.scss'
import Coin from "../../UI/Coin/Coin";

// Компонент заголовка как шаблон для игры

//eslint-disable-next-line
const GameHeader =
  ({
     title = '',
     timer = '',
     time = null,
     start = null,
     bankroll = 0,
     icon = '',
     cut = false,
     mute = null,
     muteHandler = () => false,
     popupText = ''
   }) => {

    const {t} = useTranslation(['roullete'])
    const [timeDiff, setTimeDiff] = useState(new Date() - new Date(start))

    const tick = () => {
      setTimeDiff(new Date() - new Date(start))
    };

    useEffect(() => {
      const timerID = setInterval(() => tick(), 10);
      return () => clearInterval(timerID);
    })

    return (
      <div className="game-header">
        <div className="game-header-wrapper">
          <h2>{title}</h2>
          {popupText ? <Popup popupText={popupText}/> : ''}
          {mute !== null ? (mute ? <Mute onClick={muteHandler}/> : <Volume onClick={muteHandler}/>) : ''}</div>
        {!cut ? <div className="timer">
          {icon}
          {
            time
              ? <span>{timer} {(Math.max((time - timeDiff) / 1000, 0)).toFixed(2) + 's'}</span>
              : <span>{timer}</span>
          }
        </div> : ''}
        {!cut ? <div className="bankroll">
          {t('roullete:bankroll')}
          <div className="fuel">
            <Coin/>
            <span>{(+bankroll || 0).toFixed(2)}</span>
          </div>
        </div> : ''}
      </div>
    )
  }
export default GameHeader