import React, {useState} from 'react'

import { useSelector } from 'react-redux'

import {ReactComponent as Arrow} from "../../../assets/arrow-down-gray.svg";

import Profile from '../../UI/Profile/Profile'
import RouletteList from "./RouletteList";

import './RoulettePlayers.scss'

//eslint-disable-next-line
export default ({ bets, className = '', type }) => {


  const user = useSelector(state => state.app.user)
  const [activeRoulette, setActiveRoulette] = useState(false);


  return (
    <div className='roulette-player-body'>
      <div className={"roulette-player " + className} onClick={() => {setActiveRoulette(!activeRoulette)}}>
        <span className="roulette-player-amount">{bets.length}</span>
        <ul className="roulette-player-list">
          {bets.slice(0, 6).map((item, index) =>
            <li key={index} className={"bet-item " + (user?.steamId === item.s_i ? 'is-me' : '')}>
              <Profile item={item}/>
            </li>
          )}
        </ul>
        <div className={"roulette-player-others " + (activeRoulette ? 'active' : '')}>
          <ul className="player-others">
            {bets.slice(6, 9).map((item, index) =>
              <li key={index} className="player-other">
                <Profile item={item}/>
              </li>
            )}
          </ul>
          {bets.length > 6 && (<span className="player-show">+{bets.length - 6}</span>)}
          <Arrow className="player-show-arrow"/>
        </div>
      </div>
      <RouletteList bets={bets} type={type} className={className} activeRoulette={activeRoulette}/>
    </div>
  )
}