import React from 'react'

import {useSelector} from 'react-redux'
import Profile from '../../UI/Profile/Profile';

import './StreetraceList.scss'
import Coin from "../../UI/Coin/Coin";

//eslint-disable-next-line
export default ({bets, type, active}) => {

  const user = useSelector(state => state.app.user)
  const stage = useSelector(state => state.streetrace.stage)
  const previous = useSelector(state => state.streetrace.previous.slice(0, 10))
  const streamer = useSelector(state => state.app.streamer)
  const streetrace = useSelector(state => state.streetrace.streetrace)

  return (
      <ul className={'mobile-list ' + (active ? 'active' : '')}>
        {bets.map((item, index) =>
          <li key={index} className={"bet-item " + (user?.steamId === item.s_i ? 'mobile-is-me' : '')}>
          <Profile item={item}/>
          <span>{streamer && user?.steamId !== item.s_i ? '*'.repeat(item.u.length) : item.u}</span>

          <div className="bet-value">
            <Coin className={(stage === 0 ? (previous && previous[0]?.car === type ? 'green-bet' : 'red-bet') : '')}/>
            <div
              className={"bet " + (stage === 0 ? (previous && previous[0]?.car === type ? 'green-bet' : 'red-bet') : '')}>
              {stage === 0 ? (previous && previous[0].car === type
                  ? '+' + (item?.b_a * 3.75 > streetrace?.maxPayout
                  ? streetrace?.maxPayout : item?.b_a * 3.75).toFixed(2)
                  : '-' + (item?.b_a).toFixed(2))
                : (item?.b_a).toFixed(2)}
            </div>
          </div>
        </li>)}
      </ul>
  )
}