import React from 'react'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'
import './Profile.scss'

const Profile = ({item = {}}) => {

  const user = useSelector(state => state.app.user)
  const streamer = useSelector(state => state.app.streamer)

  function getColor(level) {
    return (level < 10 ? 'grey' : level < 20 ? 'blue' : level < 40 ? 'yellow' : level < 60 ? 'orange'
      : level < 80 ? 'violet' : level < 90 ? 'red' : level <= 100 ? 'fire  ' : '')
  }

  return (
    item?.s_i === "bot"
    ?
    <div className={'bets-avatar ' + getColor(item?.l)}>
      <img
        className={streamer && user?.steamId !== item?.s_i ? 'streamer' : ''} src={item?.a}
        alt=""/>
      <div className={"level " + getColor(item?.l)}>{item?.l}</div>
    </div>
    :
      (item
      ?
        <Link to={"/profile/" + item?.s_i} className={'bets-avatar ' + getColor(item?.l)}>
          <img
            className={streamer && user?.steamId !== item?.s_i ? 'streamer' : ''} src={item?.a}
            alt=""/>
          <div className={"level " + getColor(item?.l)}>{item?.l}</div>
        </Link>
        :
        <div className={'bets-avatar ' + getColor(item?.l)}>
          <img
            className={streamer && user?.steamId !== item?.s_i ? 'streamer' : ''} src={item?.a}
            alt=""/>
          <div className={"level " + getColor(item?.l)}>{item?.l}</div>
        </div>)
  )
}
export default Profile;