import React from 'react'

import {useTranslation} from "react-i18next";
import { useSelector } from 'react-redux'

import Profile from '../../UI/Profile/Profile';

import './Racer.scss'
import Coin from "../../UI/Coin/Coin";

const Racer = ({ racer, className = '', car = '', game = ''}) => {

  const streamer = useSelector(state => state.app.streamer)
  const user = useSelector(state => state.app.user)

  const {t} = useTranslation(['race'])

  if(!racer) return false

  return (
    <div className={"racer " + className + " " + (game.state === 2 && (game.w === racer.s_i ? 'win' : 'lose'))}>
      <div className="racer-images">
        <Profile item={racer}/>
      </div>
      <div className="racer-info">
        {game.state !== 2 ? (
          <div className="racer-bet">
            <Coin/>
            <span>{(new Intl.NumberFormat('ru-RU').format(+(racer.b_a)).toString().replace(',', '.'))}</span>
          </div>
        ) : game.state === 2 && (
          game.w === racer.s_i ?
            <div className="racer-bet win">
              <Coin/>
              <span>+{((new Intl.NumberFormat('ru-RU').format(+(racer.b_a)).toString().replace(',', '.'))*0.9).toFixed(2)}</span>
            </div> :
            <div className="racer-bet lose">
              <span>{t('race:loser')}</span>
            </div>
        )}
        <div className="racer-name">
          <div className={"racer-car " + className}></div>
          <span>{streamer && user?.steamId !== (racer.s_i) ? '*'.repeat((racer.u).length) : (racer.u)}</span>
        </div>
      </div>
    </div>
  )
}
export default Racer;
