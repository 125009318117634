import React from 'react';
import { Link } from 'react-router-dom'


import { ReactComponent as Close } from "../../../../assets/mines/close-popup.svg";
import {cancelGame, hideTrackRace, joinGame, showTrackRace} from '../../../../redux/actions';
import { ReactComponent as Flag } from "../../../../assets/race/racing-flag.svg";
import { useDispatch, useSelector } from "react-redux";
import './RacePopup.scss'

const RacePopup = ({ setOpen, game, joinRace, games }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.app.user)


  function getColor(level) {
    return (level < 10 ? 'grey' : level < 20 ? 'blue' : level < 40 ? 'yellow' : level < 60 ? 'orange'
      : level < 80 ? 'violet' : level < 90 ? 'red' : level <= 100 ? 'fire  ' : '')
  }
  return (
    <div className="race-popup" >
      <div className="race-popup__background" onClick={() => { setOpen(false) }} />
      <div className="race-popup__popup">
        <Close className="race-popup__close" onClick={() => { setOpen(false) }} />
        <div className="race-popup__title">
          <Flag />
          <span>Race</span>

        </div>
        {
          joinRace === true
          ?
          <div className="race-popup__text">Are you sure you want to delete this race and join
            <Link to={"/profile/" + game?.f_p?.s_i}>

              <span className="race-popup__user"> <div className={"race-popup__avatar " + getColor(game?.f_p?.l)}><img src={game?.f_p?.a} alt="" /></div> {game?.f_p?.u}</span>
            </Link>
            race?
          </div>
          :
          <div className="race-popup__text">Are you sure you want to delete this race and create a new one?</div>
        }
        <div className="race-popup__buttons">
          <div className="race-popup__button race-popup__button-cancel" onClick={() => { setOpen(false) }}>Cancel</div>
            {
              joinRace === true
            ?
            <div className="race-popup__button race-popup__button-delete" onClick={() => {
              dispatch(hideTrackRace(games.find(game => game?.f_p?.s_i === user?.steamId)?.r_i),
              dispatch(joinGame({id: game.r_i, amount: game.f_p.b_a})),
              dispatch(showTrackRace(game)), setOpen(false)) }} >Delete and join
            </div>
            :
            <div className="race-popup__button race-popup__button-delete" onClick={() => {
              dispatch(cancelGame(game?.r_i), setOpen(false))
              }} >Delete
            </div>
            }
        </div>
      </div>
    </div>
  );
};

export default RacePopup;