import React from 'react';

import {ReactComponent as Bot} from "../../../assets/mines/bot.svg";

import './Lock.scss'

const Lock = ({
                icon,
                text,
                btnText,
                btnDisabled = false,
                onClick = console.log,
                whiteColor = true,
                minesBot = false
              }) => {

  let backgroundColor = {backgroundColor: 'rgba(255, 255, 255, 0.1)', backdropFilter: 'blur(3px)'};
  if (!whiteColor) backgroundColor = {backgroundColor: 'rgba(27, 31, 42, 0.7)', backdropFilter: 'blur(10px)'};

  return (
    <div className="lock" style={backgroundColor}>
      <div className="lock__wrapper">
        {icon ? <img src={icon} alt="" className="lock__icon"/> : ''}
        {text ? <div className="lock__text">{text}</div> : ''}
        {btnText ? <button className="lock__button lock__button-play-now" disabled={btnDisabled} onClick={() => {
          onClick()
        }}>{minesBot ? <Bot/> : ''}{btnText}</button> : ''}
      </div>
    </div>
  );
};

export default Lock;