import axios from "axios";
import {store} from '../index'
import {showError} from "../redux/actions";

export const DOMAIN = 'https://csgorace.com/'
export const API_DOT_DOMAIN = 'https://api.csgorace.com/'
const ADMIN = 'https://admin.csgorace.com/'

const fetcher = axios.create({
  baseURL: 'https://api.csgorace.com/',
  withCredentials: true
})

fetcher.interceptors.request.use(null, error => {
  store.dispatch(showError({text: error.message, translate: false}))
  return Promise.reject(error);
})

fetcher.interceptors.response.use(null, error => {
  store.dispatch(showError({text: error.message, translate: false}))
  return Promise.reject(error);
})

export async function getAccount() {
  return fetcher({
    method: 'get',
    url: '/account_details'
  })
    .then(res => {
      const data = res.data;

      data.data.claimedRakeback /= 100;
      data.data.availableRakeback /= 100;
      data.data.dailyBet /= 100;
      data.data.dailyWithdraw /= 100;
      data.data.dailyWithdrawalLimit /= 100;
      data.data.monthlyBet /= 100;
      data.data.monthlyDeposit /= 100;
      data.data.referralProfit /= 100;
      data.data.totalBet /= 100;
      data.data.totalDeposit /= 100;
      data.data.totalWithdraw /= 100;
      data.data.totalWin /= 100;
      data.data.walletsBalance.balance /= 100;
      data.data.walletsBalance.promoBalance /= 100;
      data.data.walletsBalance.balance += data.data.walletsBalance.promoBalance;
      data.data.weeklyBet /= 100;
      data.data.weeklyWin /= 100;

      return data;
    })
}


export async function getServer() {
  return fetcher({
    method: 'get',
    url: '/get_server'
  })
    .then(res => res.data)
}

// chat
export async function getMessages(lang) {
  return fetcher({
    method: 'get',
    url: `/get_messages?language=${lang}`
  })
    .then(res => res.data)
}

export async function muteUser(data) {
  return fetcher({
    method: 'post',
    url: `${ADMIN}mute_player`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify(data)
  })
    .then(res => res.data)
}

export async function banUser(data) {
  return fetcher({
    method: 'post',
    url: `${ADMIN}ban_player`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify(data)
  })
    .then(res => res.data)
}

export async function activityReward(data) {
  return fetcher({
    method: 'post',
    url: `${ADMIN}praise_player`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify(data)
  })
    .then(res => res.data)
}

export async function getProfile(id) {
  return fetcher({
    method: 'get',
    url: `/get_user?steamId=${id}`
  })
    .then(res => {
      const data = res.data;
      data.data.dailyBet /= 100;
      data.data.monthlyBet /= 100;
      data.data.totalBet /= 100;
      data.data.totalDeposit /= 100;
      data.data.totalWin /= 100;
      data.data.totalWithdraw /= 100;

      return data;
    })
}

export async function getProfileRewards() {
  return fetcher({
    method: 'get',
    url: `${DOMAIN}free/get_achievements`
  })
    .then(res => {
      const data = res.data;

      if (data.data) {
        data.data.map(reward => {
          reward.amount /= 100;

          return reward;
        });
      }

      return data;
    })
}

export async function claimRewards(id) {
  return fetcher({
    method: 'post',
    url: `${DOMAIN}free/claim_achievement`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({id})
  })
    .then(res => res.data)
}

export async function getRedeem() {
  return fetcher({
    method: 'get',
    url: `/referrals/redeem_profit`
  })
    .then(res => res.data)
}

export async function getRefferals() {
  return fetcher({
    method: 'get',
    url: `/referrals/get_referrals`   // delete
  })
    .then(res => res.data)
}

export async function editCode(code) {
  return fetcher({
    method: 'post',
    url: `/referrals/edit_code`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({code})
  })
    .then(res => res.data)
}

export async function getProfit() {
  return fetcher({
    method: 'get',
    url: `/referrals/get_referrals_profit`
  })
    .then(res => {
      const data = res.data;

      data.data.available /= 100;
      if (data.data.level) {
        data.data.level = data.data.level.map(count => count / 100);
      }
      data.data.redeemedProfit /= 100;
      data.data.totalDeposit /= 100;

      return data;
    })
}

export async function referralCode(code) {
  return fetcher({
    method: 'post',
    url: `${DOMAIN}referrals/use_referral_code`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({code})
  })
    .then(res => res.data)
}

export async function sendCode(code) {
  return fetcher({
    method: 'post',
    url: `/use_promo`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({code})
  })
    .then(res => res.data)
}

export async function getProfileResults(game, id, offset) {
  return fetcher({
    method: 'get',
    url: `/${game}/get_user_rounds?steamId=${id}&offset=${offset}`
  })
    .then(res => {
      const data = res.data;
      if (data.data.rounds) {
        data.data.rounds.map(round => {
          if(round.winAmount) {
            round.winAmount /= 100;
          }
          if (round.players) {
            round.players.map(player => {
              player.b_a /= 100;

              return player
            })
          }

          return round;
        })
      }

      return data;
    })
}

export async function getTransactions(offset) {
  return fetcher({
    method: 'get',
    url: `/get_transactions?offset=${offset}`
  })
    .then(res => {
      const data = res.data;

      if (data.data) {
        data.data.map(withdraw => {
          withdraw.amountFuel /= 100;
          return withdraw;
        })
      }

      return data;
    })
}

export async function changeTradeUrl(tradeURL) {
  return fetcher({
    method: 'post',
    url: `/change_tradeurl`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({tradeURL})
  })
    .then(res => res.data)
}

export async function changeApiKey(apiKey) {
  return fetcher({
    method: 'post',
    url: `/change_apikey`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({api: apiKey})
  })
    .then(res => res.data)
}

// tickets
export async function getTickets() {
  return fetcher({
    method: 'get',
    url: `${DOMAIN}support/get_tickets`
  })
    .then(res => res.data)
}


export async function getTicket(id) {
  return fetcher({
    method: 'get',
    url: `${DOMAIN}support/get_ticket?id=${id}`
  })
    .then(res => res.data)
}

export async function sendTicketMessage(data) {
  return fetcher({
    method: 'post',
    url: `${DOMAIN}support/answer_ticket`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify(data)
  })
    .then(res => res.data)
}

export async function closeTicket(data) {
  return fetcher({
    method: 'post',
    url: `${DOMAIN}support/close_ticket`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify(data)
  })
    .then(res => res.data)
}


export async function createTicket(data) {
  return fetcher({
    method: 'post',
    url: `${DOMAIN}support/create_ticket`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify(data)
  })
    .then(res => res.data)
}

// tot

export async function getTot() {
  return fetcher({
    method: 'get',
    url: `/get_tot`
  })
    .then(res => {
      const data = res.data;

      if (data.data.daily) {
        data.data.daily.map(player => {
          player.dailyBet /= 100;
          return player;
        })
      }
      if (data.data.ever) {
        data.data.ever.map(player => {
          player.totalBet /= 100;
          return player;
        })
      }
      if (data.data.monthly) {
        data.data.monthly.map(player => {
          player.monthlyBet /= 100;
          return player;
        })
      }

      return data;
    })
}

export async function getTotRewards() {
  return fetcher({
    method: 'get',
    url: `/get_tot_rewards`
  })
    .then(res => {
      const data = res.data;

      if (data.data) {
        data.data.rewards.map(player => {
          player.amount /= 100;
          return player;
        })
      }

      return data;
    })
}

// withdraw

export async function getWithdraw(search) {
  return fetcher({
    method: 'get',
    url: `/withdraw/waxpeer/get_items?limit=100${search}`
  })
    .then(res => {
      const data = res.data;

      if (data.items) {
        data.items.map(item => {
          item.price /= 100;

          return item;
        });
      }

      return data;
    })
}

export async function outWithdraw(data) {
  return fetcher({
    method: 'post',
    url: `/withdraw/waxpeer/withdraw`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify(data)
  })
    .then(res => {
      const data = res.data;

      if (data.data) {
        data.data.price /= 100;
      }

      return data;
    })
}

export async function checkWithdraw() {
  return fetcher({
    method: 'get',
    url: `${DOMAIN}withdraw/waxpeer/check_withdrawals`
  })
    .then(res => {
      const data = res.data;

      if (data.data) {
        data.data.map(transaction => {
          transaction.price /= 100;

          return transaction;
        })
      }

      return data;
    })
}

// race

export async function getRace() {
  return fetcher({
    method: 'get',
    url: `/get_race`
  })
    .then(res => {
      const data = res.data;

      data.data.minBet /= 100;
      data.data.maxBet /= 100;
      if (data.data.games) {
        data.data.games.map(game => {
          if (game.players) {
            game.players.map(player => {
              player.b_a /= 100;

              return player;
            })
          }

          return game;
        });
      }

      return data;
    })
}

export async function getRaceRound(id) {
  return fetcher({
    method: 'get',
    url: `/race/round_data?roundId=${id}`
  })
    .then(res => {
      const data = res.data;
      data.data.winAmount /= 100;
      data.data.players.map(player => {
        player.b_a /= 100;
        return player;
      })
      return data
    })
}

// crash

export async function getCrash() {
  return fetcher({
    method: 'get',
    url: `/get_crash`
  })
    .then(res => {
      const data = res.data;

      data.data.maxBet /= 100;
      data.data.minBet /= 100;
      data.data.maxPayout /= 100;
      if (data.data.bets) {
        data.data.bets.map(bet => {
          bet.b_a /= 100;
          if (bet?.w) bet.w /= 100;

          return bet;
        })
      }

      return data;
    })
}

export async function getCrashPrevious() {
  return fetcher({
    method: 'get',
    url: `/crash/get_previous_rounds`
  })
    .then(res => res.data)
}

export async function getCrashRound(id) {
  return fetcher({
    method: 'get',
    url: `/crash/round_data?roundId=${id}`
  })
    .then(res => {
      const data = res.data;

      data.data.totalBet /= 100;
      if (data.data.players) {
        data.data.players.map(player => {
          player.b_a /= 100;
          return player;
        });
      }

      return data;
    })
}

// roullete

export async function getRoullete() {
  return fetcher({
    method: 'get',
    url: `/get_roulette`
  })
    .then(res => {
      const data = res.data;

      data.data.maxBet /= 100;
      data.data.maxPayout /= 100;
      data.data.minBet /= 100;
      if (data.data.bets) {
        data.data.bets.map(bet => {
          bet.b_a /= 100;

          return bet;
        })
      }

      return data;
    })
}

export async function getRoulletePrevious() {
  return fetcher({
    method: 'get',
    url: `/roulette/get_previous_rounds`
  })
    .then(res => res.data)
}

export async function getRoulleteRound(id) {
  return fetcher({
    method: 'get',
    url: `/roulette/round_data?roundId=${id}`
  })
    .then(res => {
      const data = res.data;

      data.data.totalBet /= 100;
      if (data.data.players) {
        data.data.players.map(player => {
          player.b_a /= 100;

          return player;
        });
      }

      return data;
    })
}

// streetrace

export async function getStreetrace() {
  return fetcher({
    method: 'get',
    url: `/get_streetrace`
  })
    .then(res => {
      const data = res.data;

      data.data.maxBet /= 100;
      data.data.minBet /= 100;
      data.data.maxPayout /= 100;
      if (data.data.bets) {
        data.data.bets.map(bet => {
          bet.b_a /= 100;
          return bet;
        });
      }

      return data;
    })
}

export async function getStreetracePrevious() {
  return fetcher({
    method: 'get',
    url: `/streetrace/get_previous_rounds`
  })
    .then(res => res.data)
}

export async function getStreetraceRound(id) {
  return fetcher({
    method: 'get',
    url: `/streetrace/round_data?roundId=${id}`
  })
    .then(res => {
      const data = res.data;

      data.data.totalBet /= 100;
      if (data.data.players) {
        data.data.players.map(player => {
          player.b_a /= 100;
          return player;
        });
      }

      return data;
    })
}

// hangar

export async function getHangars() {
  return fetcher({
    method: 'get',
    url: `${DOMAIN}free/get_hangars`
  })
    .then(res => {

      for (const lvl in res.data.data.hangars) {
        res.data.data.hangars[lvl].minAmount /= 100;
        res.data.data.hangars[lvl].maxAmount /= 100;
        res.data.data.hangars[lvl].price /= 100;
      }

      return res.data;
    })
}

export async function openHangar(level) {
  return fetcher({
    method: 'get',
    url: `${DOMAIN}free/open_hangar?level=${level}`
  })
    .then(res => res.data)
}


// deposit

export async function depositFreekassa(data) {
  return fetcher({
    method: 'post',
    url: `${DOMAIN}deposit/freekassa`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify(data)
  })
    .then(res => res.data)
}

export async function depositQiwi(data) {
  return fetcher({
    method: 'post',
    url: `${DOMAIN}deposit/qiwi`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify(data)
  })
    .then(res => res.data)
}

export async function depositCoinbase() {
  return fetcher({
    method: 'post',
    url: `${DOMAIN}deposit/coinbase`,
  })
    .then(res => res.data)
}

export async function depositSkins() {
  return fetcher({
    method: 'post',
    url: `${DOMAIN}deposit/skinsback`,
  })
    .then(res => res.data)
}

export async function getNotifications() {
  return fetcher({
    method: 'get',
    url: `/get_notifications`
  })
    .then(res => res.data)
}

export async function getBonuses() {
  return fetcher({
    method: 'get',
    url: `/free/get_bonuses`
  })
    .then(res => {
      const data = res.data;

      data.data.availableRakeback /= 100;
      data.data.giveawayAmount /= 100;

      return data;
    })
}

export async function getRakeback() {
  return fetcher({
    method: 'get',
    url: '/free/get_rakeback',
  })
    .then(res => res.data)
}

export async function claimRakeback() {
  return fetcher({
    method: 'post',
    url: '/free/claim_rakeback',
  })
    .then(res => {
      const data = res.data;

      data.data.amount /= 100;

      return data;
    })
}

export async function checkCondition(data) {
  return fetcher({
    method: 'post',
    url: `${DOMAIN}free/check_conditions`,
    data,
  })
    .then(res => res.data);
}

export async function dailyBonus(data) {
  return fetcher({
    method: 'post',
    url: `${DOMAIN}free/daily_bonus`,
    data: data
  })
    .then(res => res.data);
}

// Mines battles

export async function getMinesBattle() {
  return fetcher({
    method: 'get',
    url: `${API_DOT_DOMAIN}get_mines_battle`
  })
    .then(res => {
      const data = res.data;

      data.data.games.map(game => {
        game.players.map(player => {
          player.b_a /= 100
          return player;
        })
        return game;
      })
      data.data.maxBet /= 100;
      data.data.minBet /= 100;

      return data
    });
}

export async function getMinesBattleRound(id) {
  return fetcher({
    method: 'get',
    url: `${API_DOT_DOMAIN}mines_battle/round_data?roundId=${id}`
  })
    .then(res => {
      const data = res.data;
      data.data.winAmount /= 100;
      data.data.players.map(player => {
        player.b_a /= 100;
        return player;
      })
      return data
    });
}

// getProfileResult
// export async function getMinesBattle() {
//     return fetcher({
//         method: 'get',
//         url: `${API_DOT_DOMAIN}get_user_rounds?limit=number&offset=number&steamId=string`
//     })
//         .then(res => res.data);
// }

export async function getMinesBattleCurrentRound(id) {
  return fetcher({
    method: 'get',
    url: `${API_DOT_DOMAIN}mines_battle/current_round?roundId=${id}`
  })
    .then(res => {
      const data = res.data;

      if(data.data?.players) {
        data.data.players.map(player => {
          player.b_a /= 100;
          return player;
        })
      }

      if(data.data?.fields) {
        data.data.fields = data.data.fields.map(field => field.map(fieldY => fieldY.map(fieldX => (fieldX / 100 || fieldX))))
      }

      return data;
    });
}

export async function depositWaxpeer() {
  return fetcher({
    method: 'post',
    url: `${DOMAIN}deposit/waxpeer`,
  })
    .then(res => res.data);
}

export async function depositSkinsback() {
  return fetcher({
    method: 'post',
    url: `${DOMAIN}deposit/skinsback`,
  })
    .then(res => res.data);
}

export async function getDepositCurrencies() {
  return fetcher({
    method: 'get',
    url: `${DOMAIN}deposit/crypto/get_currencies`,
  })
    .then(res => {
      const data = res.data;

      data.data.currencies.map(crypto => {
        crypto.minDeposit /= 100;
        crypto.rate /= 100;

        return crypto;
      })

      return data;
    });
}
export async function getDepositAddress(wallet) {
  return fetcher({
    method: 'get',
    url: `${DOMAIN}deposit/crypto/get_address?wallet=${wallet}`,
  })
    .then(res => res.data);
}
//
// export async function dailyBonus(data) {
//   return fetcher({
//     method: 'post',
//     url: `${DOMAIN}free/daily_bonus`,
//     data: data
//   })
//     .then(res => res.data);
// }
//
// export async function dailyBonus(data) {
//   return fetcher({
//     method: 'post',
//     url: `${DOMAIN}free/daily_bonus`,
//     data: data
//   })
//     .then(res => res.data);
// }


export default fetcher
