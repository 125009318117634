import React from 'react';
import { useDispatch, useSelector } from "react-redux";

import MinesMine from "./MinesMine";
import Lock from "../../UI/Lock/Lock";
import {gameWithMinesBot} from "../../../redux/actions";

import './MinesGrid.scss'

const MinesGrid = ({mines, player, field, roundId, turn, bot, className}) => {
  const dispatch = useDispatch();

  const rowSize = Math.sqrt(mines);
  const user = useSelector(state => state.app.user);

  const addMines = () => {
    const fieldPlayer = [];
    for (let y = 0; y < rowSize; y++) {
      for(let x = 0; x < rowSize; x++) {
        fieldPlayer.push(<MinesMine x={x} y={y} roundId={roundId} value={field ? field[y][x] : null}/>)
      }
    }
    return fieldPlayer;
  }

  return (
    <div className={"mines__grid-wrapper " + className}>
      <div className={'mines__grid ' + (mines === 25 ? 'twenty-five' : mines === 16 && 'sixteen')
        + (turn ? "" : " disabled") + (player?.s_i === user.steamId ? " " : " me")} id={player?.s_i}>
        {addMines()}
      </div>
      {bot ? <Lock btnText={"Play with bot"} onClick={() => {dispatch(gameWithMinesBot(roundId))}} minesBot={true} /> : ''}
    </div>
  );
};
export default MinesGrid;