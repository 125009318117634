import {
    BANNER,
    BONUS,
    CHANGE_LANG,
    ERROR_HIDE,
    ERROR_SHOW,
    GET_NOTIFICATIONS,
    HIDE_PAY,
    LEVEL,
    LOADER,
    OLD,
    ONLINE,
    ONLOAD,
    OPEN_REWARD,
    SERVER,
    SHOW_PAY,
    STREAMER,
    UPDATE_BALANCE,
    UPDATE_CONDITION_MET,
    USER,
    ADD_INTERVAL,
    UPDATE_INTERVALS, UPDATE_USER_AVAILABLE_RAKEBACK
} from "./types"

const initialState = {
    test: 0,
    loader: false,
    onLoad: false,
    user: null,
    server: null,
    serverLatency: 0,
    streamer: false,
    online: 0,
    banner: true,
    bonus: null,
    error: {
        show: false,
        data: {
            text: 'Error',
            translate: false
        }
    },
    old: false,
    hidePay: false,
    notifications: [],
    intervals: [],
}

export const appReducer = ( state = initialState, action ) => {
    switch(action.type){
        case LOADER:
            return { ...state, loader : action.payload === null ? !state.loader : action.payload }
        case ONLOAD:
            return { ...state, onLoad: true }
        case USER:
            return { ...state, user : action.payload }
        case SERVER:
            return { ...state, server : action.payload, serverLatency: Date.now() - new Date(action.payload.syncTime).getTime(), online: action.payload.onlineUsers }
        case CHANGE_LANG:
            return { ...state, user : state.user ? {...state.user, language: action.payload } : null}
        case UPDATE_BALANCE:
            return {
                ...state,
                user: {
                    ...state.user,
                    walletsBalance: {
                        balance: +(state.user.walletsBalance.balance + action.payload),
                    }
                }
            }
        case ERROR_SHOW:
            return { ...state, error : {show : true, data: action.payload }}
        case ERROR_HIDE:
            return { ...state, error : {show : false, text: 'Error' }}
        case ONLINE:
            return { ...state, online : action.payload }
        case BANNER:
            return { ...state, banner: !state.banner}
        case OLD:
            return { ...state, old: action.payload }
        case BONUS:
            return { ...state, bonus: action.payload }
        case STREAMER:
            return { ...state, streamer: state.user?.permissions.status > 1 ? (action.payload !== undefined ? action.payload : !state.streamer) : false }
        case LEVEL:
            let newXp = state.user.xp + action.payload
            var newLevel = state.user.level
            while (newXp >= (8000*(1.11 ** (newLevel + 1) - 1)/(1.11 - 1)) && newLevel < 100) {
                newLevel = newLevel + 1
            }
            return { ...state, user: { ...state.user, xp: newXp, level: newLevel }}
        case HIDE_PAY:
            return { ...state, hidePay: true }
        case SHOW_PAY:
            return { ...state, hidePay: false }
        case GET_NOTIFICATIONS:
            return { ...state, notifications: action.payload.map(item => ({ ...item, moment: `${new Date(item.moment).getDate()}.${new Date(item.moment).getMonth() + 1}.${new Date(item.moment).getFullYear()} ` + (new Date(item.moment).getHours() < 10 ? '0' + new Date(item.moment).getHours() : new Date(item.moment).getHours()) + ':' +  (new Date(item.moment).getMinutes() < 10 ? '0' + new Date(item.moment).getMinutes() : new Date(item.moment).getMinutes())}))}
        case OPEN_REWARD:
            return { ...state, user: { ...state.user, achievements: { ...state.user.achievements, [action.payload] : 2 } } }
        case UPDATE_CONDITION_MET:
            return { ...state, user: { ...state.user, conditionsMet: action.payload }}
        case ADD_INTERVAL:
            return { ...state, intervals: state.intervals.push(action.payload) }
        case UPDATE_INTERVALS:
            return { ...state, intervals: action.payload }
        case UPDATE_USER_AVAILABLE_RAKEBACK:
            return { ...state, user: { ...state.user, availableRakeback: action.payload } }
        default:
            return state
    }
}
