import React, {useState} from 'react'

import {useSelector, useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {cancelGame, changeLevel, joinGame, showTrackRace, updateBalance} from '../../../redux/actions'
import Racer from './Racer'
import Helmet1 from '../../../assets/race/helmet1.png'
import Helmet2 from '../../../assets/race/helmet2.png'
import Profile from '../../UI/Profile/Profile';
import {ReactComponent as Flag} from "../../../assets/race/flag.svg";
import {ReactComponent as Cup} from "../../../assets/race/cup.svg";

import './RaceGame.scss'
import RacePopup from './RacePopup/RacePopup'
import {REMOVE_GAME} from "../../../redux/types";
import Coin from "../../UI/Coin/Coin";
import {store} from "../../../index";


export default function RaceGame({game, timeoutRaceAnimate}) {
  const user = useSelector(state => state.app.user)
  const games = useSelector(state => state.race.games)
  const dispatch = useDispatch()
  const [openPopup, setOpenPopup] = useState(false)
  const [joinRace, setJoinRace] = useState(true)

  const {t} = useTranslation(['race'])

  const openJoinPopup = () => {
    setOpenPopup(true)
    setJoinRace(true)
  }

  const buttonOnClick = (game) => {
    if (game.f_p && game.s_p) {
      dispatch(showTrackRace(game))
    } else if (game.f_p.s_i === user?.steamId) {
      dispatch(cancelGame(game.r_i))
    } else if (games.find(game => game?.f_p?.s_i === user?.steamId && game.state !== 2)) {
      openJoinPopup()
    } else {
      const myGame = games.find(game => game?.f_p?.s_i === user?.steamId || game?.s_p?.s_i === user?.steamId)
      if (myGame) dispatch({ type: REMOVE_GAME, payload: {r_i: myGame?.r_i} })
      dispatch(joinGame({id: game.r_i, amount: game.f_p.b_a}))
      dispatch(showTrackRace(game))
    }
  }

  const buttonText = () => {
    switch (game.state) {
      case 0:
        return game.f_p.s_i === user?.steamId ? t('race:cancel') : t('race:accept');
      case 1:
        return (
          <div className="race-game-nav-inner">
            <Flag className="race-game-nav-img"/>
            <span>{t('race:racing')}</span>
          </div>);
      case 2:
        if (user?.steamId !== game.s_p.s_i && user?.steamId !== game.f_p.s_i) {
          setTimeout(() => {
            dispatch({type: REMOVE_GAME, payload: {r_i: game.r_i}})
          }, 7000)
        }
        return (
          <div className="race-game-nav-inner">
            <Cup className="race-game-nav-img"/>
            <span className="race-game-nav-inner-nickname">{game.w === game.f_p.s_i ? game.f_p.u : game.s_p.u}</span>
            <span>wins!</span>
          </div>
        )
      default:
        if (game.w) {
          if (user?.steamId !== game.s_p.s_i && user?.steamId !== game.f_p.s_i) {
            setTimeout(() => {
              dispatch({type: REMOVE_GAME, payload: {r_i: game.r_i}})
            }, 7000)
          }
          return (
            <div className="race-game-nav-inner">
              <Cup className="race-game-nav-img"/>
              <span className="race-game-nav-inner-nickname">{game.w === game.f_p.s_i ? game.f_p.u : game.s_p.u}</span>
              <span>wins!</span>
            </div>
          )
        } else {
          return game.f_p.s_i === user?.steamId ? t('race:cancel') : t('race:accept');
        }
    }
  }

  return (
    <div className={"race-game " + (game.f_p.s_i === user?.steamId ? 'me' : '')}>
      <div className="race-game-body">
        {!game.s_p ? (
          <div className={"race-game-body-start " + (game.f_p.s_i === user?.steamId ? 'me' : '')}>
            <div className="racer-start">
              <Profile item={game.f_p}/>
              <div className="info">
                <span>{t('race:raceWith')}</span>
                <div className="nickname">{game.f_p.u}</div>
              </div>
            </div>
            <div className="racer-start-bet">
              <Coin/>
              <span>{game.f_p.b_a}</span>
            </div>
            <img src={game.f_p.s_i === user?.steamId ? Helmet2 : Helmet1} className={'helmet'} alt=""/>
          </div>
        ) : game.state === 1 ? (
          <div className='race-game-body-current'>
            <div className='race-game-body-text'>Racing</div>
            <Racer racer={game.f_p} className={'racer-blue'} game={game} car={'blue'}/>
            <Racer racer={game.s_p} className={'racer-red'} game={game} car={'red'}/>
          </div>
        ) : game.state === 2 && (
          <div className='race-game-body-current other-game-end'>
            <div className='race-game-body-text'>Winner</div>
            <Racer racer={game.f_p} className={'racer-blue'} game={game} car={'blue'}/>
            <Racer racer={game.s_p} className={'racer-red'} game={game} car={'red'}/>
          </div>
        )}
      </div>
      <button
        className={"race-game-nav " + (game.f_p && game.s_p ? 'current ' : game.f_p?.s_i === user?.steamId ? 'me ' : 'other ')}
        onClick={() => buttonOnClick(game)}>
        {buttonText()}
      </button>
      {openPopup ? <RacePopup setOpen={setOpenPopup} game={game} joinRace={joinRace} games={games}/> : ''}

    </div>
  )
}
