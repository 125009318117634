import React, { useEffect } from 'react';

import { useDispatch } from "react-redux";

import {changeLoader, claimRakebackHandler} from "../../../redux/actions";

import keyImg from '../../../assets/rakeback/key.png';
import bg from '../../../assets/rakeback/receive.png';
import coinImg from "../../../assets/coin.png";

import './Receive.scss';

const Receive = ({availableRakeback}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeLoader(true));
  }, [])

  const claim = () => {
    dispatch(claimRakebackHandler())
  }

  return (
    <div className="rakeback__receive receive">
      <img src={bg} alt="" className="receive__bg" />
      <h4 className="receive__title">You receive <br /> rackback every Monday</h4>
      <div className="receive__control">
        <div className="receive__text">
          <img src={keyImg} alt="" width={36} className="receive__key"></img>
          <p>Your Rakeback can <br/> be collected now</p>
        </div>
        <div className="receive__money rakeback-money">
          <img src={coinImg} alt="" className="receive__coin"></img>
          <div className="receive__number">{availableRakeback}</div>
          <button className="receive__button" onClick={claim}>Take now</button>
        </div>
      </div>
    </div>
  );
};

export default Receive;