import React, { useEffect } from 'react';

import { useDispatch, useSelector } from "react-redux";
import {
  getMinesBattleCurrentRoundHandler,
  cancelMines,
  gameWithMinesBot
} from "../../../redux/actions";

import { ReactComponent as TrashCan } from '../../../assets/trash-can.svg'

import GameNav from "../../Sections/GameNav/GameNav";
import GameHeader from "../GameHeader/GameHeader";
import MinesPlayer from "./MinesPlayer";
import MinesRounds from "./MinesRounds";
import MinesField from "./MinesField";
import Timer from "../../UI/Timer/Timer";

import './MinesGame.scss'
import Back from "../../../assets/arrow-bug.svg";
import {Link, useHistory} from "react-router-dom";
import GameOverMines from "../../Popups/GameOverMines/GameOverMines";
import {CLEAR_CURRENT_GAME, MINES_ON, REMOVE_MINES} from "../../../redux/types";
import Coin from "../../UI/Coin/Coin";

const MinesGame = ({ match }) => {
  const history = useHistory();

  if (isNaN(match.params.roundId)) {
    history.push('/mines')
  }

  const roundId = Number.parseInt(match.params.roundId);
  const dispatch = useDispatch();
  const games = useSelector(state => state.mines.games);
  const currentGame = useSelector(state => state.mines.current_game);
  const isGameExist = games.findIndex(game => game?.r_i === roundId) !== -1;
  const minesData = useSelector(state => state.mines.mines);
  const settings = useSelector(state => state.mines.settings);
  const gameOver = useSelector(state => state.mines.game_over)

  const firstPlayer = Object.keys(currentGame).length ? currentGame.p[0] : null;
  const secondPlayer = Object.keys(currentGame).length && currentGame.p[1] ? currentGame.p[1] : null;
  const currentRound = currentGame?.m_s ? Math.floor(currentGame?.m_s / 2) + 1 : '';
  const currentStage = Object.keys(gameOver).length ? 3 : currentGame?.s_g;

  const firstPlayerSum = Object.keys(currentGame).length
    ? currentGame.f[0].reduce((acc, curY) => acc + curY.reduce((accX, curX) => accX + (curX === 'M' ? 0 : curX), 0), 0) : 0
  const secondPlayerSum = Object.keys(currentGame).length
    ? currentGame.f[1].reduce((acc, curY) => acc + curY.reduce((accX, curX) => accX + (curX === 'M' ? 0 : curX), 0), 0) : 0

  const totalCost = firstPlayer?.b_a;

  useEffect(() => {
    if (settings?.b && currentStage === 0 && currentGame?.r_i) {
      dispatch(gameWithMinesBot(currentGame.r_i));
    }
  }, [currentStage, currentGame])

  useEffect(() => {
    if (!isGameExist) {
      history.push('/mines')
    }
  }, [isGameExist])

  useEffect(() => {
    dispatch({type: MINES_ON, payload: {isMainPage: false, minesOn: true}})
    dispatch(getMinesBattleCurrentRoundHandler(roundId));

    return () => {
      dispatch({type: MINES_ON, payload: {isMainPage: false, minesOn: false}})
    }
  }, [])

  return (
    <div className="mines__game">
      <GameNav />
      {
        isGameExist
          ?
          <>
            <div className="mines__game-header">
              <Link className="mines__back" to="/mines" onClick={() => dispatch({type: CLEAR_CURRENT_GAME})}><img src={Back} alt="" /></Link>
              <GameHeader title={'Mines Battle'} cut={true} />
              <div className="mines__total-cost">
                Total cost:
                <div>
                  <Coin />
                  {totalCost}
                </div>
              </div>
            </div>
            <div className="mines__about-game-wrapper">
              {currentGame?.r_i && currentGame?.s && <div className="card__game-code">
                Game Code: {currentGame?.r_i}
              </div>}
              <div className="mines__info-wrapper">
                <div className="mines__about-game">
                  <MinesPlayer player={firstPlayer} turn={currentStage === 1} winAmount={firstPlayerSum} anotherWinAmount={secondPlayerSum} />
                  <div className={"mines__round " + (currentStage === 0 ? "waiting" : " ")}>
                    <MinesRounds currentRound={currentRound} rounds={currentGame?.a} map={currentGame?.f_t}
                      withMines={currentGame?.m_m} gameCode={currentGame?.r_i} privateGame={currentGame?.s} />
                    <div className={"mines__timer"}>
                      <Timer lastMove={currentGame?.m_t} maxMoveTime={minesData?.maxMoveTime} stage={currentStage} />
                    </div>
                  </div>
                  <MinesPlayer waiting={!secondPlayer} player={secondPlayer} turn={currentStage === 1} className="two"
                    winAmount={secondPlayerSum} anotherWinAmount={firstPlayerSum} />
                  {
                    !secondPlayer && (
                      <button className="mines__delete" onClick={() => dispatch(cancelMines(currentGame?.r_i))}>
                        <TrashCan />
                      </button>
                    )
                  }
                </div>

                <button className="mines__delete-bottom" style={currentStage === 0 ? {} : { transform: "scale(0)" }}
                  onClick={() => dispatch(cancelMines(currentGame?.r_i))}>
                  <TrashCan />
                  <span>End game</span>
                </button>

              </div>
              <MinesField currentStage={currentStage} roundId={currentGame?.r_i} fieldSize={currentGame?.f_s}
                fieldType={currentGame?.f_t} firstPlayer={firstPlayer} secondPlayer={secondPlayer} fields={currentGame?.f} />
              <div className="mines__timer-bottom">
                <Timer lastMove={currentGame?.m_t} maxMoveTime={minesData?.maxMoveTime} stage={currentStage} />
              </div>
              {currentGame?.r_i && currentGame?.s && <div className="mines__code-bottom">
                Game Code: {currentGame?.r_i}
              </div>}
            </div>
          </>
          :
          ''
      }
      {gameOver?.w && <GameOverMines currentGame={currentGame} firstPlayer={firstPlayer} secondPlayer={secondPlayer} bet={firstPlayer?.b_a} winner={currentGame?.w} />}
    </div>
  );
};
export default MinesGame;