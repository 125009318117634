import React from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'

import {ReactComponent as Fuel} from '../../../assets/fuel.svg'
import bot from '../../../assets/race/bot.svg'

import './GameAmount.scss'
import {createGame} from "../../../redux/actions";

// Для режимов где можно ставку поставить, собственно поле со ставкой своей


//eslint-disable-next-line
const GameAmount = ({rates = [], changeAmount = () => false, game = {}, amount = '', button = '', withBot = false}) => {

  const {t} = useTranslation(['roullete'])

  const user = useSelector(state => state.app.user)
  const dispatch = useDispatch()

  return (
    <div className={"game-amount " + (button ? 'radius' : '')}>
      <div className="amount">
        <span className="amount-header">{t('roullete:amount')}</span>
        <div className="game-control">
          <div className="amount-value">
            <Fuel/>
            <input type="text" value={amount} disabled={!user} step="0.01" placeholder='Enter amount'
                   min={game?.minBet || 0.01} onChange={event => dispatch(changeAmount(event.target.value))}/>
            <div className="rates">
              <div className="rates-body">
                <div className="rates-body-clear" onClick={() => dispatch(changeAmount(''))}>{t('roullete:clear')}</div>
                <ul className="rates-body-list">
                  {rates.map(item => <li key={item.id}
                                         onClick={() => user ? dispatch({type: item.do}) : false}>{item.name}</li>)}
                </ul>
              </div>
            </div>
          </div>
          <div className="game-start-buttons">
            {
              withBot
              ?
              <>
                <button className="race-create race-create-bot 2" onClick={() => dispatch(createGame(amount, true, null))}>
                  <img src={bot} className="race-bot-iconx" alt=""/>
                  <span>Play with bot</span>
                </button>
                <span>or</span>
              </>
              :
              ''
            }
            {button}
          </div>
        </div>
        <div className="amount-values">
          <span>{t('roullete:minBet')} <span>{game?.minBet}</span></span>
          <span>{t('roullete:maxBet')} <span>{game?.maxBet}</span></span>
          <span>{t('roullete:maxWin')}
            <span>{game?.maxPayout || (game?.maxBet || 0) * 2}</span>
                    </span>
        </div>
      </div>
    </div>
  )
}
export default GameAmount