import React, {useState} from 'react'

import {useSelector} from 'react-redux'

import { ReactComponent as Arrow } from '../../../assets/arrow-down-gray.svg'

import Profile from '../../UI/Profile/Profile';
import StreetraceList from "./StreetraceList";

import './StreetracePlayers.scss'

//eslint-disable-next-line
const StreetracePlayers = ({bets, className = '', type}) => {

  const user = useSelector(state => state.app.user)
  const [active, setActive] = useState(false);

  return (
    <div className='streetrace-player-body'>
      <div className={"streetrace-player " + className} onClick={() => {setActive(!active)}}>
        <span className="streetrace-player-amount">{bets.length}</span>
        <ul className="streetrace-player-list">
          {bets.slice(0, 6).map((item, index) =>
            <li key={index} className={"bet-item " + (user?.steamId === item.s_i ? 'is-me' : '')}>
              <Profile item={item}/>
            </li>
          )}
        </ul>
        <div className={"streetrace-player-others " + (active ? 'active' : '')}>
          <ul className="player-others">
            {bets.slice(6, 9).map((item, index) =>
              <li key={index} className="player-other">
                <Profile item={item}/>
              </li>
            )}
          </ul>
          {bets.length > 6 && (<span className="player-show">+{bets.length - 6}</span>)}
          <Arrow className="player-show-arrow"/>
        </div>
      </div>
      <StreetraceList bets={bets} type={type} className={className} active={active}/>
    </div>
  )
}
export default StreetracePlayers;