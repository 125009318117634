import React from 'react';

import Coin from "../Coin/Coin";

const ProgressBar = ({max = 100, value = 10, isDisplayProgress = false, ObjectClassName = ""}) => {

  const progressBarStyle = {
    color: '#FFF',
    display: 'flex'
  }

  const wrapperStyle = {
    position: 'relative',
    flex: '1 0 auto',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '50px'

  }

  const valueStyle = {
    width: (value / max * 100) > 100 ? '100%' : `${value / max}%`,
    height: '13px',
    background: '#4B68FF',
    borderRadius: '50px',
    position: 'absolute',
    top: 0,
    left: 0
  }

  const textStyle = {
    display: 'flex',
    color: '#FFF',
    zIndex: '100',
    fontSize: '12px',
    marginRight: '10px',
    fontFamily: 'Hauora',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '0.5px'
  }

  return (
    <div className={ObjectClassName + "__progress"} style={progressBarStyle}>

      { isDisplayProgress
        ?
        <div className={ObjectClassName + "__text"} style={textStyle}>
          <Coin width='14px' height='14px' />
          {Math.min(value, max).toFixed(2)} /
          <Coin width='14px' height='14px'/>
          <div className={ObjectClassName + "__text " + ObjectClassName + "__text-second"}>
            {max.toFixed(2)}
          </div>
        </div> : '' }

      <div className={ObjectClassName + "__wrapper"} style={wrapperStyle}>
        <div className={ObjectClassName + "__value"} style={valueStyle}></div>
      </div>
    </div>
  );
};

export default ProgressBar;