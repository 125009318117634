import React, {useEffect} from 'react'


import {Link, useLocation} from 'react-router-dom'

import {useTranslation} from 'react-i18next'

import {useSelector, useDispatch} from 'react-redux'
import {DOMAIN} from '../../../utilites/api'

import {changeNav} from '../../../redux/actions'

import Account from '../../Account'
import ButtonYellow from '../../UI/ButtonYellow/ButtonYellow'

import {ReactComponent as Twitter} from '../../../assets/social/tw.svg'
import {ReactComponent as Discord} from '../../../assets/social/ds.svg'
import {ReactComponent as Instagram} from '../../../assets/social/in.svg'
import {ReactComponent as Telegram} from '../../../assets/social/tg.svg'
import {ReactComponent as Steam} from '../../../assets/steam.svg'

import FundBug from '../../../assets/foundbug-nav.svg'

import './Nav.scss'

const Nav = () => {

  const {t} = useTranslation(['main', 'profile'])

  const user = useSelector(state => state.app.user)
  const nav = useSelector(state => state.nav.nav)
  const banner = useSelector(state => state.app.banner)
  const showTot = useSelector(state => state.app.server?.tot)

  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(changeNav())
  }, [location])

  return (
    <div className={"nav " + (banner ? 'banner-nav' : '')}>
      <div className="nav-menu">
        {user
          ?
          <>
            <Account/>
            <div className="nav-menu-btns-links">
              <Link to="/withdraw" className="nav-menu-btns-links-cash">{t('profile:cashOut')}</Link>
              <Link to="/deposit"><ButtonYellow
                className="nav-menu-btns-links-deposit">{t('profile:deposit')}</ButtonYellow></Link>
            </div>
          </>
          :
          <a href={`${DOMAIN}auth/steam`} className={"account-btn"}>
            <Steam/>
            <span>Login with Steam</span>
          </a>
        }
        <ul className="nav-menu-list">
          {nav.map((item, index) => (
            (item.refferal && !user) || (item.logout && !user) || (item.bonus && !user) || (item.tot && !showTot) ? ''
              : <li className={"nav-menu-list-item " + (item.active ? 'active' : '')} key={index}>
                {
                  !item.logout
                  ? <Link to={!item.refferal ? (item.link || '/') : '/profile/' + user?.steamId + item.link}> {item.icon}
                    <span>{t('main:header.' + item.translate)}</span></Link>
                  : <a href={item.link}> {item.icon} <span>{t('main:header.' + item.translate)}</span></a>
                }
              </li>
          ))}
        </ul>
        <Link className="nav-links-foundbug" to="/faq/found_bug">
          <img src={FundBug} alt=""/>
          <span>Report a bug</span>
        </Link>
      </div>
      <div className="nav-links">
        <Link className="nav-links-item" to="/fair"><span>{t('main:footer.fair')}</span></Link>
        <Link className="nav-links-item" to="/privacy"><span>{t('main:footer.privacy')}</span></Link>
        <Link className="nav-links-item" to="/tos"><span>{t('main:footer.terms')}</span></Link>
        <Link className="nav-links-item" to="/faq"><span>{t('main:footer.faq')}</span></Link>
      </div>
      <div className="nav-social">
        <a className={'nav-social-item'} rel="noreferrer" href="https://twitter.com/csgorace_com"
           target="_blank"><Twitter/></a>
        <a className={'nav-social-item'} rel="noreferrer" href="https://discord.gg/WdFqz9wRu2"
           target="_blank"><Discord/></a>
        {/*<a className={'nav-social-item'} rel="noreferrer" href="tg://resolve?domain=csgorace"*/}
        {/*   target="_blank"><Telegram/></a>*/}
        {/*<a className={'nav-social-item'} href="https://www.instagram.com/csgorace_official/" rel="noreferrer"*/}
        {/*   target="_blank"><Instagram/></a>*/}
        {/*<a className={'nav-social-item'} href="https://vk.com/csgorace_official" rel="noreferrer" target="_blank" ><Vk/></a>*/}
      </div>
      <div className="nav-rights">
        {t('main:rights')}
      </div>
    </div>
  )
}

export default Nav;