import React, { useEffect } from 'react'
import Slider from "react-slick";
//import $ from 'jquery';

import { useDispatch, useSelector } from 'react-redux'
import { getProfileRewardsHandler, reverseReward, claimRewardHandler } from '../redux/actions'
import { useTranslation } from 'react-i18next'

import Coin from "./UI/Coin/Coin";

import { ReactComponent as Info } from '../assets/info-reward.svg'
import { ReactComponent as Close } from '../assets/close.svg'
import { ReactComponent as Lock } from '../assets/lock.svg'
import { ReactComponent as Unlock } from '../assets/unlock.svg'
import { ReactComponent as Lines } from '../assets/lines.svg'

import Fin from '../assets/reward/fin.svg'
import Fdep from '../assets/reward/fdep.svg'
import Bets from '../assets/reward/bets.svg'
import X20 from '../assets/reward/x20.svg'
import Tdep from '../assets/reward/tdep.svg'
import Ref from '../assets/reward/ref.svg'
import Top3 from '../assets/reward/top3.svg'
import Chat from '../assets/reward/chat.svg'
import CoinImg from '../assets/coin.png'

import './ProfileRewards.scss'
import "slick-carousel/slick/slick.css";


const ProfileRewards = () => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const rewards = useSelector(state => state.profile.rewards)
    const server = useSelector(state => state.app.server)
    const profile = useSelector(state => state.profile.profile);

    const user = useSelector(state => state.app.user)
    // {item.id === "bets" ? <><span> {user?.betsCount}</span>  /1000</> :
    // item.id === "tdep" ? <><span> {user?.totalDeposit}</span>  /100</> :
        // item.id === "ref" ? <><span> {user?.activeReferrals}</span>  /10</> :

    const percent = {
        tdep: (user?.totalDeposit/100)*100,
        bets: (user?.betsCount/1000)*100,
        ref: (user?.activeReferrals/10)*100,
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 400,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 3000,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8,
                }
            },
            {
                breakpoint: 2090,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1390,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1210,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 1130,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 790,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 610,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 470,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
        ]
    };

    useEffect(() => {
        dispatch(getProfileRewardsHandler('profile'))
        const rewardsBackText = document.querySelectorAll('.rewards-back-text');
        rewardsBackText.forEach(item => {
            if(item.innerHTML.includes('$')) {
                const newStr = item.innerHTML.split('$');
                item.innerHTML = `${newStr[0]}&nbsp;<img class="profile-rewards-coin" src=${CoinImg} width="13" height="13" alt=""/>${newStr[1] || ''}`;
            }
        })
    }, [])

    return (
        <div className="profile-rewards">
            <div className="profile-rewards__slider">
                <Slider
                    {...settings}
                >
                    {rewards.map(item => (
                        <div key={item.id} className={"profile-rewards-item-content " + (item.info ? 'info ' : '')}>
                            <div className="profile-rewards-item">
                                <div className={"rewards-front " + (profile?.[item.id] === 0 || profile?.achievements?.[item.id] === 0 || profile?.achievements?.[item.id] === 1
                                    ? 'block' : '')}>
                                    <div className="rewards-front-header">
                                        <span>{item.id === 'fdep' ? '' : <Coin />}{item.id === 'fdep' ? (server.achievementBonus * 100).toFixed(2) : item.amount}{item.id === 'fdep' ? '%' : ''}</span>
                                        <Info onClick={() => dispatch(reverseReward(item.id))} />
                                    </div>
                                    <div className="rewards-front-name">{t('profile:rewards_list.' + item.id)}</div>
                                    <img src={
                                        item.id === 'fin' ? Fin :
                                            item.id === 'fdep' ? Fdep :
                                                item.id === 'bets' ? Bets :
                                                    item.id === 'x20' ? X20 :
                                                        item.id === 'tdep' ? Tdep :
                                                            item.id === 'ref' ? Ref :
                                                                item.id === 'top3' ? Top3 :
                                                                    item.id === 'chat' ? Chat : ''} alt="" className="rewards-front-img" />
                                    {profile?.[item.id] === 0 || profile?.[item.id] === 1 || profile?.achievements?.[item.id] === 0 || profile?.achievements?.[item.id] === 1 ? <div className="rewards-front-block">
                                        <div className="rewards-front-header">
                                            <span>{item.id === 'fdep' ? '' : <Coin />}{item.id === 'fdep' ? (server.achievementBonus * 100).toFixed(2) : item.amount}{item.id === 'fdep' ? '%' : ''}</span>
                                            <Info onClick={() => dispatch(reverseReward(item.id))} />

                                        </div>
                                        <div className="rewards-front-name">{t('profile:rewards_list.' + item.id)}</div>
                                        <div className="rewards-front-block-image">
                                            {profile?.[item.id] === 0 || profile?.achievements?.[item.id] === 0 ? <Lock /> : profile?.achievements?.[item.id] === 1 ? <Unlock /> : ''}
                                        </div>
                                        {profile?.[item.id] === 0 || profile?.achievements?.[item.id] === 0 ? <div className="rewards-front-block-progress rewards-progress">
                                            <div className="rewards-progress__value"
                                            style={{ background: `linear-gradient(90deg, #141720 ${percent[item.id] || 0}%,#919294 ${percent[item.id] || 0}%)` }}
                                            >
                                                {item.id === "bets" ? <><span> {user?.betsCount}</span>  /1000</> :
                                                    item.id === "tdep" ? <><span> {user?.totalDeposit}</span>  /100</> :
                                                        item.id === "ref" ? <><span> {user?.activeReferrals}</span>  /10</> :
                                                            <><span> 0</span>  /1</>}
                                            </div>
                                            <div className="profile-rewards__line"
                                                style={{ width: (item.id === "bets" ? user?.betsCount / 1000 : item.id === "tdep" ? user?.totalDeposit / 100 : item.id === "ref" ? user?.activeReferrals / 10 : 0) * 100 + '%' }}
                                            ></div>
                                        </div>
                                            : profile?.achievements?.[item.id] === 1 ? <button className='rewards-front-block-pickup' onClick={() => dispatch(claimRewardHandler(item.id))}>{t('profile:pickUp')} ${item.amount}</button> : ''}
                                        <Lines className="rewards-front-block-background" />

                                    </div> : ''}
                                </div>
                                <div className="rewards-back">
                                    <div className="rewards-back-header">
                                        <span>{item.id === 'fdep' ? '' : <Coin />}{item.id === 'fdep' ? (server.achievementBonus * 100).toFixed(2) : item.amount}{item.id === 'fdep' ? '%' : ''}</span>
                                        <div className="close" onClick={() => dispatch(reverseReward(item.id))}><Close /></div>
                                    </div>
                                    <div className="rewards-back-name">{t('profile:rewards_list.' + item.id)}</div>
                                    <div className="rewards-back-text">
                                        {(t('profile:rewards_list_text.' + item.id))}
                                    </div>
                                    <div className="rewards-back-ps">{t('profile:rewards_list_ps.' + item.id)}</div>
                                    <div className="rewards-back-progress rewards-progress">
                                        <div className="rewards-progress__value"
                                        style={{ background: `linear-gradient(90deg, #141720 ${percent[item.id] || 0}%,#919294 ${percent[item.id] || 0}%)` }}
                                        
                                        >
                                            {item.id === "bets" ? <><span> {user?.betsCount}</span>  /1000</> :
                                                item.id === "tdep" ? <><span> {user?.totalDeposit}</span>  /100</> :
                                                    item.id === "ref" ? <><span> {user?.activeReferrals}</span>  /10</> :
                                                        <><span> 0</span>  /1</>}
                                        </div>
                                        <div className="profile-rewards__line"
                                            style={{ width: (item.id === "bets" ? user?.betsCount / 1000 : item.id === "tdep" ? user?.totalDeposit / 100 : item.id === "ref" ? user?.activeReferrals / 10 : 0) * 100 + '%' }}
                                        ></div>

                                    </div>
                                    <Lines className="rewards-front-block-background" />
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default ProfileRewards;