import React, {useEffect, useState} from 'react'

import { Link } from 'react-router-dom' 
import { useDispatch, useSelector } from 'react-redux'
import { changeAutoCash, crashBetHandler, crashCashOutHandler, getCrashHandler, inputAmount, inputAutoCash, nextBetHandler, showRoundDetail } from '../redux/actions'
import { getCrashRound } from '../utilites/api' 

import ButtonYellow from '../components/UI/ButtonYellow/ButtonYellow'
import CrashAnimate from '../components/Games/Crash/CrashAnimate'
import Hash from '../components/Hash'
import GameNav from '../components/Sections/GameNav/GameNav'
import Popup from "../components/Popups/PopupInfo/Popup";
import GameHeader from '../components/Games/GameHeader/GameHeader'

import {useTranslation} from 'react-i18next'
import {ReactComponent as Live} from '../assets/crash/live.svg'
import {ReactComponent as Lose} from '../assets/crash/red-circle.svg'

import {ReactComponent as Controller} from '../assets/controller.svg'
import './Crash.scss'
import Coin from "../components/UI/Coin/Coin";
import GameAmount from "../components/Games/GameAmount/GameAmount";

const Crash = () => {

  const {t} = useTranslation(['crash', 'main'])

  const dispatch = useDispatch()

  const [earnings, setEarnings] = useState(0)

  const user = useSelector(state => state.app.user)
  const streamer = useSelector(state => state.app.streamer)
  const rates = useSelector(state => state.amount.rates)
  const amount = useSelector(state => state.amount.amount)

  const betsAll = useSelector(state => state.crash.betsAll)
  const crash = useSelector(state => state.crash.crash)
  const previous = useSelector(state => state.crash.previous)
  const autoCash = useSelector(state => state.crash.autoCash)
  const autoCashAmount = useSelector(state => state.crash.autoCashAmount)
  const autoCashType = useSelector(state => state.crash.autoCashType)
  const stage = useSelector(state => state.crash.stage)
  const bet = betsAll.find(bet => bet.s_i === user?.steamId);
  const nextBet = useSelector(state => state.crash.nextBet)
  const [x, setX] = useState(1);
  const title = stage === 0 ? 'Next round in' : stage === 1 ? 'Rolling in' : 'Rolling';
  const minBet = useSelector(state => state.crash.minBet);
  const maxBet = useSelector(state => state.crash.maxBet);
  const start = useSelector(state => state.crash.crash?.stageStart)
  const bettingStage = useSelector(state => state.crash.crash?.bettingStage)
  const preparingStage = useSelector(state => state.crash.crash?.preparingStage)
  const time = stage === 0 ? preparingStage : stage === 1 ? bettingStage : null;
  const bankroll = betsAll.reduce((acc, bet) => bet.b_a + acc, 0)

  useEffect(() => {
    dispatch(getCrashHandler())
  }, [])
  useEffect(() => {
    if (bet) {
      setEarnings(+(bet.b_a * (x || 1)).toFixed(2))
    } else {
      setEarnings('-')
    }
  }, [bet, x])

  useEffect(() => {
    if (stage === 1 && nextBet) {
      dispatch(crashBetHandler(nextBet))
    }
  }, [stage])

  return (
    <div className="crash">
      <GameNav/>
      <GameHeader title={t('crash:crash')} icon={<Controller/>} timer={title}
                  bankroll={bankroll} time={time} start={start}/>
      <div className="crash-recent">
        <ul className="recent-body">{previous.map(round => <li
          className={'recent-body-item ' + (round.X < 1.5 ? 'grey' : round.X < 3 ? 'lime' : round.X < 9 ? 'blue' : round.X < 20 ? 'violet' : 'gold')}
          key={round.roundId}
          onClick={() => dispatch(showRoundDetail(round.roundId, id => getCrashRound(id)))}>{round.X}x</li>)}</ul>
      </div>
      <div className="crash-row">
        <CrashAnimate setX={setX} />
        <div className={"crash-bets " + (stage === 2 && bet?.c === null ? "disable" : "")}>
          <div className="bet-amount">
            <span>{t('crash:betAmount')}</span>
            <div className="crash-rates-body">
                <div className= "crash-rates-input">
                  <input type="text" readOnly={stage === 2 && bet?.c === null} className={stage === 2 && bet?.c === null ? "disable-input" : ""}
                         value={amount} disabled={!user} step="0.01" min="0.01"
                         placeholder={'MIN = ' + minBet + ' / MAX = ' + maxBet}
                         onChange={event => dispatch(inputAmount(event.target.value))}
                         onFocus={ev => {ev.target.setAttribute('placeholder', '')}}
                         onBlur={ev => {ev.target.setAttribute('placeholder', 'MIN = ' + minBet + ' / MAX = ' + maxBet)}}
                  />
                    <div className="crash-rates-body-clear" onClick={() => dispatch(inputAmount(''))}>{t('roullete:clear')}</div>
                </div>
              <ul className="crash-rates-body-list">
                {rates.map(item => <li key={item.id}
                                       onClick={() => user ? dispatch({type: item.do}) : false}>{item.name}</li>)}
              </ul>
            </div>
          </div>
          <div className="autocash-out">
            <div className="bets-header">
              <span>{t('crash:autoCash')}</span>
              <Popup className="bets-header-popup" popupText={t('crash:popupAutoCashOut')}/>
            </div>
            <input type="text" placeholder='Type if you want to set an auto-cashout' disabled={!user}
                   readOnly={stage === 2 && bet?.c === null || !amount} className={stage === 2 && bet?.c === null || !amount ? "disable-input" : ""} step="0.01"
                   min="0.01"
                   value={autoCash && user ? (autoCashType === 1 ? +(autoCashAmount * amount).toFixed(2) : autoCashAmount) : ''}
                   onChange={event => {
                     dispatch(inputAutoCash(autoCashType === 1 ? +(event.target.value) / amount : event.target.value))
                   }}
                   onFocus={ev => {ev.target.setAttribute('placeholder', '')}}
                   onBlur={ev => {ev.target.setAttribute('placeholder', 'Type if you want to set an auto-cashout')}}
            />
          </div>
          <div className="autocash-out-tabs">
            <div className={"autocash-out-tab " + (autoCashType === 0 ? 'active' : '')}
                 onClick={() => dispatch(changeAutoCash(0))}>x0.00
            </div>
            <div className={"autocash-out-tab " + (autoCashType === 1 ? 'active' : '')}
                 onClick={() => dispatch(changeAutoCash(1))}><Coin/>amount
            </div>
          </div>
          <ButtonYellow
            className={"place-bet " + (stage === 2 && bet?.c === null ? "cash-out" : "")}
            disabled={!!(!user || stage === 0 || (stage === 1 && bet) || (stage === 2 && bet?.c !== null && nextBet))}
            onClick={() =>
              stage === 1
              ? dispatch(crashBetHandler())
              : stage === 2 && bet?.c !== null
                ? dispatch(nextBetHandler())
                : dispatch(crashCashOutHandler())}
          >
            {stage === 2 && bet?.c === null ? t('crash:cashOut') : t('crash:placeBet')}
            <span className="place-bet-bet">
              <Coin/>{stage === 2 && bet?.c === null ? earnings : amount || 0}
            </span>
          </ButtonYellow>
        </div>
      </div>
      <ul className="crash-table">
        <li className="header">
          <span>{t('crash:table.player')}</span>
          <span>{t('crash:table.autoCasheout')}</span>
          <span>{t('crash:table.bet')}</span>
          <span>{t('crash:table.profit')}</span>
        </li>
        {betsAll.map((bet) => <li className={'user ' + (user?.steamId === bet.s_i ? 'is-me' : '')} key={bet.s_i}>
          <div className="user-data">
            <Link to={"/profile/" + bet.s_i}
                  className={(bet.l < 10 ? 'grey' : bet.l < 20 ? 'blue' : bet.l < 40 ? 'yellow' : bet.l < 60 ? 'orange' : bet.l < 80 ? 'violet' : bet.l < 90 ? 'red' : bet.l <= 100 ? 'fire' : '')}><img
              className={streamer && user?.steamId !== bet.s_i ? 'streamer' : ''} src={bet.a} alt=""/></Link>
            <span>{streamer && user?.steamId !== bet.s_i ? '*'.repeat(bet.u.length) : bet.u}</span>
            <div
              className={"level " + (bet.l < 10 ? 'grey' : bet.l < 20 ? 'blue' : bet.l < 40 ? 'yellow' : bet.l < 60 ? 'orange' : bet.l < 80 ? 'violet' : bet.l < 90 ? 'red' : bet.l <= 100 ? 'fire' : '')}>{bet.l}</div>
          </div>
          <div className={"user-cashout " + (stage === 0 && !bet.c ? 'red' : '')}>
            <p
              className={'user-cashout-val ' + (stage === 0 && !bet.c ? 'disable' : bet.c ? 'disable' : bet.a_c ? '' : 'disable')}>X {bet.a_c}</p>
            <p
              className={'user-cashout-val ' + (stage === 0 && !bet.c ? 'disable' : bet.c ? 'disable' : bet.a_c ? 'disable' : '')}>
              <Live/> Live</p>
            <p
              className={'user-cashout-val ' + (stage === 0 && !bet.c ? 'disable' : bet.c ? '' : 'disable')}>X {bet.c}</p>
            <p className={'user-cashout-val ' + (stage === 0 && !bet.c ? 'red' : 'disable')}><Lose/> Fail</p>
          </div>
          <div className="user-bet">
            <Coin/> {bet.b_a}</div>
          <div className={"user-profit " + (stage === 0 && !bet.c ? 'red' : '')}>
            <Coin/> {bet.c ? (bet.w - bet.b_a > crash.maxPayout ? crash.maxPayout : bet.w - bet.b_a).toFixed(2) : (stage === 0 ? (0 - bet.b_a).toFixed(2) : '\u2014')}
          </div>
        </li>)}
      </ul>
      <Hash hash={crash?.previousHash}/>
    </div>
  )
}

export default Crash;