import React from 'react';

import {useDispatch, useSelector} from "react-redux";

import {moveMines} from "../../../redux/actions";

import './MinesMine.scss'

const MinesMine = ({x, y, value, roundId}) => {
  const user = useSelector(state => state.app.user);
  const dispatch = useDispatch();

  const getResult = (ev) => {
    const isMyField = ev.target.closest('.mines__grid').getAttribute('id') === user?.steamId;
    if(ev.target.classList.contains("mines__shirt") && isMyField) {
      dispatch(moveMines(x, y, roundId));
    } else {
      ev.target.classList.add('mines__card-active');
    }
  }

  return (
    <div onClick={getResult} className={"mines__card " + (value !== null ? 'mines__card-active' : '')} data-x={x} data-y={y}>
      <div className="mines__shirt"/>

      {
        value === 'M'
        ?
          <div className="mines__fail"></div>
          :
          <div className="mines__success"><span>{value}</span></div>
      }
    </div>
  );
};

export default MinesMine;