import React, {useEffect} from 'react'

import {useDispatch, useSelector} from "react-redux";

import {useTranslation} from 'react-i18next'

import {UPDATE_SETTINGS} from "../../../redux/types";

import Popup from "../../Popups/PopupInfo/Popup";

import './MinesDetails.scss'
import Coin from "../../UI/Coin/Coin";
import {inputAmount} from "../../../redux/actions";

const MinesDetails = () => {

  const dispatch = useDispatch();
  const {t} = useTranslation(['roullete', 'main'])

  const settings = useSelector(state => state.mines.settings);
  const amount = useSelector(state => state.amount.amount)

  const changeSettings = (name, value) => {
    switch (name) {
      case 'type':
        dispatch({type: UPDATE_SETTINGS, payload: { ...settings, s: value, b: false }})
        break;
      case 'rounds':
        dispatch({type: UPDATE_SETTINGS, payload: { ...settings, a: value, m: false }})
        break;
      case 'size':
        dispatch({type: UPDATE_SETTINGS, payload: { ...settings, f_s: value }})
        break;
      case 'inf':
        dispatch({type: UPDATE_SETTINGS, payload: { ...settings, a: 0, m: true }})
        break;
      case 'bot':
        dispatch({type: UPDATE_SETTINGS, payload: { ...settings, s: false, b: true }})
        break;
      default:
        return;
    }
  }

  useEffect(() => {
    dispatch({type: UPDATE_SETTINGS, payload: { ...settings, b_a: +(amount) }})
  }, [amount])

  return (
    <div className="mines__details">
      <div className="mines__title">Battle settings</div>

      <div className="mines__inputs">

        <div className="mines__input">
          <div className="mines__input-title">
            Bet amount
          </div>
          <div className="mines__coin">
            <Coin />
            <input className="mines__coin-input" type="text" placeholder="Enter bet amount" value={amount} onChange={(ev) => dispatch(inputAmount(ev.target.value))} />
          </div>
        </div>

        <div className="mines__input">
          <div className="mines__input-title">
            Game type
          </div>
          <div className="mines__type">
            <button className={settings.s === 0 ? 'mines__button active' : 'mines__button'} onClick={() => changeSettings('type', 0)}>Public</button>

            <button className={settings.s === 1 ? 'mines__button active' : 'mines__button'} onClick={() => changeSettings('type', 1)}>Private</button>

            <button className={settings.b ? 'mines__button active' : 'mines__button'} onClick={() => changeSettings('bot', true)}>With bot</button>
          </div>
        </div>

        <div className="mines__input">
          <div className="mines__input-title">
            Number of rounds
          </div>
          <div className="mines__rounds">
            <button className={settings.a === 3 ? 'mines__button active' : 'mines__button'} onClick={() => changeSettings('rounds', 3)}>3</button>

            <button className={settings.a === 5 ? 'mines__button active' : 'mines__button'} onClick={() => changeSettings('rounds', 5)}>5</button>

            <button className={settings.a === 7 ? 'mines__button active' : 'mines__button'} onClick={() => changeSettings('rounds', 7)}>7</button>

            <button className={settings.m === true ? 'mines__button active' : 'mines__button'} onClick={() => changeSettings('inf', true)}>
              <p>Infinity</p>
              <div className="mines__popup">
                <Popup popupText={t('roullete:details')} />
              </div>
            </button>
          </div>
        </div>

        <div className="mines__input">
          <div className="mines__input-title">
            Field size
          </div>
          <div className="mines__type">
            <button className={settings.f_s === 4 ? 'mines__button active' : 'mines__button'} onClick={() => changeSettings('size', 4)}>4x4</button>

            <button className={settings.f_s === 5 ? 'mines__button active' : 'mines__button'} onClick={() => changeSettings('size', 5)}>5x5</button>

            <button className={settings.f_s === 6 ? 'mines__button active' : 'mines__button'} onClick={() => changeSettings('size', 6)}>6x6</button>
          </div>
        </div>
      </div>

    </div>
  )
}
export default MinesDetails