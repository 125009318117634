import React from 'react';

import "./DepositSkinsCard.scss"

const DepositSkinsCard = ({logo, classForHover, link, name}) => {
  if(link) {
    return (
      <a href={link} target="_blank"  rel="noreferrer" className={"skins__card " + (classForHover || '')}>
        <div className={"skins__game"}>
          <img src={name} alt=""/>
        </div>
        <div className={"skins__name"}>
          <img src={logo} alt=""/>
        </div>
      </a>
    );
  } else {
    return (
      <div className={"skins__card " + (classForHover || '')}>
        <div >
          <img src={name} alt=""/>
        </div>
        <div className={"skins__name"}>
          <img src={logo} alt=""/>
        </div>
      </div>
    );
  }
};

export default DepositSkinsCard;