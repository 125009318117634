export default function Number({number = 0}) {

    // 0 2 3 5 6 7 8 9
    const line_1 = () => {
        return number === 0 || (number !== 1 && number !== 4);
    }

    // 2 3 4 5 6 8 9
    const line_2 = () => {
        return (number >= 2 && number <= 6) || number === 8 || number === 9;
    }

    // 0 2 3 5 6 8 9
    const line_3 = () => {
        return number !== 1 && number !== 4 && number !== 7;
    }

    // 0 4 5 6 8 9
    const line_4 = () => {
        return (number < 1 || number > 3) && number !== 7;
    }

    // 0 2 6 8
    const line_5 = () => {
        return number === 0 || number === 2 || number === 6 || number === 8;
    }

    // 0 1 2 3 4 7 8 9
    const line_6 = () => {
        return number !== 5 && number !== 6;
    }

    // 0 1 3 4 5 6 7 8 9
    const line_7 = () => {
        return number !== 2;
    }

    return (
        <svg width="31" height="47" viewBox="0 0 31 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.32227 2.71871L10.6634 0.803223H25.7745L27.69 2.93155L25.3489 5.05987H10.4506L8.32227 2.71871Z"
                fill={line_1() ? '#F3F6FF' : 'white'} fillOpacity={line_1() ? '1' : '0.1'}
            />
            <path
                d="M5.55518 23.5765L8.10916 21.661L23.4331 21.2354L25.1355 23.5765L22.7946 25.492L7.25783 25.9177L5.55518 23.5765Z"
                fill={line_2() ? '#F3F6FF' : 'white'} fillOpacity={line_2() ? '1' : '0.1'}
            />
            <path
                d="M3.42725 44.2212L5.7684 42.3057H20.8795L22.795 44.434L20.4538 46.5623H5.55557L3.42725 44.2212Z"
                fill={line_3() ? '#F3F6FF' : 'white'} fillOpacity={line_3() ? '1' : '0.1'}
            />
            <path
                d="M4.90214 23.0236L3.30249 20.4562L5.2392 5.46974L7.47213 3.57005L9.38741 5.91121L7.49676 21.2129L4.90214 23.0236Z"
                fill={line_4() ? '#F3F6FF' : 'white'} fillOpacity={line_4() ? '1' : '0.1'}
            />
            <path
                d="M2.77291 43.4554L1.17327 40.8881L2.57608 25.9174L4.91727 24.2147L6.83273 26.7687L5.34293 41.4542L2.77291 43.4554Z"
                fill={line_5() ? '#F3F6FF' : 'white'} fillOpacity={line_5() ? '1' : '0.1'}
            />
            <path
                d="M25.7586 23.0236L24.1589 20.4562L26.2 5.91121L28.4519 3.84283L30.2626 6.43745L28.3532 21.2129L25.7586 23.0236Z"
                fill={line_6() ? '#F3F6FF' : 'white'} fillOpacity={line_6() ? '1' : '0.1'}
            />
            <path
                d="M23.2044 43.4552L21.5177 41.6445L23.5414 25.9014L25.8977 24.2745L27.7084 26.8691L25.799 41.6445L23.2044 43.4552Z"
                fill={line_7() ? '#F3F6FF' : 'white'} fillOpacity={line_7() ? '1' : '0.1'}
            />
        </svg>
    )
}