import React, {useState, useRef, useEffect} from 'react'

import { Link } from 'react-router-dom'

import {useLocation} from 'react-router'

import {useDispatch, useSelector} from 'react-redux'
import {sendMessage, showChat, showChatRules, showDeposit} from '../../../redux/actions'
import { hidePay } from '../../../redux/actions'

import {useTranslation} from 'react-i18next'
import socket from '../../../utilites/sockets'

import Message from '../../Message'
import ButtonYellow from '../../UI/ButtonYellow/ButtonYellow'

import './Chat.scss'

import {ReactComponent as Arrow} from '../../../assets/arrow-right.svg'
import {ReactComponent as ChatImg} from '../../../assets/chat.svg'
import {ReactComponent as Send} from '../../../assets/send.svg'

// import { ReactComponent as Close } from '../../../assets/close.svg'

import {ReactComponent as Wallet} from '../../../assets/wallet.svg'
import {ReactComponent as Online} from '../../../assets/online.svg'
import Close from '../../../assets/close.svg'

import Modal from "../../UI/Modal/Modal";

//eslint-disable-next-line
const Chat = () => {
  const dispatch = useDispatch()
  const {t} = useTranslation(['main'])

  const [message, setMessage] = useState('')
  const [isUserScroll, setIsUserScroll] = useState(false)
  const [messagesLocal, setMessagesLocal] = useState(0)
  const [modalActive, setModalActive] = useState(false);
  const [[coordX, coordY], setCoord] = useState([0, 0])
  const [messageId, setMessageId] = useState(0)
  const chat = useRef()

  const location = useLocation()

  const messages = useSelector(state => state.chat.messages)
  const streamer = useSelector(state => state.app.streamer)
  // const newMessagesCount = useSelector(state => state.chat.newMessagesCount)
  const collapse = useSelector(state => state.chat.chat)
  const user = useSelector(state => state.app.user)
  const banner = useSelector(state => state.app.banner)
  const online = useSelector(state => state.app.online)
  const hidePayVar = useSelector(state => state.app.hidePay) || !user
  let profile = useSelector(state => state.profile.chat_profile)

  const isChatHide = localStorage.getItem('hideChat');

  const scrollToTopChat = (element) => {
    setTimeout(() => {
      if (!isUserScroll && element) {
        // SmoothVerticalScrolling(element, 1000, 'bottom')
        element.scrollTo({
          top: 10000
        })
      }
    }, 200)
  }

  const setChatState = () => {
    dispatch(showChat())
    if(+(isChatHide)) {
      localStorage.setItem('hideChat', 0)
    } else {
      localStorage.setItem('hideChat', 1)
    }
  }

  const setChatPayState = () => {
    dispatch(hidePay())
    if(+(hidePayVar)) {
      localStorage.setItem('hidePay', false)
    }
  }

  const sendMessageHandler = event => {
    event.preventDefault()
    dispatch(sendMessage(message))
    setMessage('')
  }

  useEffect(() => {
    if (messagesLocal < messages.length) {
      scrollToTopChat(chat.current)
    }
    setMessagesLocal(messages.length)
    //eslint-disable-next-line
  }, [messages])

  useEffect(() => {
    socket.on('chatMsg', () => scrollToTopChat(chat.current))
    scrollToTopChat(chat.current)
    if(+(isChatHide)) {
      dispatch(showChat())
    }
  }, [])
  
  return (
    <div
      className={"chat " + (banner ? 'banner-chat ' : '') + (hidePayVar ? 'full ' : '') + (collapse ? 'collapse ' : '') + (location.pathname.includes('withdraw') ? 'none ' : '')}
      onMouseOver={(ev) => {
        if(ev.target.classList.contains('chat-body') || ev.target.classList.contains('chat-body-messages-list')) {
          setModalActive(false)
        }
      }}
    >
      <div className="message-modal">
        <Modal messageId={messageId} x={coordX} y={coordY} active={modalActive} setModalActive={setModalActive}  profile={profile}/>
      </div>
      <div className={"chat-pay " + (hidePayVar ? 'hidden' : '')}>
        <span>{t('main:payBalance')}</span>
        <Link to="/deposit"><ButtonYellow onClick={() => dispatch(showDeposit())}>{t('main:pay')}</ButtonYellow></Link>
        <Wallet className="chat-pay-image"/>
        <div className="chat-pay-close" onClick={()=>{setChatPayState()}}>
          <img src={Close} alt="" />
        </div>
      </div>
      <div className={"chat-body "}>
        {/*<div className="makar"></div>*/}
        <div className="chat-body-header">
          <div className="chat-title">
            <ChatImg/>
            <span>{t('main:chat')}</span>
          </div>
          <div className="chat-rules">
            <span onClick={() => dispatch(showChatRules())}>{t('main:rules')}</span>
            <button onClick={setChatState}><Arrow/></button>
          </div>
        </div>
        <div className="chat-body-online">
          <Online/>
          <div className="count">{online}</div>
          <span>{t('online')}</span>
        </div>
        <div className={"chat-body-messages " + (streamer ? 'streamer' : '')} ref={chat}
             onScroll={() => setIsUserScroll(true)} onClick={event => streamer ? (() => {
          event.preventDefault()
          event.stopPropagation()
        })() : false}>
          <div className="chat-body-messages-list">
            {messages.map(item => <Message setMessageId={setMessageId} message={item} key={item.i} setCoord={setCoord} setModalActive={setModalActive}
                                           onClick={() => setMessage(prev => prev + '@' + item.n + ' ')}/>)}
          </div>
        </div>
        <form className="chat-body-send" onSubmit={sendMessageHandler}>
          <input type="text" placeholder={t('main:chatSend')} value={message}
                 onChange={event => setMessage(event.target.value)}
                 disabled={!user || user?.permissions?.bannedChat || user?.permissions?.mutedChat}/>
          <button type="submit" disabled={!user || user?.permissions?.bannedChat || user?.permissions?.mutedChat}>
            <Send/>
          </button>
        </form>
      </div>
    </div>
  )
}
export default Chat;