import React from 'react'

import {Link} from 'react-router-dom'

import {useSelector} from 'react-redux'

import Pos1 from '../assets/top/1.png'
import Pos2 from '../assets/top/2.png'
import Pos3 from '../assets/top/3.png'

import './TopItem.scss'
import Coin from "./UI/Coin/Coin";

//eslint-disable-next-line
const TopItem = ({userData = null, className = '', pos = 0, filter = ''}) => {

  const user = useSelector(state => state.app.user)
  const streamer = useSelector(state => state.app.streamer)
  const rewards = useSelector(state => state.tot.rewards)
  const monthlyBetCoefficient = rewards.monthlyBetCoefficient;
  const dailyBetCoefficient = rewards.dailyBetCoefficient;

  if (!userData) return ''
  return <div className={"top-item " + className}>
    <div className="top-item-body">
      <Link to={"/profile/" + userData.steamId}
            className={'image ' + (userData.level < 10 ? 'grey' : userData.level < 20 ? 'blue' : userData.level < 40 ? 'yellow' : userData.level < 60 ? 'orange' : userData.level < 80 ? 'violet' : userData.level < 90 ? 'red' : userData.level <= 100 ? 'fire' : '')}>
        <img src={userData.profileAvatar} className={streamer && user?.steamId !== userData.steamId ? 'streamer' : ''}
             alt=""/>
        <div
          className={"level " + (userData.level < 10 ? 'grey' : userData.level < 20 ? 'blue' : userData.level < 40 ? 'yellow' : userData.level < 60 ? 'orange' : userData.level < 80 ? 'violet' : userData.level < 90 ? 'red' : userData.level <= 100 ? 'fire' : '')}>{userData.level}</div>
      </Link>
      <Link to={"/profile/" + userData.steamId}
            className="name">{streamer && user?.steamId !== userData.steamId ? '*'.repeat(userData.nickname.length) : userData.nickname}</Link>
      <div className="fuel">
        <Coin />
        <span>
            {
              (
                userData?.totalBet
                ?? userData?.monthlyBet
                ?? userData?.dailyBet
              ).toFixed(2)
            }
        </span>
      </div>
      <div className={"won " + (filter === 'ever' ? 'hide' : '')}>
        <Coin />
        <span> + {filter === 'daily'
          ? Math.min(rewards.rewards.find(wagered => wagered.place === pos && wagered.type === 'D')
            ?.amount, userData.dailyBet / dailyBetCoefficient).toFixed(2)
          : filter === 'monthly'
            ? Math.min(rewards.rewards.find(wagered => wagered.place === pos && wagered.type === 'M')
              ?.amount, userData.monthlyBet / monthlyBetCoefficient).toFixed(2) : ''}
                </span>
      </div>
      <img src={pos === 1 ? Pos1 : pos === 2 ? Pos2 : Pos3} alt="" className="cup"/>
    </div>
    <div className={"top-item-count " + (pos === 1 ? 'gold' : '')}>TOP {pos}</div>
  </div>
}
export default TopItem;