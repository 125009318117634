import {
    ADD_STREETRACE_BET,
    ADD_STREETRACE_ROUND,
    CLEAR_STREETRACE_BETS_ALL,
    CLEAN_STREETRACE,
    GET_STREETRACE,
    GET_STREETRACE_PREVIOUS,
    MUTE_STREETRACE,
    SET_STREETRACE_STAGE,
    UPDATE_STREETRACE_TIME,
    WINNER_STREETRACE
} from "./types"

const initialState = {
    streetrace: null,
    stage: null,
    previous: [],
    interval: null,
    bets: [],
    betsAll: [],
    speed: 0,
    winner: null,
    mute: true,
    start: null,
}

export const streetraceReducer = ( state = initialState, action ) => {
    switch(action.type){
        case GET_STREETRACE:
            return { 
                ...state, 
                streetrace: action.payload.streetrace, 
                stage: action.payload.streetrace.stage,
                betsAll: action.payload.streetrace.bets || [],
                winner: action.payload.streetrace.car,
                start: action.payload.streetrace.stageStart
            }
        case UPDATE_STREETRACE_TIME:
            return { ...state, start: action.payload }
        case GET_STREETRACE_PREVIOUS:
            return { ...state, previous: action.payload, winner: state.winner || action.payload[0].color }
        case SET_STREETRACE_STAGE:
            return { ...state, stage: action.payload }
        case ADD_STREETRACE_ROUND:
            return {
                ...state,
                previous: [{ car: action.payload.c, moment: action.payload.m, roundId: action.payload.r_i}].concat(state.previous.slice(0, -1)),
                streetrace: { ...state.streetrace, previousHash: action.payload.r_h } 
            }
        case ADD_STREETRACE_BET:
            return { ...state, betsAll: state.betsAll.concat(action.payload) }
        case CLEAR_STREETRACE_BETS_ALL:
            return { ...state, betsAll: [] }
        case WINNER_STREETRACE:
            return { ...state, winner : action.payload }
        case CLEAN_STREETRACE:
            return {
                ...state,
                streetrace: null,
                stage: null,
                previous: [],
                interval: null,
                betsAll: [],
                speed: 0,
                winner: null
            }
        case MUTE_STREETRACE: 
            return { ...state, mute : !state.mute }
        default:
            return state
    }
}