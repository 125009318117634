import React, {useEffect, useState} from 'react';

import {useDispatch, useSelector} from "react-redux";
import {depositReferral} from "../../redux/actions";

import ReferralBonus from "../../components/UI/IsCorrectInput/ReferralBonus";

import {REFERRAL_CODE} from "../../redux/types";

import "./DepositRefferalBonus.scss"

const DepositRefferalBonus = () => {
  const dispatch = useDispatch();
  const referralBonus = useSelector(state => state.deposit.refferal_bonus);
  const [ refValue, setRefValue ] = useState('')

  function useReferral(ev) {
    ev.preventDefault();
    dispatch(depositReferral(ev.target[0].value))
  }

  function clearCode(ev) {
    ev.preventDefault();
    dispatch({type: REFERRAL_CODE, payload: 0})
  }

  useEffect(() => {
    return () => {
      dispatch({type: REFERRAL_CODE, payload: 0})
    }
  }, [])

  return (
    <div className="deposit__refferal refferal">
      <div className="refferal__text">
        <p>Have a refferal code?</p>
        <p>Paste a refferal code here</p>
      </div>
      <form className="refferal__form" onSubmit={useReferral}>
        <div className="refferal__input-form">
          <input type="text" placeholder="Paste a refferal code" className="refferal__input" value={refValue} onInput={ev => setRefValue((ev.target.value).toUpperCase())}/>
          <ReferralBonus bonus={referralBonus}/>
        </div>
        {
          referralBonus > 0
            ? <button onClick={clearCode} className="refferal__change-button">Change code</button>
            : <button className="refferal__button">Use code</button>
        }
      </form>
    </div>
  );
};

export default DepositRefferalBonus;