import React from 'react';

import { ReactComponent as CSGO } from '../../../assets/mines/map-logo-0.svg'
import { ReactComponent as Tree } from '../../../assets/mines/map-logo-1.svg'
import { ReactComponent as Cactus } from '../../../assets/mines/map-logo-2.svg'
import { ReactComponent as Inf } from '../../../assets/mines/infinity.svg'

import './MinesRounds.scss'

export default function MinesRounds({rounds, currentRound, map, gameCode, withMines, privateGame})  {
  const progressRounds = [];
  for (let i = 0; i < rounds; i++) {
    progressRounds.push(<span className={"card__rounds " + (i < (currentRound || 1) ? "passed" : '')}></span>);
  }

  return (
    <div className={'card__round ' + (rounds === 100 ? 'inf' : '')}>
      {/* {gameCode && privateGame && <div className="card__game-code">
        Game Code: {gameCode}
      </div>} */}
      <div className={'card__map ' + (map === 0 ? "csgo" : (map === 1 ? "cactus" : "tree")) + (rounds === 100 ? 'inf' : '')}>
        { map === 0 ? <CSGO /> : (map === 1 ? <Cactus /> : <Tree />) }
      </div>
      {
        !withMines
        ?
          <>
            <p>{Math.min(currentRound || 1, rounds)}/{rounds} rounds</p>
            <div className='card__row'>
              {progressRounds.map(item => item)}
            </div>
          </>
        : <div className='card__row infinity'> <Inf/> Infinity</div>

      }
    </div>
  );
};
