import React from 'react';

import Profile from '../../UI/Profile/Profile';

import './MinesPlayer.scss'
import {useSelector} from "react-redux";
import Coin from "../../UI/Coin/Coin";

const MinesPlayer = ({className, waiting, player, turn, winAmount, anotherWinAmount, betAmount}) => {
  const gameOver = useSelector(state => state.mines.game_over);

  return (
    <div className={"mines__player " + (className || "") + (waiting ? " waiting" : "")}>
      <Profile item={player}/>
      <div className="mines__info">
        <div className="mines__bet" id={player?.b_a}>
          <Coin/>
          {
            betAmount
            ?
              <span className={gameOver?.w ? (gameOver?.w === player?.s_i ? 'win' : 'loose') : ''}>
                {betAmount}
              </span>
              :
              <span className={gameOver?.w ? (gameOver?.w === player?.s_i ? 'win' : 'loose') : ''}>
                {!waiting ? (gameOver?.w ? (gameOver?.w === player?.s_i ? '+' + ((winAmount + anotherWinAmount) || 0).toFixed(2)
                  : '-' + (player?.b_a || 0).toFixed(2)) : (winAmount || 0).toFixed(2)) : 0.00}
              </span>
          }
          { waiting === undefined
            ?
            <div className={"mines__turn" + (turn ? "" : " not-move")}>
              Your turn
            </div>
            :
            <div className={"mines__turn" + (!turn ? "" : " not-move")}>
              Rival's turn
            </div>
          }
        </div>
        <span className="mines__nickname">{waiting ? 'Waiting for the player...' : player?.u}</span>
      </div>
    </div>
  );
};
export default MinesPlayer;