import {
   DAILY_BONUS,
   DAILY_GAME_PLAY,
   DAILY_GAME_ACCEPT,
   GET_BONUSES,
   GET_RAKEBACK,
   GET_CONDITION,
} from './types';

const initialState = {
   bonuses: {},
   rakeback: [],
   condition_data: {},
   daily_bonus: null,
   daily_game: false,
   daily_accept: 0,
}

export const bonusesReducer = ( state = initialState, action ) => {
   switch(action.type){
      case GET_BONUSES:
         return { ...state, bonuses: action.payload}
      case GET_RAKEBACK:
         return { ...state, rakeback: action.payload }
      case DAILY_BONUS:
         return { ...state, daily_bonus: [ ...action.payload ] }
      case DAILY_GAME_PLAY:
         return { ...state, daily_game: action.payload }
      case DAILY_GAME_ACCEPT:
         return { ...state, daily_accept: action.payload }
      case GET_CONDITION:
         return { ...state, condition_data: action.payload }
      default:
         return state
   }
}