import React from "react";

import Profile from '../../UI/Profile/Profile';

import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import { createGame, hideTrackRace } from '../../../redux/actions'

import './GameOver.scss'
import Coin from "../../UI/Coin/Coin";

export default function GameOver({game}) {
  const { t } = useTranslation('race')

  const dispatch = useDispatch()
  const user = useSelector(state => state.app.user)

  let withBot = game.s_p.s_i === 'bot';

  return (
    <div className='game-over__wrapper'>
      <div className='game-over'>
        <div className='game-over__card'>
          <p className='game-over__text'>{t('race:gameOver.winner')}</p>
          <div className='game-over__winner'>
            <Profile className='game-over__profile' item={game.f_p.s_i === game.w ? game.f_p : game.s_p}/>
            <p className='game-over__nickname'>{game.f_p.s_i === game.w ? game.f_p.u : game.s_p.u}</p>
          </div>
          <div className="game-over__bet">
            <Coin/>
            <span>{game.w_a.toFixed(2)}</span>
          </div>
        </div>
        <button className='game-over__create' disabled={!user} onClick={() => dispatch(createGame(game.f_p.b_a, withBot, game?.r_i))}>
          <span>{t('race:gameOver.create')}</span>
        </button>
        <button className='game-over__back' onClick={() => dispatch(hideTrackRace(game?.r_i))}>
          <span>{t('race:gameOver.getBack')}</span>
        </button>
      </div>
    </div>
  );
}