import {
    CHANGE_BETS_ALL,
    ADD_ROUND,
    CHANGE_AUTOCASH_OUT,
    CURRENT_X,
    GET_CRASH,
    GET_CRASH_PREVIOUS,
    INPUT_AUTOCASH_OUT,
    SET_STAGE,
    ADD_CASHOUT,
    NEXT_BET,
    CHANGE_START
} from "./types"


const initialState = {
    crash: null,
    bankroll: 0,
    previous: [],
    autoCash: false,
    autoCashType: 0,
    autoCashAmount: '',
    stage: null,
    nextBet: null,
    betsAll: [],
    cashouts: [],
    startDelay: '',
    delay: '',
    changeDelay: '',
    spinningStart: null,
    x: null,
    timeout: undefined,
    interval: undefined,
    title: '',
    minBet: null,
    maxBet: null
}

export const crashReducer = (state = initialState, action) => {
    switch (action.type){
        case GET_CRASH:
            return {
                ...state, 
                crash: action.payload.crash, 
                bankroll: +action.payload.crash.bets?.reduce((counter, bet) => counter + bet.b_a, 0).toFixed(2) || 0,
                stage: action.payload.crash.stage,
                betsAll: action.payload.crash.bets || [],
                minBet: action.payload.crash.minBet,
                maxBet: action.payload.crash.maxBet
            }
        case GET_CRASH_PREVIOUS: 
            return { ...state, previous: action.payload.rounds }
        case CHANGE_AUTOCASH_OUT:
            return { ...state, autoCashType: action.payload }
        case INPUT_AUTOCASH_OUT: 
            let value = action.payload.value
            if(isNaN(value)){
                value = ''
            }
            return { ...state, autoCashAmount: value, autoCash: !!value }
        case ADD_ROUND: 
            return { 
                ...state, 
                previous: [{ X: action.payload.X, moment: action.payload.m, roundId: action.payload.r_i }].concat(state.previous), 
                crash: { ...state.crash, previousHash: action.payload.r_h } }
        case SET_STAGE:
            return { ...state, stage: action.payload }
        case NEXT_BET:
            return { ...state, nextBet: action.payload }
        case CHANGE_BETS_ALL:
            return { ...state, betsAll: action.payload.clear ? [] : state.betsAll.concat(action.payload.data) }
        case ADD_CASHOUT:
            return { ...state, cashouts: state.cashouts.concat(action.payload), betsAll: state.betsAll.map(item => item.b_a === action.payload.b_a && item.s_i === action.payload.s_i ? ({ ...item, w: action.payload.w, c: action.payload.c}) : item)  }
        case CURRENT_X: 
            return { ...state, x: action.payload }
        case CHANGE_START:
            return { ...state, crash: { ...state.crash, stageStart: action.payload } }
        default: 
            return state
    }
}