import React, {useState} from 'react';

import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from 'react-redux'
import { checkConditionHandler } from "../../../redux/actions";

import { ReactComponent as ReqSuccess } from '../../../assets/bonuses/req-success.svg'

import ProgressBar from "../../../components/UI/ProgressBar/ProgressBar";

import {DAILY_GAME_ACCEPT} from "../../../redux/types";

import './Requirements.scss';
import Coin from "../../../components/UI/Coin/Coin";

const Requirements = () => {
  const { t } = useTranslation(['bonuses']);

  const dispatch = useDispatch();
  const monthlyBet = useSelector(state => state.app.user.monthlyBet);
  const user = useSelector(state => state.app.user);
  const betCoefficient = useSelector(state => state.hangars.betCoefficient);
  const hangars = useSelector(state => state.hangars.hangars);
  const conditionData = useSelector(state => state.bonuses.condition_data)
  const [isBet, setIsBet] = useState(null);

  function findBonus(level, bonusesArray) {
    for (let i = 0; i < bonusesArray.length + 1; i++) {
      if (level >= (bonusesArray[i - 1]?.level || 0) && level < (bonusesArray[i]?.level || 101)) {
        return bonusesArray[i - 1]?.price;
      }
    }
    return 0;
  }

  const needToBet = findBonus(user?.level, hangars) * betCoefficient;

  function checkRequirements(ev) {
    ev.preventDefault();

    dispatch(checkConditionHandler());
    if (monthlyBet > needToBet) {
      setIsBet(true)
    } else {
      setIsBet(false)
    }

  }

  function isCompleteCondition() {
    if (user?.conditionsMet && monthlyBet > needToBet) {
      dispatch({type: DAILY_GAME_ACCEPT, payload: 1});
      return true
    } else {
      dispatch({type: DAILY_GAME_ACCEPT, payload: 0});
      return false
    }
  }

  return (
    <div className="requirements">
      {
      isCompleteCondition()
      ?
        <>
          <div className="requirements__title">{t('bonuses:requirements.title')}</div>
          <div className="requirements__success">
            <ReqSuccess />
            <div>{t('bonuses:requirements.success')}</div>
          </div>
        </>
        :
        <form action="" className="requirements__form" onSubmit={checkRequirements}>
          <div className="requirements__wrapper">
            <div className="requirements__title">{t('bonuses:requirements.title')}</div>
              <div className="requirements__text">
                <div className={"requirements__item " + conditionData?.hasNickname}>
                  <div className={"requirements__condition "}> <span>1</span> {t('bonuses:requirements.subtitle.second')}</div>
                </div>
                <div className={"requirements__item " + isBet}>
                  <span>2</span>{t('bonuses:requirements.subtitle.third.one')}<Coin /> <div className="requirements__value">{needToBet}</div> {t('bonuses:requirements.subtitle.third.two')}
                </div>
                <div className="requirements__item">
                  <div className="requirements__line">
                    <ProgressBar max={needToBet} value={monthlyBet} isDisplayProgress={true}
                                 ObjectClassName={"requirements"} className="requirements__bar"/>
                  </div>
                </div>
              </div>
              <button className="requirements__button" type="submit">{t('bonuses:requirements.button')}</button>
              <div className="requirements__info">Please, meet the requirements and press the button «Check»</div>
          </div>
        </form>
      }
    </div>
  );
};

export default Requirements;