import React, {useEffect, useState} from 'react'

import GameNav from '../components/Sections/GameNav/GameNav'
import GameHeader from '../components/Games/GameHeader/GameHeader'
import GameAmount from '../components/Games/GameAmount/GameAmount'


import RouletteBets from '../components/Games/Roulette/RouletteBets'
import Hash from '../components/Hash'
import RouletteAnimate from '../components/Games/Roulette/RouletteAnimate'
import {useTranslation} from 'react-i18next'

import {useSelector, useDispatch} from 'react-redux'
import {changeMuteRoullete, getRoulleteHandler, inputAmount, roulleteCleaner, showRoundDetail} from '../redux/actions'

import {ReactComponent as Controller} from '../assets/controller.svg'

import {getRoulleteRound} from '../utilites/api'

import './Roulette.scss'
import RoulettePlayers from "../components/Games/Roulette/RoulettePlayers";

//eslint-disable-next-line
export default () => {

  const dispatch = useDispatch()

  const rates = useSelector(state => state.amount.rates)
  const amount = useSelector(state => state.amount.amount)
  const previous = useSelector(state => state.roullete.previous)
  const roullete = useSelector(state => state.roullete.roullete)
  const stage = useSelector(state => state.roullete.stage)
  const title = stage === 0 ? 'Next round in' : stage === 1 ? 'Spinning in' : 'Spinning';
  const bettingStage = roullete?.bettingStage
  const preparingStage = roullete?.preparingStage
  const spinningStage = roullete?.spinningStage
  const time = stage === 0 ? spinningStage + preparingStage : stage === 1 ? bettingStage : null;
  const betsAll = useSelector(state => state.roullete.betsAll)
  const mute = useSelector(state => state.roullete.mute)
  const start = useSelector(state => state.roullete.start)
  const bankroll = useSelector(state => state.roullete.betsAll.reduce((counter, value) => counter + value.b_a, 0))

  const {t} = useTranslation(['roullete'])

  useEffect(() => {
    dispatch(getRoulleteHandler())
    return () => dispatch(roulleteCleaner())
  }, [])

  return (
    <div className="roulette">
      <GameNav/>
      <GameHeader mute={mute} muteHandler={() => dispatch(changeMuteRoullete())} title={t('roullete:roullete')}
                  icon={<Controller/>} bankroll={bankroll} start={start} time={time}
                  timer={title}/>
      <div className="roulette-stat">
        <div className="roulette-stat-history">
          <ul className="history">
            {previous.slice(0, 10).map(round => <li key={round.roundId} className={round.color.toLowerCase()}
                                                    onClick={() => dispatch(showRoundDetail(round.roundId, id => getRoulleteRound(id)))}></li>)}
          </ul>
        </div>
        <div className="roulette-stat-last">
          <span className="roulette-stat-last-span">{t('roullete:last')} 100</span>
          <div className="values">
            <div className="values-item"><span className={'color red'}></span>
              <span>{previous.slice(0, 100).filter(item => item.color === 'Red').length}</span></div>
            <div className="values-item"><span className={'color black'}></span>
              <span>{previous.slice(0, 100).filter(item => item.color === 'Black').length}</span></div>
            <div className="values-item"><span className={'color green'}></span>
              <span>{previous.slice(0, 100).filter(item => item.color === 'Green').length}</span></div>
          </div>
        </div>
      </div>
      <RouletteAnimate/>
      <GameAmount rates={rates} changeAmount={inputAmount} amount={amount} game={roullete}/>
      <div className="roulette-bets">
        <RouletteBets className={'black'} bets={betsAll.filter(item => item.c === 'Black')} type={'Black'}/>
        <RouletteBets className={'green'} bets={betsAll.filter(item => item.c === 'Green')} type={'Green'}/>
        <RouletteBets className={'red'} bets={betsAll.filter(item => item.c === 'Red')} type={'Red'}/>
      </div>
      <div className="roulette-players">
        <RoulettePlayers className={'black'} bets={betsAll.filter(item => item.c === 'Black')} type={'Black'}/>
        <RoulettePlayers className={'green'} bets={betsAll.filter(item => item.c === 'Green')} type={'Green'}/>
        <RoulettePlayers className={'red'} bets={betsAll.filter(item => item.c === 'Red')} type={'Red'}/>
      </div>
      {roullete ? <Hash hash={roullete.previousHash}/> : ''}
    </div>
  )
}