import React, {useEffect} from 'react'

import GameNav from '../components/Sections/GameNav/GameNav'
import GameHeader from '../components/Games/GameHeader/GameHeader'
import GameAmount from '../components/Games/GameAmount/GameAmount'
import Hash from '../components/Hash'
import StreetraceBets from '../components/Games/Streetrace/StreetraceBets'
import StreetraceAnimate from '../components/Games/Streetrace/StreetraceAnimate'
import StreetracePlayers from "../components/Games/Streetrace/StreetracePlayers";

import {useTranslation} from 'react-i18next'

import {useSelector, useDispatch} from 'react-redux'

import {getStreetraceRound} from '../utilites/api'
import {
  getStreetraceHandler,
  inputAmount,
  showRoundDetail,
  changeMuteStreetrace
} from '../redux/actions'

import {ReactComponent as Flags} from '../assets/flags.svg'

import './Streetrace.scss'

export default function Streetrace() {

  const {t} = useTranslation(['streetrace'])

  const dispatch = useDispatch()

  const rates = useSelector(state => state.amount.rates)
  const amount = useSelector(state => state.amount.amount)

  const streetrace = useSelector(state => state.streetrace.streetrace)
  const stage = useSelector(state => state.streetrace.stage)
  const title = stage === 0 ? 'Next round in' : stage === 1 ? 'Race in' : 'Race';
  const start = useSelector(state => state.streetrace.start)
  const bettingStage = streetrace?.bettingStage
  const preparingStage = streetrace?.preparingStage
  const spinningStage = streetrace?.spinningStage
  const time = stage === 0 ? spinningStage + preparingStage : stage === 1 ? bettingStage : null;
  const previous = useSelector(state => state.streetrace.previous)
  const betsAll = useSelector(state => state.streetrace.betsAll)
  const mute = useSelector(state => state.streetrace.mute)
  const bankroll = useSelector(state => state.streetrace.betsAll.reduce((counter, value) => counter + value.b_a, 0))

  useEffect(() => {
    dispatch(getStreetraceHandler())
  }, [])

  return (
    <div className="streetrace">
      <GameNav/>
      <GameHeader mute={mute} muteHandler={() => dispatch(changeMuteStreetrace())}
                  title={t('streetrace:streetrace')} icon={<Flags/>} start={start} time={time}
                  timer={title} bankroll={bankroll} popupText={t('streetrace:info.p1')}/>
      <div className="streetrace-stat">
        <div className="streetrace-stat-history">
          <ul className="history">
            {previous.slice(0, 10).map(round => <li key={round.roundId}
                                                    className={(round.car === 0 ? 'red' : round.car === 1 ? 'yellow' : round.car === 2 ? 'blue' : 'green')}
                                                    onClick={() => dispatch(showRoundDetail(round.roundId, id => getStreetraceRound(id)))}></li>)}
          </ul>
        </div>
        <div className="streetrace-stat-last">
          <span className="streetrace-stat-last-span">{t('streetrace:last')} 100</span>
          <div className="values">
            <div className="values-item"><span className={'color red'}></span>
              <span>{previous.slice(0, 100).filter(item => item.car === 0).length}</span></div>
            <div className="values-item"><span className={'color yellow'}></span>
              <span>{previous.slice(0, 100).filter(item => item.car === 1).length}</span></div>
            <div className="values-item"><span className={'color blue'}></span>
              <span>{previous.slice(0, 100).filter(item => item.car === 2).length}</span></div>
            <div className="values-item"><span className={'color green'}></span>
              <span>{previous.slice(0, 100).filter(item => item.car === 3).length}</span></div>
          </div>
        </div>
      </div>
      <StreetraceAnimate/>
      <GameAmount rates={rates} changeAmount={inputAmount} amount={amount} game={streetrace}/>
      <div className="streetrace-bets">
        <StreetraceBets bets={betsAll.filter(item => item.c === 0)} className={'red'} type={0}/>
        <StreetraceBets bets={betsAll.filter(item => item.c === 1)} className={'yellow'} type={1}/>
        <StreetraceBets bets={betsAll.filter(item => item.c === 3)} className={'green'} type={3}/>
        <StreetraceBets bets={betsAll.filter(item => item.c === 2)} className={'blue'} type={2}/>
      </div>
      <div className="streetrace-players">
        <StreetracePlayers bets={betsAll.filter(item => item.c === 0)} className={'red'} type={0}/>
        <StreetracePlayers bets={betsAll.filter(item => item.c === 1)} className={'yellow'} type={1}/>
        <StreetracePlayers bets={betsAll.filter(item => item.c === 3)} className={'green'} type={3}/>
        <StreetracePlayers bets={betsAll.filter(item => item.c === 2)} className={'blue'} type={2}/>
      </div>
      <Hash hash={streetrace?.previousHash}/>
    </div>
  )
}