import {
  ADD_MINES_GAME, CLEAR_CURRENT_GAME, GAME_OVER, GET_CURRENT_GAME,
  GET_MINES, JOIN_GAME_MINES, MINES_ON, REMOVE_MINES, UPDATE_SETTINGS
} from './types';

const initialState = {
  mines: null,
  games: [],
  current_game: {},
  game_over: {},
  on_mines: false,
  settings: {
    b_a: null,
    f_t: 0,
    s: 0,
    f_s: 5,
    a: 5,
    m: false,
    b: false
  }
}

export const minesReducer = ( state = initialState, action ) => {
  switch(action.type){
    case GET_MINES:
      return {
        ...state,
        mines: { ...action.payload },
        games: action.payload.games.map(game => ({
          a: game.attempts,
          f_s: game.fieldSize,
          f_t: game.fieldType,
          m: game.moment,
          m_m: game.mines,
          m_s: game.moves,
          w: game.winner,
          w_a: game.winAmount,
          p: game.players,
          r_i: game.roundId,
          s: game.secret,
        })),
      }
    case REMOVE_MINES:
      return {
        ...state,
        games: state.games.filter(game => (game.r_i !== action.payload)),
      }
    case MINES_ON:
      if (action.payload.minesOn) {
        return {
          ...state,
          on_mines: action.payload
        }
      } else {
        return {
          ...state,
          game_over: {},
          current_game: {},
          games: action.payload.isMainPage ? state.games : [],
          on_mines: action.payload
        }
      }
    case ADD_MINES_GAME:
      return {
        ...state,
        games: state.games.concat({ ...action.payload }),
      }
    case JOIN_GAME_MINES:
      return { ...state, games: state.games.map(item => item.r_i === action.payload.r_i ? ({...item, s_p: action.payload.s_p, w: action.payload.w, r_h: action.payload.r_h, w_a: action.payload.w_a  }) : item) }
    case UPDATE_SETTINGS:
      return {
        ...state,
        settings: { ...action.payload }
      }
    case GAME_OVER:
      const field = state.current_game.f[1]
      field[action.payload.y][action.payload.x] = action.payload.v
      return {
        ...state,
        game_over: action.payload,
        current_game: {
          ...state.current_game,
          f: [
            state.current_game.f[0],
            field,
          ]
        }
      }
    case GET_CURRENT_GAME:
      return {
        ...state,
        current_game: {
          a: action.payload.attempts,        // attempts
          f_s: action.payload.fieldSize,     // fieldSize
          f_t: action.payload.fieldType,     // fieldType
          f: action.payload.fields,          // fields
          m_t: action.payload.lastMoveTime,  // lastMoveTime
          m_m: action.payload.mines,         // mines
          m: action.payload.moment,          // moment
          m_s: action.payload.moves,         // moves
          p: action.payload.players,         // players
          r_i: action.payload.roundId,       // roundId
          s: action.payload.secret,          // secret
          s_g: action.payload.stage,          // stage
          w: action.payload.winner,
          w_a: action.payload.winAmount,
        }
      }
    case CLEAR_CURRENT_GAME:
      return {
        ...state,
        current_game: {},
      }
    default:
      return state
  }
}