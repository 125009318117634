import React from 'react';

import "./MinesJoinButton.scss"
import Coin from "../../UI/Coin/Coin";

const MinesJoinButton = ({ bet, ...props }) => {
  return (
    <button className="mines__join join" {...props}>
      <div className="join__content">
        <span className="join__text">Join for</span>
        <span className="join__bet">
          <Coin className="join__img" />
          {bet}
        </span>
      </div>
    </button>
  );
};

export default MinesJoinButton;