import React from 'react';

import {Link} from "react-router-dom";

import MoneyBlock from "../../../../components/UI/MoneyBlock/MoneyBlock";
import Lock from "../../../../components/UI/Lock/Lock";

import locker from "../../../../assets/locker.svg"
import moneybox from "../../../../assets/bonuses/rakeback-bg.png"

import './Rakeback.scss'

const Rakeback = ({title, text, coin, className, access}) => {

  return (
    <Link to={access ? "/free/rakeback" : "/free"} className={'bonuses__card card bonuses__card-' + className + (access ? " card-hover" : "")}>

      { access ? '' : <Lock icon={locker} text={"Reach 10 level to open"} whiteColor={true}/> }
      <h4 className="card__title">{title}</h4>
      <div className="card__text">{text}</div>
      <MoneyBlock money={coin} icon={true}/>
      <img src={moneybox} alt="" className="card__image"/>
    </Link>
  );
};

export default Rakeback;