import React from 'react';

import {useTranslation} from "react-i18next";

import Lock from "../../../../components/UI/Lock/Lock";

import coin from "../../../../assets/coin.png";
import lightbg from "../../../../assets/bonuses/Light.svg"
import clock from "../../../../assets/clock.svg";

import './Giveaway.scss'

const Giveaway = ({name, quality, giveawayMoney, img, link, className}) => {
    const { t } = useTranslation(['bonuses'])

    return (
        <div
            className={'bonuses__card card bonuses__card-' + className + (link ? " card-hover" : "")}
            id={className}
            style={{
                background: `url('${img}') no-repeat right bottom / contain,
                             url('${lightbg}') no-repeat right bottom / cover,
                             #1B1F2A`}}
        >
            { link ? '' : <Lock icon={clock} text={"Draw isn’t available"} whiteColor={true}/> }
            <div className='card__win'><span className="card__win-text">WIN</span></div>
            <h4 className="card__title">{name}</h4>
            <div className="card__subtitle">
                <div className="card__quality">{quality && quality.replace('(', '').replace(')', '')}</div>
                <img className="giveaway-icon" src={coin} alt="O"/>
                <div className="giveaway-money">{giveawayMoney}</div>
            </div>
            <a href={link} target="_blank" rel="noreferrer" className={className + '-button'}>{t('bonuses:trophyButton')}</a>
        </div>
    );
};

export default Giveaway;