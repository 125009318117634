import React, {useState} from 'react';

import {ReactComponent as Circles} from "../../../assets/deposit/bg-crypto-card.svg";

import DepositCryproPopup from "../../../components/Popups/DepositCryptoPopup/DepositCryptoPopup";

import "./DepositCryptoCard.scss"


const DepositCryptoCard = ({logo, classForHover, qr, crypto}) => {

  const [openCryptoInfo, setOpenCryptoInfo] = useState(false)

  return (
    <div>
      <div className={"crypto-card " + (classForHover || '')} onClick={() => {
        setOpenCryptoInfo(true)
      }}>
        {logo}
        <Circles className={"crypto-card__bg " + (classForHover || '')}/>
        <div className="crypto-card__name">{crypto?.name}</div>
        {
          crypto?.wallet !== crypto?.network && <div className={"crypto-card__info "  + (classForHover || '')}>
            {crypto?.network}
          </div>
        }
      </div>
      {
        openCryptoInfo
          ?
          <DepositCryproPopup
            minDeposit={crypto?.minDeposit} fee={crypto?.depositFee} qr={qr} setOpen={setOpenCryptoInfo} name={crypto?.name} logo={logo}
            className={classForHover} info={crypto?.wallet !== crypto?.network ? crypto?.network : false} short={crypto?.short} wallet={crypto?.wallet} rate={crypto?.rate}/>
          :
          ''}
    </div>
  );
};

export default DepositCryptoCard;