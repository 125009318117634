import React from 'react';

import {ReactComponent as Scull} from "../../../assets/mines/scull-popup.svg";
import {ReactComponent as Close} from "../../../assets/mines/close-popup.svg";

import './MinesPopupPrivate.scss'
import {useDispatch} from "react-redux";
import {joinMines, showError} from "../../../redux/actions";
import {useHistory} from "react-router-dom";

const MinesPopupPrivate = ({setOpen, games}) => {
  const dispatch = useDispatch();

  function joinSecretGame(ev) {
    ev.preventDefault();
    const id = +(ev.target['0'].value);
    if(games) {
      const game = games.find(game => game.r_i === id);

      if(game) {
        dispatch(joinMines(id, game.p[0].b_a));
        setOpen(false)
      } else {
        dispatch(showError({text: "Can't find this game", translate: false}))
      }
    }
  }

  return (
    <div className="private" >
      <div className="private__background" onClick={() => {setOpen(false)}}/>
      <div className="private__popup">
        <Close className="private__close" onClick={() => {setOpen(false)}}/>
        <div className="private__title">
          <Scull/>
          <span>Mines battle</span>
        </div>
        <span className="private__text">Please, enter the code to join the private battle</span>
        <form className="private__form" onSubmit={joinSecretGame}>
          <input className="private__input" type="text"/>
          <button className="private__button">
            Join the private battle
          </button>
        </form>
      </div>
    </div>
  );
};

export default MinesPopupPrivate;