import React, {useEffect, useRef, useState} from "react";

import {useSelector} from "react-redux";

import Number from './Number';

import SpeedometrImg from '../../../assets/crash/speedometr.png';
import Divider from '../../../assets/crash/dividers.svg';
import Guides from '../../../assets/crash/guides.png';
import Guides1 from '../../../assets/crash/guides-1.png';
import Sec from '../../../assets/crash/sec.svg';
import Point from '../../../assets/crash/point.png'
import Circle1 from '../../../assets/crash/Circle1.svg';
import Circle2 from '../../../assets/crash/Circle2.svg';
import Circle3 from '../../../assets/crash/Circle3.svg';
import Circle4 from '../../../assets/crash/Circle4.svg';
import Circle5 from '../../../assets/crash/Circle5.svg';

import './Speedometr.scss';

export default function Speedometr({setX}) {

    const stage = useSelector(state => state.crash?.stage);
    const latency = useSelector(state => state.app?.serverLatency);
    const spinningStart = useSelector(state => state.crash.crash?.stageStart);
    const time = new Date() - new Date(spinningStart) - latency
    const serverX = useSelector(state => state.crash?.x);
    const [x, updateX] = useState(stage === 2 ? Math.exp(5e-5 * time) : (Math.max(serverX, 1) || 1));

    const tick = () => {
        updateX(stage === 2 ? Math.exp(5e-5 * time) : (serverX || 1))
        setX(x)
    };

    useEffect(() => {
        const timerID = setInterval(() => tick(), 10);
        return () => clearInterval(timerID);
    })

    // const [, setRandHash] = useState(0);
    //
    // setTimeout(() => {
    //     setRandHash(Math.random)
    // }, 10)

    // let date = new Date();
    //
    // useEffect(() => {
    //
    // })

    // useEffect(() => {
    //     setX(x);
    // }, [x])

    const calcNowRotate = (delimiter, countDelimiters = 4, x1SpeedGradate = 0.25, x2SpeedGradate = 1) => {
        // фиктивный x для проверок
        const fakeX = x;
        // изначальное положение
        const startX = fakeX >= 3 ? 3 : 1;
        // градация ячеек
        const gradate = fakeX >= 3 ? x2SpeedGradate : x1SpeedGradate;
        // изначальный градус поворота
        const rotate = delimiter * -5 - delimiter + 1;
        // минимальное значение скорости на участке
        const speedValueMin = startX + (gradate / countDelimiters) * (delimiter - 1)
        // максимальное значение скорости на участке
        const speedValueMax = startX + (gradate / countDelimiters) * (delimiter)
        // процент поворота, если прошлая ячейка достигла максимума
        const percentRotate = 1 - ((Math.max(fakeX, speedValueMin) - speedValueMin)
            / (speedValueMax - speedValueMin));
        // проверяем что прошлая ячейка достигла максимума
        const actualRotate = fakeX > speedValueMin ? -5 : rotate;

        // возвращаем нужное значение поворота
        return {transform: `rotateZ(${Math.min(actualRotate * percentRotate, 0)}deg)`};
    }

    return (
        <div className='speedometr'>
            <img src={Divider} alt="divider" className='speedometr-divider'/>
            <img src={Guides} alt="divider" className='speedometr-guides'/>
            <img src={Guides1} alt="divider" className='speedometr-guides-1'/>
            <img src={SpeedometrImg} alt="speedometr" className='speedometr-bg'/>
            <div className="speedometr__wrapper">
                <div className="circle__wrapper">
                    <img src={Circle1} className="circle circle-1" alt="" style={{transform: `scale(${0.8})`, transition: '0.1s all ease'}}/>
                    <img src={Circle2} className="circle circle-2" alt="" style={{transform: `scale(${0.8})`, transition: '0.1s all ease'}}/>
                    <img src={Circle3} className="circle circle-3" alt="" style={{transform: `scale(${0.8})`, transition: '0.1s all ease'}}/>
                    <img src={Circle4} className="circle circle-4" alt="" style={{transform: `scale(${0.8})`, transition: '0.1s all ease'}}/>
                    <img src={Circle5} className="circle circle-5" alt="" style={{transform: `scale(${0.8})`, transition: '0.1s all ease'}}/>
                </div>
            </div>
            <svg className="speed-gradient"
                 width="281" height="139" viewBox="0 0 281 139"
                 fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    style={calcNowRotate(32)}
                    d="M278.859 138.771C279.523 138.766 280.058 138.224 280.047 137.56C279.998 134.449 279.846 131.339 279.589 128.238C279.534 127.576 278.949 127.089 278.288 127.15L268.639 128.032C267.978 128.092 267.492 128.677 267.546 129.339C267.772 132.102 267.909 134.873 267.954 137.646C267.965 138.309 268.506 138.844 269.17 138.839L278.859 138.771Z"
                    fill="url(#paint0_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(31)}
                    d="M278.098 125.224C278.758 125.155 279.238 124.563 279.162 123.904C278.809 120.812 278.353 117.732 277.793 114.671C277.674 114.018 277.044 113.591 276.392 113.715L266.876 115.538C266.224 115.663 265.797 116.292 265.916 116.945C266.412 119.674 266.819 122.418 267.136 125.173C267.212 125.832 267.803 126.311 268.463 126.241L278.098 125.224Z"
                    fill="url(#paint1_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(30)}
                    d="M276.01 111.794C276.66 111.66 277.079 111.025 276.939 110.376C276.285 107.333 275.528 104.313 274.672 101.322C274.489 100.683 273.82 100.32 273.184 100.508L263.892 103.255C263.255 103.444 262.893 104.112 263.075 104.75C263.836 107.417 264.51 110.108 265.095 112.818C265.236 113.467 265.871 113.885 266.521 113.751L276.01 111.794Z"
                    fill="url(#paint2_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(29)}
                    d="M272.615 98.6357C273.249 98.4385 273.604 97.7648 273.401 97.1327C272.451 94.1691 271.402 91.2381 270.256 88.3446C270.012 87.7275 269.31 87.4316 268.695 87.6813L259.718 91.3263C259.103 91.5759 258.808 92.2767 259.051 92.8939C260.07 95.4731 261.005 98.0848 261.854 100.725C262.057 101.357 262.73 101.711 263.364 101.514L272.615 98.6357Z"
                    fill="url(#paint3_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(28)}
                    d="M267.95 85.8827C268.562 85.6244 268.849 84.9192 268.585 84.31C267.349 81.4538 266.018 78.6396 264.594 75.8723C264.291 75.2822 263.563 75.0564 262.976 75.3652L254.399 79.8724C253.811 80.1811 253.586 80.9074 253.889 81.4978C255.156 83.9647 256.342 86.4723 257.445 89.0165C257.709 89.6255 258.414 89.9119 259.025 89.6536L267.95 85.8827Z"
                    fill="url(#paint4_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(27)}
                    d="M262.051 73.6347C262.634 73.3176 262.85 72.5876 262.528 72.0073C261.018 69.2861 259.417 66.6161 257.729 64.0018C257.369 63.4443 256.623 63.291 256.068 63.6559L247.975 68.9828C247.421 69.3477 247.267 70.0926 247.627 70.6504C249.13 72.9812 250.556 75.3603 251.904 77.784C252.226 78.3641 252.955 78.5801 253.539 78.263L262.051 73.6347Z"
                    fill="url(#paint5_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(26)}
                    d="M254.991 62.0442C255.541 61.6716 255.684 60.9239 255.307 60.3779C253.538 57.8177 251.683 55.3173 249.747 52.881C249.334 52.3614 248.577 52.2818 248.061 52.6993L240.528 58.7932C240.012 59.2107 239.932 59.967 240.345 60.4869C242.069 62.6593 243.721 64.8873 245.3 67.1674C245.677 67.7131 246.424 67.8566 246.973 67.484L254.991 62.0442Z"
                    fill="url(#paint6_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(25)}
                    d="M246.827 51.1965C247.337 50.7718 247.407 50.0137 246.978 49.5073C244.966 47.1327 242.876 44.826 240.71 42.5912C240.248 42.1146 239.487 42.1096 239.014 42.5756L232.115 49.3783C231.642 49.8442 231.637 50.6047 232.098 51.0817C234.027 53.0747 235.89 55.1301 237.684 57.2446C238.113 57.7506 238.871 57.8203 239.381 57.3956L246.827 51.1965Z"
                    fill="url(#paint7_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(24)}
                    d="M237.636 41.1974C238.102 40.7248 238.097 39.9635 237.62 39.5016C235.386 37.3356 233.079 35.2449 230.705 33.2331C230.198 32.8041 229.44 32.8737 229.016 33.3838L222.816 40.8299C222.391 41.34 222.461 42.0973 222.967 42.5268C225.081 44.3212 227.137 46.1841 229.129 48.1125C229.606 48.5741 230.367 48.5691 230.833 48.0965L237.636 41.1974Z"
                    fill="url(#paint8_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(23)}
                    d="M227.518 32.1546C227.936 31.6387 227.856 30.8815 227.337 30.4685C224.9 28.5318 222.4 26.6771 219.84 24.9074C219.294 24.53 218.547 24.6736 218.174 25.2228L212.733 33.2403C212.361 33.7895 212.504 34.5363 213.05 34.9142C215.33 36.4929 217.557 38.1456 219.73 39.8696C220.249 40.2822 221.006 40.2028 221.423 39.6869L227.518 32.1546Z"
                    fill="url(#paint9_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(22)}
                    d="M216.515 24.1146C216.88 23.5601 216.726 22.8144 216.169 22.4545C213.554 20.7667 210.884 19.1669 208.162 17.6576C207.581 17.3357 206.851 17.5521 206.535 18.1353L201.909 26.6489C201.592 27.2321 201.808 27.9612 202.388 28.2836C204.812 29.6304 207.192 31.056 209.523 32.5581C210.081 32.9175 210.826 32.7642 211.191 32.2097L216.515 24.1146Z"
                    fill="url(#paint10_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(21)}
                    d="M204.861 17.2408C205.17 16.6533 204.944 15.9262 204.354 15.6224C201.587 14.198 198.773 12.8665 195.917 11.6302C195.308 11.3666 194.603 11.6532 194.344 12.2645L190.572 21.1892C190.314 21.8005 190.6 22.505 191.209 22.7692C193.753 23.8729 196.26 25.0594 198.727 26.3266C199.317 26.6299 200.044 26.4045 200.352 25.8171L204.861 17.2408Z"
                    fill="url(#paint11_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(20)}
                    d="M192.53 11.5129C192.78 10.898 192.484 10.1965 191.867 9.95211C188.974 8.80608 186.043 7.75712 183.079 6.80699C182.447 6.60436 181.773 6.95878 181.576 7.59253L178.698 16.8442C178.501 17.478 178.855 18.151 179.486 18.3542C182.127 19.203 184.738 20.1377 187.317 21.1568C187.935 21.4007 188.635 21.1051 188.885 20.4902L192.53 11.5129Z"
                    fill="url(#paint12_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(19)}
                    d="M179.715 7.02762C179.903 6.39116 179.54 5.72209 178.902 5.53932C175.91 4.68232 172.89 3.9256 169.848 3.27044C169.199 3.13072 168.563 3.54945 168.429 4.19947L166.471 13.6887C166.337 14.3387 166.755 14.9738 167.404 15.1141C170.114 15.7001 172.805 16.3744 175.471 17.1359C176.11 17.3181 176.778 16.9553 176.966 16.3189L179.715 7.02762Z"
                    fill="url(#paint13_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(18)}
                    d="M166.485 3.81287C166.61 3.16099 166.183 2.53083 165.53 2.41164C162.468 1.85273 159.389 1.39635 156.297 1.04326C155.637 0.967961 155.046 1.44714 154.976 2.10719L153.96 11.7429C153.89 12.403 154.369 12.9939 155.028 13.0697C157.783 13.3866 160.527 13.7933 163.256 14.2891C163.909 14.4077 164.538 13.9811 164.663 13.3292L166.485 3.81287Z"
                    fill="url(#paint14_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(17)}
                    d="M153.044 1.91709C153.105 1.25613 152.618 0.670851 151.956 0.616186C148.855 0.359861 145.745 0.20732 142.634 0.15882C141.97 0.148477 141.428 0.683295 141.424 1.34699L141.356 11.0359C141.351 11.6996 141.886 12.2408 142.549 12.2517C145.322 12.2972 148.093 12.4331 150.857 12.6593C151.518 12.7134 152.103 12.2271 152.163 11.5661L153.044 1.91709Z"
                    fill="url(#paint15_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(16)}
                    d="M139.459 1.34723C139.454 0.683536 138.912 0.148851 138.248 0.159356C135.136 0.208618 132.027 0.361919 128.926 0.619002C128.264 0.673829 127.778 1.25923 127.838 1.92018L128.722 11.569C128.782 12.2299 129.367 12.7161 130.028 12.6618C132.792 12.435 135.563 12.2984 138.336 12.2522C138.999 12.2412 139.533 11.6999 139.529 11.0362L139.459 1.34723Z"
                    fill="url(#paint16_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(15)}
                    d="M125.933 2.10776C125.863 1.44772 125.272 0.968572 124.612 1.0439C121.52 1.39713 118.441 1.85366 115.379 2.41271C114.727 2.53194 114.299 3.16212 114.424 3.81398L116.247 13.3302C116.372 13.9821 117.001 14.4087 117.654 14.29C120.383 13.7941 123.127 13.3873 125.882 13.0703C126.541 12.9944 127.02 12.4035 126.95 11.7434L125.933 2.10776Z"
                    fill="url(#paint17_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(14)}
                    d="M112.514 4.19387C112.38 3.54383 111.744 3.12497 111.095 3.26456C108.053 3.91912 105.033 4.67524 102.041 5.53164C101.403 5.71428 101.039 6.38329 101.227 7.01978L103.974 16.3116C104.162 16.9481 104.83 17.311 105.469 17.1289C108.135 16.3679 110.826 15.6942 113.537 15.1087C114.185 14.9686 114.604 14.3336 114.47 13.6835L112.514 4.19387Z"
                    fill="url(#paint18_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(13)}
                    d="M99.3446 7.59101C99.1474 6.95726 98.4737 6.60281 97.8416 6.80542C94.878 7.75543 91.947 8.80428 89.0535 9.9502C88.4364 10.1946 88.1405 10.896 88.3902 11.511L92.0352 20.4884C92.2849 21.1033 92.9856 21.3989 93.6029 21.155C96.1821 20.136 98.7938 19.2014 101.434 18.3527C102.066 18.1496 102.42 17.4766 102.223 16.8428L99.3446 7.59101Z"
                    fill="url(#paint19_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(12)}
                    d="M86.605 12.2502C86.3467 11.6388 85.6415 11.352 85.0324 11.6155C82.176 12.8511 79.3617 14.1818 76.5943 15.6055C76.0041 15.9091 75.7783 16.6361 76.087 17.2237L80.5933 25.8012C80.902 26.3887 81.6283 26.6143 82.2187 26.3111C84.6858 25.0445 87.1934 23.8588 89.7378 22.7557C90.3467 22.4917 90.6333 21.7873 90.375 21.1759L86.605 12.2502Z"
                    fill="url(#paint20_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(11)}
                    d="M74.3464 18.154C74.0294 17.5709 73.2994 17.3547 72.7191 17.6767C69.9978 19.1868 67.3278 20.7874 64.7135 22.4759C64.156 22.836 64.0027 23.5817 64.3675 24.1362L69.6943 32.2297C70.0592 32.7841 70.8041 32.9373 71.362 32.5776C73.6927 31.0749 76.0719 29.6487 78.4956 28.3011C79.0757 27.9786 79.2917 27.2494 78.9746 26.6663L74.3464 18.154Z"
                    fill="url(#paint21_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(10)}
                    d="M62.7525 25.2155C62.3798 24.6663 61.6322 24.5227 61.0862 24.9C58.526 26.6694 56.0256 28.5239 53.5893 30.4604C53.0697 30.8734 52.9902 31.6305 53.4076 32.1465L59.5017 39.6793C59.9191 40.1953 60.6755 40.2747 61.1954 39.8622C63.3677 38.1384 65.5957 36.4859 67.8757 34.9074C68.4214 34.5296 68.5649 33.7828 68.1923 33.2335L62.7525 25.2155Z"
                    fill="url(#paint22_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(9)}
                    d="M51.9123 33.3736C51.4876 32.8635 50.7295 32.7938 50.2231 33.2228C47.8484 35.2343 45.5416 37.3247 43.3066 39.4904C42.83 39.9523 42.8249 40.7136 43.2909 41.1863L50.0932 48.0862C50.5591 48.5589 51.3196 48.5639 51.7966 48.1024C53.7897 46.1742 55.8452 44.3115 57.9598 42.5173C58.4659 42.0879 58.5356 41.3306 58.111 40.8205L51.9123 33.3736Z"
                    fill="url(#paint23_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(8)}
                    d="M41.9071 42.5699C41.4345 42.1039 40.6732 42.1088 40.2113 42.5854C38.0453 44.8201 35.9546 47.1267 33.9428 49.5011C33.5137 50.0075 33.5834 50.7656 34.0934 51.1903L41.5396 57.3899C42.0496 57.8146 42.807 57.745 43.2364 57.2389C45.0308 55.1246 46.8937 53.0693 48.8222 51.0764C49.2837 50.5995 49.2788 49.839 48.8062 49.373L41.9071 42.5699Z"
                    fill="url(#paint24_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(7)}
                    d="M32.8495 52.7059C32.3335 52.2885 31.5763 52.3681 31.1634 52.8877C29.2271 55.3241 27.3727 57.8246 25.6036 60.385C25.2262 60.931 25.3699 61.6786 25.9192 62.0512L33.9376 67.4905C34.4868 67.8631 35.2337 67.7195 35.6114 67.1738C37.1898 64.8936 38.8421 62.6655 40.5657 60.4931C40.9782 59.9731 40.8988 59.2168 40.3827 58.7994L32.8495 52.7059Z"
                    fill="url(#paint25_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(6)}
                    d="M24.8411 63.665C24.2867 63.3001 23.541 63.4535 23.1809 64.0111C21.4926 66.6255 19.8921 69.2956 18.3822 72.0169C18.0602 72.5973 18.2764 73.3272 18.8595 73.6442L27.3721 78.2719C27.9553 78.5889 28.6844 78.3729 29.0069 77.7928C30.3543 75.369 31.7804 72.9897 33.283 70.6589C33.6426 70.1011 33.4894 69.3561 32.935 68.9913L24.8411 63.665Z"
                    fill="url(#paint26_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(5)}
                    d="M17.9301 75.383C17.3425 75.0744 16.6155 75.3002 16.3119 75.8904C14.8884 78.6579 13.5578 81.4723 12.3224 84.3287C12.0589 84.9379 12.3457 85.6431 12.9571 85.9013L21.883 89.6709C22.4944 89.9291 23.1988 89.6426 23.4628 89.0336C24.5657 86.4892 25.7514 83.9815 27.0178 81.5144C27.321 80.9239 27.0954 80.1977 26.5078 79.889L17.9301 75.383Z"
                    fill="url(#paint27_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(4)}
                    d="M12.2186 87.6849C11.6036 87.4352 10.9022 87.7312 10.6579 88.3483C9.51204 91.2418 8.46329 94.1729 7.51338 97.1365C7.3108 97.7686 7.66527 98.4423 8.29903 98.6394L17.551 101.517C18.1847 101.714 18.8577 101.36 19.0608 100.728C19.9094 98.0883 20.8439 95.4765 21.8628 92.8973C22.1067 92.28 21.8111 91.5793 21.1962 91.3296L12.2186 87.6849Z"
                    fill="url(#paint28_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(3)}
                    d="M7.73603 100.495C7.09957 100.307 6.4305 100.67 6.24775 101.308C5.39084 104.3 4.6342 107.32 3.97912 110.362C3.83941 111.011 4.25816 111.647 4.90819 111.781L14.3975 113.739C15.0475 113.873 15.6826 113.455 15.8228 112.806C16.4088 110.095 17.083 107.405 17.8444 104.738C18.0266 104.1 17.6638 103.432 17.0274 103.243L7.73603 100.495Z"
                    fill="url(#paint29_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(2)}
                    d="M4.52472 113.708C3.87286 113.583 3.24266 114.011 3.12341 114.663C2.56421 117.725 2.10754 120.804 1.75417 123.896C1.67881 124.556 2.15793 125.147 2.81798 125.217L12.4536 126.234C13.1137 126.304 13.7046 125.825 13.7805 125.166C14.0977 122.411 14.5046 119.667 15.0006 116.939C15.1194 116.286 14.6927 115.656 14.0409 115.531L4.52472 113.708Z"
                    fill="url(#paint30_linear_1640_26386)"/>
                <path
                    style={calcNowRotate(1)}
                    d="M2.62808 127.149C1.96712 127.088 1.38179 127.575 1.32706 128.237C1.07044 131.338 0.917601 134.447 0.868802 137.559C0.858395 138.223 1.39316 138.765 2.05686 138.769L11.7458 138.838C12.4095 138.843 12.9507 138.308 12.9617 137.645C13.0074 134.872 13.1436 132.101 13.37 129.337C13.4242 128.676 12.938 128.091 12.277 128.031L2.62808 127.149Z"
                    fill="url(#paint31_linear_1640_26386)"/>
                <defs>
                    <linearGradient id="paint0_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint4_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint5_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint6_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint7_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint8_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint9_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint10_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint11_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint12_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint13_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint14_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint15_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint16_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint17_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint18_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint19_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint20_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint21_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint22_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint23_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint24_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint25_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint26_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint27_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint28_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint29_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint30_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                    <linearGradient id="paint31_linear_1640_26386" x1="10.7525" y1="139.127" x2="164.338" y2="-4.37236"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#46BE60"/>
                        <stop offset="0.343855" stopColor="#E8E13F"/>
                        <stop offset="0.639757" stopColor="#FFB800"/>
                        <stop offset="1" stopColor="#F6442D"/>
                    </linearGradient>
                </defs>
            </svg>

            <span className={'speed-value speed-value-1' + (x > 3 ? ' speed-value-xl' : '')}>{x < 3 ? '1.00' : '3'}</span>
            <span className={'speed-value speed-value-2' + (x > 3 ? ' speed-value-xl' : '')}>{x < 3 ? '1.25' : '4'}</span>
            <span className={'speed-value speed-value-3' + (x > 3 ? ' speed-value-xl' : '')}>{x < 3 ? '1.50' : '5'}</span>
            <span className={'speed-value speed-value-4' + (x > 3 ? ' speed-value-xl' : '')}>{x < 3 ? '1.75' : '6'}</span>
            <span className={'speed-value speed-value-5' + (x > 3 ? ' speed-value-xl' : '')}>{x < 3 ? '2.00' : '7'}</span>
            <span className={'speed-value speed-value-6' + (x > 3 ? ' speed-value-xl' : '')}>{x < 3 ? '2.25' : '8'}</span>
            <span className={'speed-value speed-value-7' + (x > 3 ? ' speed-value-xl' : '')}>{x < 3 ? '2.50' : '9'}</span>
            <span className={'speed-value speed-value-8' + (x > 3 ? ' speed-value-xl' : '')}>{x < 3 ? '2.75' : '10'}</span>
            <span className={'speed-value speed-value-9' + (x > 3 ? ' speed-value-xl' : '')}>{x < 3 ? '3.00' : '10+'}</span>

            <div className="speedometr-value">
                <Number number={Math.floor(x % 1000 / 100)}/>
                <Number number={Math.floor(x % 100 / 10)}/>
                <Number number={Math.floor(x % 10)}/>
                <img src={Point} alt=""  className="speedometr-point"/>
                <Number number={Math.floor(x * 10 % 10)} />
                <Number number={Math.floor(x * 100 % 10)}/>
            </div>

            <img src={Sec} alt="sec"  className='speedometr-sec'/>
        </div>
    )
}