import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

import { useSelector, useDispatch } from 'react-redux'

import { ReactComponent as Track } from '../../../assets/race-track.svg';
import { ReactComponent as Track2 } from '../../../assets/race-track2.svg';

import './RaceAnimate.scss'
import { hideTrackRace } from "../../../redux/actions";
import GameOver from "../../Popups/GameOver/GameOver";
// import RaceTimeout from "./RaceTimeout/RaceTimeout";
import Profile from "../../UI/Profile/Profile";
import Coin from "../../UI/Coin/Coin";

const RaceAnimate = ({ game }) => {
  gsap.registerPlugin(MotionPathPlugin);

  const dispatch = useDispatch()
  const showTrack = useSelector(state => state.race.showTrack)

  const textRef = useRef(null);
  const DeepRef = gsap.utils.selector(textRef);

  useEffect(() => {
      const blueCar = gsap.timeline({
        yoyo: true,
      });

      const greenCar = gsap.timeline({
        yoyo: true
      });

      function stateBlueCar(track2, duration = 8, ease = "Power2.easeOut", end = -1.44, start = -0.4) {
        switch (track2) {
          case true:
            blueCar.to(DeepRef("#blue-car"), {
              duration,
              position: 'relative',
              immediateRender: true,
              delay: 2,
              motionPath: {
                path: "#pathCars",
                align: "#pathCars",
                alignOrigin: [0.5, 0.5],
                autoRotate: true,
                start,
                end,
              },
              ease
            }, -1)
            break;

          default:
            blueCar.to(DeepRef("#blue-car"), {
              duration,
              position: 'relative',
              immediateRender: true,
              delay: 2,
              motionPath: {
                path: "#pathCars",
                align: "#pathCars",
                alignOrigin: [.8, .8],
                autoRotate: true,
                start,
                end,
              },
              ease
            }, -1)
            break;
        }

      }

      function stateGreenCar(track2 = false, duration = 7, ease = "Power1.inOut", end = -1.52, start = -0.4,) {
        switch (track2) {
          case true:
            greenCar.to(DeepRef("#green-car"), {
              duration,
              position: 'relative',
              immediateRender: true,
              delay: 2,
              motionPath: {
                path: "#pathCars2",
                align: "#pathCars2",
                alignOrigin: [0.5, 0.5],
                autoRotate: true,
                start,
                end,
              },
              ease
            }, -1)
            break;
          default:
            greenCar.to(DeepRef("#green-car"), {
              duration,
              position: 'relative',
              immediateRender: true,
              delay: 2,
              motionPath: {
                path: "#pathCars",
                align: "#pathCars",
                alignOrigin: [1.3, 1.3],
                autoRotate: true,
                start,
                end,
              },
              ease
            }, -1)
            break;
        }
      }

      function positionFrameBlueCar(x = 250, y = 40, rotation = 90, duration = 0, time = -1) {
        blueCar.to(DeepRef("#blue-frame"), {
          scale: .9,
          position: 'absolute',
          x,
          y,
          rotation,
          duration,
          yoyo: true
        }, time)
      }

      function positionFrameGreenCar(x = 250, y = 40, rotation = 90, duration = 0, time = -1) {
        greenCar.to(DeepRef("#red-frame"), {
          scale: .9,
          position: 'absolute',
          x,
          y,
          rotation,
          duration
        }, time)
      }
    // setTimeout(() => {
      if (showTrack && showTrack.w) {
        if (showTrack.r_i % 2 === 0) {
          if (showTrack.w === showTrack.f_p.s_i) {
            if (document.querySelector('#blue-frame')) document.querySelector('#blue-frame').innerHTML
              = document.querySelector('#blue-frame')?.innerHTML + `<image rx="20" ry="20" width="87" height="87" style="transform:translate(10px, 10px)" href="` + showTrack.s_p.a + `" />`
            if (document.querySelector('#red-frame')) document.querySelector('#red-frame').innerHTML
              = document.querySelector('#red-frame')?.innerHTML + `<image rx="20" ry="20" width="87" height="87" style="transform:translate(10px, 10px)" href="` + showTrack.f_p.a + `" />`
            //first animation
            // winner
            blueCar.call(stateBlueCar, [false, 12, "Power3.easeOut", -1.46])
              .add(positionFrameBlueCar)

            //loss
            greenCar.call(stateGreenCar, [false, 7, "Power1.inOut", -1.52])
              .add(positionFrameGreenCar)
          } else if (showTrack.w === showTrack.s_p?.s_i) {
            if (document.querySelector('#blue-frame')) document.querySelector('#blue-frame').innerHTML
              = document.querySelector('#blue-frame')?.innerHTML + `<image rx="20" ry="20" width="87" height="87" style="transform:translate(10px, 10px)" href="` + showTrack.s_p.a + `" />`
            if (document.querySelector('#red-frame')) document.querySelector('#red-frame').innerHTML
              = document.querySelector('#red-frame')?.innerHTML + `<image rx="20" ry="20" width="87" height="87" style="transform:translate(10px, 10px)" href="` + showTrack.f_p.a + `" />`
            //twice animation
            // loss
            blueCar.call(stateBlueCar, [false, 7, "Power1.inOut", -1.52])
              .add(positionFrameBlueCar)
            //winner
            greenCar.call(stateGreenCar, [false, 12, "Power3.easeOut", -1.46])
              .add(positionFrameGreenCar)
          }

        } else {
          if (showTrack.w === showTrack.f_p.s_i) {
            if (document.querySelector('#blue-frame')) document.querySelector('#blue-frame').innerHTML
              = document.querySelector('#blue-frame')?.innerHTML + `<image rx="20" ry="20" width="87" height="87" style="transform:translate(10px, 10px)" href="` + showTrack.f_p.a + `" />`
            if (document.querySelector('#red-frame')) document.querySelector('#red-frame').innerHTML
              = document.querySelector('#red-frame')?.innerHTML + `<image rx="20" ry="20" width="87" height="87" style="transform:translate(10px, 10px)" href="` + showTrack.s_p.a + `" />`
            // winner
            blueCar.call(stateBlueCar, [true, 7, "Power1.inOut", -1.35, -0.195])
              .add(positionFrameBlueCar(250, 35, 90, 0.5, 0))

            // //loss
            greenCar.call(stateGreenCar, [true, 10.5, "Power3.easeOut", -1.26, -0.17])
              .add(positionFrameGreenCar(250, 35, 90, 0.5, 0))
          } else if (showTrack.w === showTrack.s_p?.s_i) {
            if (document.querySelector('#blue-frame')) document.querySelector('#blue-frame').innerHTML
              = document.querySelector('#blue-frame')?.innerHTML + `<image rx="20" ry="20" width="87" height="87" style="transform:translate(10px, 10px)" href="` + showTrack.f_p.a + `" />`
            if (document.querySelector('#red-frame')) document.querySelector('#red-frame').innerHTML
              = document.querySelector('#red-frame')?.innerHTML + `<image rx="20" ry="20" width="87" height="87" style="transform:translate(10px, 10px)" href="` + showTrack.s_p.a + `" />`
            // loss
            blueCar.call(stateBlueCar, [true, 7, "Power3.inOut", -1.24, -0.195])
              .add(positionFrameBlueCar(250, 35, 90, 0.5, 0))
            // // winner
            greenCar.call(stateGreenCar, [true, 7.7, "Power1.easeOut", -1.33, -0.17])
              .add(positionFrameGreenCar(250, 35, 90, 0.5, 0))
          }
        }
      }
    // }, 3000)
  }, [showTrack]);

  useEffect(() => {
    return () => {
      dispatch(hideTrackRace())
    }
  }, [])

  if (game) {
    return (
      <div className={"race-track " + (showTrack ? 'open' : 'open')} ref={textRef}>

        {/* Тайм-аут для рейса (3...2...1...Go!) пока не ставить */}
        {/*{game.state === 1 ? <RaceTimeout /> : ''}*/}

        <div className="race-track-header">
          <div className="header-racer blue">
            <div className="header-racer-header">
              <span>1</span>
            </div>
            <div className="header-racer-body">
              <Profile item={game.f_p}/>
              <div className="header-racer-info">
                <div className="bet">
                  <Coin />
                  <span className={game.state === 2 ? (game.w === game.f_p.s_i ? "win" : "lose") : ""}>
                  {game.state === 2
                    ?
                    (game.w === game.f_p.s_i
                      ?
                      <>
                        +{(game.w_a - game.f_p.b_a).toFixed(2)}
                      </>
                      :
                      <>
                        -{game.f_p.b_a.toFixed(2)}
                      </>)
                    :
                    <>
                      {game.f_p?.b_a.toFixed(2)}
                    </>
                  }
                </span>
                </div>
                <span>{game.f_p.u}</span>
              </div>
            </div>
          </div>
          <div className="header-racer red">
            <div className="header-racer-header">
              <span>2</span>
            </div>
            { game.s_p !== undefined
              ?
              <div className="header-racer-body">
              <Profile item={game.s_p}/>
              <div className="header-racer-info">
                <div className={"bet " + (game?.s_p ? '' : 'disabled')}>
                  <Coin />
                  <span className={game.state === 2 ? (game.w === game.s_p.s_i ? "win" : "lose") : ""}>
                  {game.state === 2
                    ?
                    (game.w === game.s_p.s_i
                      ?
                      <>
                        +{(game.w_a - game.s_p.b_a).toFixed(2)}
                      </>
                      :
                      <>
                        -{game.s_p.b_a.toFixed(2)}
                      </>)
                    :
                    <>
                      {game.s_p?.b_a.toFixed(2)}
                    </>
                  }
                </span>
                </div>
                <span>{game.s_p?.u}</span>
              </div>
            </div>
            :
              <div className="header-racer-body">
                <div className={'bets-avatar'}>
                  <div className="level">?</div>
                </div>
                <div className="header-racer-info">
                  <div className={"bet"}>
                    <Coin />
                    <span>
                      {game.f_p?.b_a.toFixed(2)}
                </span>
                  </div>
                  <span>Waiting for the player...</span>
                </div>
              </div>
            }
          </div>
        </div>
        {game.state === 2 && <GameOver game={game} />}
        {game ? (game.r_i % 2 === 0 ? <Track /> : <Track2 />) : ''}
      </div>
    );
  } else return '';
}
export default RaceAnimate;
