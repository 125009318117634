import React from 'react'

import Arrow from '../../../assets/mines/arrow.svg'


import '../Mines/MinesButton.scss'

const MinesButton = ({isActive = true}) => {
  return (
    <button className="create-button" disabled={!isActive}>Create battle <img src={Arrow} alt="" /></button>
  )
}
export default MinesButton