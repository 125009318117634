import React, { useState } from 'react';

import { ReactComponent as Arrow } from '../../../assets/bonuses/toggle-arrow.svg';

import './Depend.scss';
import Coin from "../../../components/UI/Coin/Coin";

const Depend = ({ allDailyBonuses, activeDailyBonus }) => {
  const bonuses = reformatLevels(allDailyBonuses, activeDailyBonus);
  const [openList, setOpenList] = useState(false);

  function reformatLevels(levels, active) {
    const res = [];
    for (let i = 0; i < levels.length; i++) {
      res.push({
        ...levels[i],
        isActive: levels[i]?.level === active?.level
      });
    }
    return res;
  }

  return (
    <div className="daily__depend depend">
      <div className="depend__title">Bonus depends on your level 
        <div onClick={() => { setOpenList(!openList) }} className={"depend__toggle " + (openList ? "active" : '')}>
          <Arrow />
        </div>
      </div>
      <ul className={"depend__list " + (openList ? "active" : '')}>
        {bonuses.map(level =>
          <li className="depend__point" key={level.level}>
            <div className={"depend__num level-" + level.level}>{level.name}</div>
            {/* <div className="depend__line"></div> */}
            <div className={"depend__percent " + (level.isActive ? "depend__percent-active" : "")}> <div className="depend__value"> <Coin /> {level.minAmount}-{level.maxAmount}</div></div>
          </li>)}
      </ul>
    </div>
  );
};

export default Depend;