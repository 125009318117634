import React from 'react';

import DepositDisclaimer from "./DepositDisclaimer";
// import DepositFirstBonus from "./DepositFirstBonus";
import DepositSkins from "./DepositSkins";
import DepositCrypto from "./DepositCrypto";
import DepositRefferalBonus from "./DepositRefferalBonus";

import Back from "../../assets/arrow-bug.svg";
import {Link} from "react-router-dom";

import "./Deposit.scss"

const Deposit = () => {
  return (
    <div className="deposit">
      <div className="deposit__header">
        <Link className="deposit__back" to="/streetrace"><img src={Back} alt="" /></Link>
        <span>Deposit</span>
      </div>
      <DepositRefferalBonus />
      <DepositSkins />
      <DepositCrypto />
      <DepositDisclaimer />
    </div>
  );
};

export default Deposit;