import React, {useEffect} from 'react';

import {useDispatch, useSelector} from "react-redux";
import {depositSkinsbackHandler, depositWaxpeerHandler} from "../../redux/actions";

import SkinsBack from "../../assets/deposit/skinsback.png";
import SkinsWrap from "../../assets/deposit/skinswrap.svg";
import Waxpeer from "../../assets/deposit/waxpeer.svg";
import CsGo from "../../assets/deposit/csgo.svg";
import Dota from "../../assets/deposit/dota.svg";
import Rust from "../../assets/deposit/rust.svg";

import DepositSkinsCard from "./Cards/DepositSkinsCard";

import "./DepositSkins.scss"

const DepositSkins = () => {
  const dispatch = useDispatch();
  const skinsbackLink = useSelector(state => state.deposit.skinsback_link)
  const waxpeerLink = useSelector(state => state.deposit.waxpeer_link)

  useEffect(() => {
    dispatch(depositWaxpeerHandler())
    dispatch(depositSkinsbackHandler())
  }, [])

  return (
    <div className="deposit__skins skins">
      <div className="skins__title">Skins</div>
      <div className="skins__cards">
        <DepositSkinsCard link={skinsbackLink} logo={SkinsBack} name={CsGo} />
        <DepositSkinsCard link={waxpeerLink} logo={Waxpeer} name={CsGo} />
        <DepositSkinsCard link={skinsbackLink} logo={SkinsBack} name={Dota} />
        <DepositSkinsCard link={skinsbackLink} logo={SkinsBack} name={Rust} />
      </div>
    </div>
  );
};

export default DepositSkins;