import React, {useEffect} from 'react';

const AttemptsLeftBlock = ({dailyBonusResult, attempts}) => {

  function getAttemptsLeftIndicators(amount) {
    const res = [];
    for (let i = 0; i < amount; i++) {
      res.push(
        <div className="game__attempts-left" key={amount} id={i}></div>
      )
    }
    return res;
  }

  useEffect(() => {
    document.getElementById(attempts - dailyBonusResult?.length - 1)?.classList.add('game__attempts-left-active');
  }, [dailyBonusResult])

  return (
    <div className="game__left">
      <div className="game__label">Attempts {attempts - dailyBonusResult?.length}/{attempts}</div>
      <div className="game__attempts">
        {getAttemptsLeftIndicators(attempts)}
      </div>
    </div>
  );
};

export default AttemptsLeftBlock;