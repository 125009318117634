import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { createGame, getRaceHandler, hideTrackRace, inputAmount, gameWithRaceBot } from '../redux/actions'

import GameNav from '../components/Sections/GameNav/GameNav'
import GameHeader from '../components/Games/GameHeader/GameHeader'
import GameAmount from '../components/Games/GameAmount/GameAmount'
import RaceAnimate from '../components/Games/Race/RaceAnimate'

import RacePopup from '../components/Games/Race/RacePopup/RacePopup'
import bot from '../assets/race/bot.svg'

import ButtonYellow from '../components/UI/ButtonYellow/ButtonYellow'
import RaceGame from '../components/Games/Race/RaceGame'

import './Race.scss'

const Race = () => {

  const { t } = useTranslation(['race', 'crash'])

  const dispatch = useDispatch()

  const rates = useSelector(state => state.amount.rates)
  const amount = useSelector(state => state.amount.amount)
  const race = useSelector(state => state.race.race)
  const games = useSelector(state => state.race.games)
  const user = useSelector(state => state.app.user)
  const currentGame = games.find(game => game?.f_p?.s_i === user?.steamId || game?.s_p?.s_i === user?.steamId)
  const [openPopup, setOpenPopup] = useState(false)
  const [, setJoinRace] = useState(false)

  useEffect(() => {
    dispatch(getRaceHandler())
    //eslint-disable-next-line
  }, [])

  return (
    <div className="race">
      <GameNav />
      <div className="race__header">
        <GameHeader title={t('race:race')} popupText={t('race:popupText')} cut={true} />
        <div className="race__buttons">
        {!(games.find(game => game?.f_p?.s_i === user?.steamId)?.state) && games.find(game => game?.f_p?.s_i === user?.steamId) ?
          <button className="race-create race-create-bot 1" onClick={() => dispatch(gameWithRaceBot(
            (games.find(game => game?.f_p?.s_i === user?.steamId))?.r_i
          ))}>
            <img src={bot} className="race-bot-iconx" alt="" />
            <span>Play with bot</span>
          </button> : '' }

          {!(games.find(game => game?.f_p?.s_i === user?.steamId)?.state) && games.find(game => game?.f_p?.s_i === user?.steamId) ?
          <div className="race__button-cancel" onClick={() => { setOpenPopup(true) }}>Delete the game</div> : " "}
        </div>
      </div>
      <RaceAnimate game={currentGame} />
      <div className={currentGame?.state === 1 ? "disable" : " "}>
        <GameAmount rates={rates} changeAmount={inputAmount} withBot={true}
          button={<ButtonYellow className={'race-create'} disabled={!user}
            onClick={() => dispatch(createGame(amount),
            )}>{t('race:create')}</ButtonYellow>} amount={amount} game={race} />
        {games.length > 0
          ?
            <div className="race-games">
              {games.map(game => <RaceGame key={game.r_i} game={game} />)}
            </div>
            :
            <div className="race-no-games">
              <p>It seems no one has</p>
              <p>created the race yet</p>
              <p>Be the first to create one! Make a bet and click “Create public game” or “Play with bot” to start</p>
            </div>
        }
      </div>
      {openPopup ? <RacePopup setOpen={setOpenPopup} game={games.find(game => game?.f_p?.s_i === user?.steamId)} joinRace={setJoinRace} /> : ''}
    </div>
  )
}
export default Race;
