import React from 'react'

import Hedef from '../../../assets/probability.svg'

import './MinesProbability.scss'
import {useSelector} from "react-redux";
import Coin from "../../UI/Coin/Coin";

const MinesProbability = () => {
  const settings = useSelector(state => state.mines.settings);
  const betAmount = settings?.b_a || 0;

  return (
    <div className="mines__probability probability">
      <div className="probability__title">Probability of winning</div>
      <img src={Hedef} alt="" className="probability__image"/>
      <ul className="probability__list">
        <li className="probability__point">
          <div className="probability__num">
            <div className="probability__coin"><Coin/></div>
            0-{ +(betAmount * 0.4).toFixed(1) }
          </div>
          <div className="probability__line"></div>
          <div className="probability__percent">35.9%</div>
        </li>
        <li className="probability__point">
          <div className="probability__num">
            <div className="probability__coin"><Coin/></div>
            { +(betAmount * 0.4).toFixed(1) }-{ +(betAmount * 0.8).toFixed(1) }
          </div>
          <div className="probability__line"></div>
          <div className="probability__percent">23%</div>
        </li>
        <li className="probability__point">
          <div className="probability__num">
            <div className="probability__coin"><Coin/></div>
            { +(betAmount * 0.8).toFixed(1) }-{ +(betAmount * 1.2).toFixed(1) }
          </div>
          <div className="probability__line"></div>
          <div className="probability__percent">14.7%</div>
        </li>
        <li className="probability__point">
          <div className="probability__num">
            <div className="probability__coin"><Coin/></div>
            { +(betAmount * 1.2).toFixed(1) }-{ +(betAmount * 2).toFixed(1) }
          </div>
          <div className="probability__line"></div>
          <div className="probability__percent">15.6%</div>
        </li>
        <li className="probability__point">
          <div className="probability__num">
            <div className="probability__coin"><Coin/></div>
            { +(betAmount * 2).toFixed(1) }-{ +(betAmount * 5).toFixed(1) }
          </div>
          <div className="probability__line"></div>
          <div className="probability__percent">10.4%</div>
        </li>
        <li className="probability__point">
          <div className="probability__num">
            <div className="probability__coin"><Coin/></div>
            { +(betAmount * 5).toFixed(1) }+
          </div>
          <div className="probability__line"></div>
          <div className="probability__percent">0.4%</div>
        </li>
      </ul>

    </div>
  )
}
export default MinesProbability;