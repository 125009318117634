import React, {useEffect, useState} from 'react';
import {ReactComponent as TimerImg} from "../../../assets/mines/alarm.svg";

import './Timer.scss'

const Timer = ({maxMoveTime, stage, lastMove}) => {
  const [time, setTime] = useState(0)

  const tick = () => {
    setTime(stage === 1 || stage === 2
      ? Math.min(Math.max(+((maxMoveTime / 1000) - (Date.now() - new Date(lastMove)) / 1000), 0), 10).toFixed(2)
      : '10.00')
  };

  useEffect(() => {
    const timerID = setInterval(() => tick(), 10);
    return () => clearInterval(timerID);
  })

  return (
    <div className="timer-component">
      <TimerImg/>
      <div className="timer-component__num">
        {
          time
        }
      </div>
      <span> sec</span>
    </div>
  );
};

export default Timer;