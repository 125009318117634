import React, { useEffect } from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {useTranslation} from 'react-i18next'
import {changeLoader, getBonusesHandler, getMinesBattleHandler} from '../../redux/actions'

import DailyBonus from "./BonusesCards/DailyBonus/DailyBonus";
import Achievements from "./BonusesCards/Achievements/Achievements";
import Rakeback from "./BonusesCards/Rakeback/Rakeback";
import Giveaway from "./BonusesCards/Giveaway/Giveaway";

import './Bonuses.scss'

const Bonuses = () => {

  const {t} = useTranslation(['bonuses']);
  const dispatch = useDispatch();
  const bonuses = useSelector(state => state.bonuses?.bonuses);
  const userLevel = useSelector(state => state.app.user.level);
  const achievements = useSelector(state => state.app.user.achievements);
  const achievementsAmount = (Object.values(achievements)).filter(el => el > 0).length;

  useEffect(() => {
    dispatch(changeLoader(true));
    dispatch(getBonusesHandler());
    dispatch(getMinesBattleHandler());
  }, [])

  return (
    <div className="bonuses">
      <h3 className="bonuses__title">{t('bonuses:bonuses')}</h3>
      <div className="bonuses__wrapper">
        <DailyBonus className="dailyBonus" title={t('bonuses:dailyBonusTitle')}
                    text={t('bonuses:dailyBonusText')} access={userLevel >= bonuses.dailyBonus} />
        <Achievements className="achievements" title={t('bonuses:myAchievementsTitle')}
                        text={t('bonuses:myAchievementsText')} crown={achievementsAmount}/>
        <Rakeback className="rakeback" title={t('bonuses:rakebackTitle')} text={t('bonuses:rakebackText')}
                  coin={bonuses?.availableRakeback} access={userLevel >= bonuses.rakeback} />
        <Giveaway className="giveaway" img={bonuses?.giveawayImage} name={bonuses?.giveawayName}
                quality={bonuses?.giveawayQuality} link={bonuses?.giveawayLink} giveawayMoney={bonuses?.giveawayAmount}/>
      </div>
    </div>
  )
}

export default Bonuses