import React from 'react';

import './MinesRules.scss';
const MinesRules = () => {

  return (
    <div className="mines-rules">
      <div className="mines-rules__wrapper">
        <div className="mines-rules__title">
          How does it work?
        </div>
        <div className="mines-rules__text">
          <p>Mines battle is an online game where 2 players are given a field with cards, and each player, in turn, opens the cards with coins inside.</p>
          <p>The player who has collected the most coins from all cards wins, and the winner takes the entire bank.</p>
        </div>
        <div className="mines-rules__feature">New game on CSGO RACE!</div>
      </div>
      <div className="mines-rules__decore"></div>
    </div>
  );
};

export default MinesRules;