import React, { useEffect } from 'react';

import { useDispatch, useSelector } from "react-redux";
import {getBonusesHandler, getRakebackHandler} from "../../redux/actions";

import Receive from "./Receive/Receive";
import Statistic from "./Statistic/Statistic";
import Level from "./Level/Level";
import BackArrow from "../../components/UI/BackArrow/BackArrow";
import Popup from "../../components/Popups/PopupInfo/Popup";

import './RakebackPage.scss';

const RakebackPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.app.user);
  const rakeback = useSelector(state => state.bonuses.rakeback);

  useEffect(() => {
    dispatch(getRakebackHandler());
    dispatch(getBonusesHandler());
  }, []);

  return (
    <div className="rakeback">
      <div className="rakeback__header">
        <BackArrow link={"/free"} />
        <h3 className="rakeback__title">Rakeback</h3>
        <Popup className="rakeback__popup" popupText={"Rakeback is a bonus a user can receive in case of loss in the past 7 days. The percentage of rakeback depends on the user's level."}/>
      </div>
      <div className="rakeback__wrapper">
        <Receive availableRakeback={user?.availableRakeback} />
        <Statistic weeklyWin={user?.weeklyWin} weeklyBet={user?.weeklyBet} forAllTime={user?.claimedRakeback + user?.availableRakeback} />
        <Level bonus={rakeback} level={user?.level} />
      </div>
    </div>
  );
};

export default RakebackPage;