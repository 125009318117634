export const bugData = [
    {
        id: 1,
        ru: {
            title: 'Грамматические ошибки или стилистика текста / Ошибки в дизайне / Проблемы с адаптацией сайта под какое — то устройство',
            answer: 'Если Вы обнаружили у нас одну из вышеперечисленных ошибок, просим Вас обратиться в Поддержку. За это Вы можете:',
            itemOne : "Обеспечить себе успешное начало карьерного роста, например, попадание в нашу команду Модераторов, Поддержки сайта или в Команду Админов!",
            itemTwo: "Попав в команду Модераторов, Поддержки или Админов вы сможете познакомиться и пообщаться с создателями этого крутого проекта;",
            
        },
        en: {
            title: 'Grammatical errors or text stylistics errors /Design errors/Problem with adapting the site for some device',
            answer: 'If you’ve found one of the errors mentioned above, please contact Support. For this you can:',
            itemOne : "Get a successful start to your career, such as joining our Moderator, Site Support or Admin team. By joining a team of moderators, support or admins, you can get to know and communicate with the creators of this cool project.",
        },
        de: {
            title: 'Grammatical errors or text stylistics errors /Design errors/Problem with adapting the site for some device',
            answer: 'If you’ve found one of the errors mentioned above, please contact Support. For this you can:',
            itemOne : "Get a successful start to your career, such as joining our Moderator, Site Support or Admin team. By joining a team of moderators, support or admins, you can get to know and communicate with the creators of this cool project.",
        },
        pt: {
            title: 'Erros de gramática ou estilo de texto / Erros de design / Problemas para adaptar o site a qualquer dispositivo ',
            answer: 'Se você encontrar um dos erros mencionados acima, entre em contato com o Suporte. Graças a isso, você pode: ',
            itemOne : "começar uma carreira de sucesso, por exemplo, entrando em nossa equipe de Moderadores, Suporte do site ou Administradores!  ",
            itemTwo: "uma vez na equipe de Moderadores, Suporte ou Administradores, você poderá conhecer e conversar com os criadores deste projeto legal;  ",
        },
        es: {
            title: 'Errores gramaticales o de estilística / Errores de diseño / Problemas de adaptación del sitio web a un dispositivo en concreto',
            answer: 'Si has encontrado alguno de los errores anteriores, ponte en contacto con el Soporte técnico. Con esto puedes:',
            itemOne : "Asegurarte de empezar con buen pie una carrera, por ejemplo, uniéndote a nuestro equipo de Moderadores, de Soporte técnico o de Administración.",
            itemTwo: "Si te unes al equipo de Moderadores, de Soporte o de Administración, podrás conocer e interactuar con los creadores de este genial proyecto.",
        },
        open: false
    },
    {
        id: 2,
        ru: {
            title: 'Баги (что-то не функционирует, например, вас перекидывает на другую страницу, не работают кнопки и т.п.)',
            answer: 'Если вы обнаружили подобный баг, то Поддержка награждает вас призом № 1, а также вы сможете:',
            itemOne : "подать заявку на вступление в закрытое сообщество, общаться с владельцами сайта, самыми лучшими игроками и самыми преданными пользователями сайта. В этом сообществе обсуждается абсолютно все (криптовалюты, казино, бизнес, игры, новинки, которые будут выпускаться, и т д.)",
        },
        en: {
            title: "Bugs (something doesn't work, e.g. you get redirected to another page or buttons don't work, etc.)",
            answer: 'If you find a similar bug, then Support rewards you with the No. 1 prize, and you can also:',
            itemOne : "Apply to join a closed community, communicate with the owners of the site, the top players and the most loyal users of the site. Absolutely everything is discussed in this community (cryptocurrencies, casinos, business, games, new products releasing soon and much more).",
        },
        de: {
            title: "Bugs (something doesn't work, e.g. you get redirected to another page or buttons don't work, etc.)",
            answer: 'If you find a similar bug, then Support rewards you with the No. 1 prize, and you can also:',
            itemOne : "Apply to join a closed community, communicate with the owners of the site, the top players and the most loyal users of the site. Absolutely everything is discussed in this community (cryptocurrencies, casinos, business, games, new products releasing soon and much more).",
        },
        pt: {
            title: 'Bugs (algo não funciona, por exemplo, redireciona para outra página, botões não funcionam, etc.)',
            answer: 'Se você encontrar um bug, o Suporte o recompensará com o Prêmio nº 1, e você também poderá: ',
            itemOne : "se inscrever para participar na comunidade fechada, comunicar-se com os proprietários do site, os melhores jogadores e os usuários mais dedicados do site.  Absolutamente tudo é discutido nesta comunidade (criptomoedas, cassinos, negócios, jogos, novos itens que serão lançados, etc.)",
        },
        es: {
            title: 'Bugs (algo no funciona, por ejemplo, te redirige a otra página, los botones no funcionan, etc.)',
            answer: 'Si encuentras un bug de este tipo, el servicio de Soporte técnico te recompensará con el premio № 1 y también podrás:',
            itemOne : "Solicitar unirte a una comunidad cerrada, comunicarte con los propietarios del sitio, los mejores jugadores y los usuarios más fieles del sitio. En esta comunidad se habla de absolutamente todo (criptomonedas, casinos, negocios, juegos, nuevos productos que se van a lanzar, etc.)",
        },
        open: false
    },
    {
        id: 3,
        ru: {
            title: 'Баг с помощью которого можно будет накрутить уровень на сайте / Открыть ачивки не выполняя их /  Получать бонусы не по правилам',
            answer: 'За эти баги Поддержка сайта гарантирует Вам приз №1, №2 и существенную добавку:',
            itemOne : "получить в награду на свой баланс на сайте от 10 до 200 коинов",
        },
        en: {
            title: 'A bug that can be used to level up the site/open achievements without completing them/receive bonuses against the rules',
            answer: 'For these bugs Site support guarantees you prizes No. 1, No. 2 and a significant supplement:',
            itemOne : "Get a reward to your balance on the site from 10 to 200 coins.",
        },
        de: {
            title: 'A bug that can be used to level up the site/open achievements without completing them/receive bonuses against the rules',
            answer: 'For these bugs Site support guarantees you prizes No. 1, No. 2 and a significant supplement:',
            itemOne : "Get a reward to your balance on the site from 10 to 200 coins.",
        },
        pt: {
            title: 'Um bug com o qual será possível aumentar o nível no site / Abrir realizações sem completá-las / Receber bônus fora das regras ',
            answer: 'Por encontrar esses bugs, o suporte do site garante Prêmio nº 1 e nº2  e soma significativa:',
            itemOne : "receber como Prêmio de 10 até 200 moedas para seu saldo",
        },
        es: {
            title: 'Bug con el que podrás aumentar tu nivel en el sitio / Abrir logros sin completarlos / Recibir bonificaciones fuera de las reglas',
            answer: 'Por estos bugs, el Soporte técnico te garantiza el premio № 1, № 2 y una bonificación sustancial:',
            itemOne : "Recibe de 10 a 200 monedas como recompensa a su saldo en el sitio",
        },
        open: false
    },
    {
        id: 4,
        ru: {
            title: 'Баги с подкруткой на режимах / Баги с накручиванием баланса / Баги с выводом скинов с сайта',
            answer: 'И, наконец, за обнаружение таких багов Ваши возможности на сайте будут почти безграничны! Вам полагается приз №1, №2, №3 и, внимание:.',
            itemOne : "в награду на свой баланс на сайте от 10 до 1000 коинов за честность. И мы Вам сделаем конкретное и выгодное предложение об официальной работе на сайте!",
            answerTwo: "Немедленно присоединяйтесь к числу наших друзей и партнеров, и Вы сразу почувствуете реальную выгоду от такого сотрудничества!"
        },
        en: {
            title: 'Mode balance change bugs / Bugs with balance wrapping / Bugs with the output of skins from the website',
            answer: 'Finally, for finding such bugs, your capabilities on the site will be almost unlimited! You are entitled to prizes No. 1, No. 2, No. 3 and:',
            itemOne : "As a reward, from 10 to 1000 coins will be added to your balance, and, you will receive a competitive and profitable offer for an official work on the site!",
            answerTwo: "Join the ranks of our friends and partners immediately and you will feel the real benefits of such cooperation!"
        },
        de: {
            title: 'Mode balance change bugs / Bugs with balance wrapping / Bugs with the output of skins from the website',
            answer: 'Finally, for finding such bugs, your capabilities on the site will be almost unlimited! You are entitled to prizes No. 1, No. 2, No. 3 and:',
            itemOne : "As a reward, from 10 to 1000 coins will be added to your balance, and, you will receive a competitive and profitable offer for an official work on the site!",
            answerTwo: "Join the ranks of our friends and partners immediately and you will feel the real benefits of such cooperation!"
        },
        pt: {
            title: 'Bugs com ajustes nos modos / Bugs com aumento de soma no saldo/ Bugs com retirada de skins do site ',
            answer: 'Finalmente, por encontrar esses bugs, suas oportunidades no site serão quase ilimitadas!  Você tem direito a receber Prêmio nº 1, nº 2, nº 3 e, atenção: ',
            itemOne : "como recompensa de 10 a 1000 moedas no seu saldo no site por honestidade.  Faremos uma oferta específica e lucrativa para trabalho oficial no site!  ",
            answerTwo: "Junte-se imediatamente às fileiras de nossos amigos e parceiros, e você sentirá imediatamente os reais benefícios de tal cooperação!"
        },
        es: {
            title: 'Bugs con el ajuste de los modos / Bugs con el ajuste del saldo / Bugs con el retiro de skins del sitio',
            answer: '¡Por último, por encontrar estos bugs tus posibilidades en el sitio serán casi infinitas! Tienes derecho al premio №1, №2, №3 y, atención:',
            itemOne : "Como recompensa te daremos de 10 a 1000 monedas a tu saldo en el sitio por tu honestidad. ¡Y te haremos una oferta específica y rentable para trabajar oficialmente en nuestro sitio!",
            answerTwo: "¡Únete de inmediato a las filas de nuestros amigos y socios, e inmediatamente veras los beneficios reales de esta cooperación!"
        },
        open: false
    },
    
]