import React from 'react';

import Gift from '../../../assets/bonuses/bonus-gift.svg'

import './ReferralBonus.scss'

const ReferralBonus = ({bonus}) => {

  if (bonus > 0) {
    return (
      <div className="bonus">
        <img src={Gift} className="bonus__icon" alt=""></img>
        <div className="bonus__text">You have {bonus}% bonus</div>
      </div>
    );
  } else {
    return ''
  }
};

export default ReferralBonus;