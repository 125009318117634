import React, { useState } from 'react';


import MinesItem from "./MinesItem";

import './MinesChoose.scss'
import {useDispatch} from "react-redux";
import {getMinesBattleCurrentRoundHandler} from "../../../redux/actions";

export default function MinesChoose({ games }) {
  const dispatch = useDispatch();

  const [sortDate, setSortDate] = useState(false);
  const [sortPrice, setSortPrice] = useState(false);
  const [sortDateCounter, setSortDateCounter] = useState(0);
  const [sortPriceCounter, setSortPriceCounter] = useState(0);

  function sortData(field) {
    if (field === "date") {
      setSortDate(true)
      setSortPrice(false)
      setSortPriceCounter(0);
      setSortDateCounter(sortDateCounter + 1);
      if (sortDateCounter === 2) {
        setSortDateCounter(1);

      }
      if (sortDateCounter === 1) {
        games = games?.sort(
          (a, b) => { return a?.m < b?.m ? 1 : -1 }
        )
      } else {
        games = games?.sort(
          (a, b) => { return a?.m > b?.m ? 1 : -1 }
        )
      }
    } else {
      setSortDate(false)
      setSortPrice(true)
      setSortDateCounter(0);
      setSortPriceCounter(sortPriceCounter + 1)
      if (sortPriceCounter === 2) {
        setSortPriceCounter(1);
      }
      if (sortPriceCounter === 1) {
        games = games?.sort(
          (a, b) => { return a?.p[0].b_a < b?.p[0].b_a ? 1 : -1 }
        )
      } else {
        games = games?.sort(
          (a, b) => { return a.p[0].b_a > b.p[0].b_a ? 1 : -1 }
        )
      }

    }
  }
  return (
    <div>
      <div className={'mines__sort ' + (sortDateCounter === 2 || sortPriceCounter === 2 ? "sorted" : '')}>
        <span>Sort By:</span>
        <span onClick={() => { sortData('date') }} className={sortDate ? 'active' : ''}>Date</span>
        <span onClick={() => { sortData('price') }} className={sortPrice ? 'active' : ''}>Price</span>
      </div>
      <div className='mines__cards'>
        {games.length === 0
          ?
          <div className="mines__no-games">
            <p>It seems no one has</p>
            <p>created the battle yet</p>
            <p>Be the first to create one! Click “Create battle” to start</p>
          </div>
          :
          games.map(game =>
            game.s
                ? ''
                : <MinesItem
                    firstPlayer={game?.p[0]}
                    secondPlayer={game?.p[1]}
                    betAmount={game?.p[0].b_a}
                    roundId={game?.r_i}
                    game={game}
                    />
          )
        }

      </div>
    </div>
  );
};