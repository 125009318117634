import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {UPDATE_SETTINGS} from "../../../redux/types";

import Desert from '../../../assets/deaddesert.png'
import Silent from '../../../assets/silent.png'
import Default from '../../../assets/default.png'
import Soon from '../../../assets/soon.png'

import './MinesMaps.scss'

const MinesMaps = () => {
  const settings = useSelector(state => state.mines.settings)
  const dispatch = useDispatch()

  return (
    <div className="mines__maps maps">
      <div className="maps__title">Background</div>
      <div className="maps__area">
        
        <button className={"maps__button " + (settings.f_t === 2 ? 'active' : '')} onClick={() => dispatch({type: UPDATE_SETTINGS, payload: { ...settings, f_t: 2 }})}>
          <div className="maps__block">
            <img src={Silent} alt="" className="maps__image" />
            <div className="maps__type">Silent nature</div>
          </div>
        </button>

        <button className={"maps__button " + (settings.f_t === 1 ? ' active' : '')} onClick={() => dispatch({type: UPDATE_SETTINGS, payload: { ...settings, f_t: 1 }})}>
          <div className="maps__block">
            <img src={Desert} alt="" className="maps__image" />
            <div className="maps__type">Dead desert</div>
          </div>
        </button>

        <button className={"maps__button " + (settings.f_t === 0 ? 'active' : '')} onClick={() => dispatch({type: UPDATE_SETTINGS, payload: { ...settings, f_t: 0 }})}>
          <div className="maps__block">
            
            <img src={Default} alt="" className="maps__image" />
            <div className="maps__type">Default field</div>
          </div>
        </button>

        <button className="maps__button soon">
          <div className="maps__block">
            <img src={Soon} alt="" className="maps__image" />
            <div className="maps__type">Coming soon</div>
          </div>
        </button>

        <button className="maps__button soon">
          <div className="maps__block">
            <img src={Soon} alt="" className="maps__image" />
            <div className="maps__type">Coming soon</div>
          </div>
        </button>
      </div>
    </div>
  )
}
export default MinesMaps;