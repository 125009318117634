import React from 'react';

import "./DepositDisclaimer.scss"

const DepositDisclaimer = () => {
  return (
    <div className="deposit__disclaimer disclaimer">
        <div className="disclaimer__alert">!</div>
        <div className="disclaimer__text">Please note that purchased coins are not subject to sale or exchange for real currency. If you have any problems during the deposit - contact our support team.</div>
    </div>
  );
};

export default DepositDisclaimer;