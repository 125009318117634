import React from 'react';

import './Rules.scss';
const Rules = () => {

  return (
    <div className="rules">
      <div className="rules__wrapper">
        <div className="rules__title">
          How does it work?
        </div>
        <div className="rules__text">
          <p>To collect Daily Bonus, you are required to meet requirements first. Once you meet the requirements, you can collect the bonus through the game.</p>
          <p>Note! Each card contains coins, the sum of collected coins from cards is your daily bonus. From day to day, you can collect different amounts of bonus, it depends on your luck.</p>
          <p>To start the game, please, follow this steps</p>
        </div>
        <div className="rules__list">
          <div className="rules__item"><span>1</span>Select the number of cards you want to open </div>
          <div className="rules__item"><span>2</span> Press the button «Play Now»</div>
          <div className="rules__item"><span>3</span> Open the cards and have a good luck!</div>
        </div>
        <div className="rules__feature">New feature on CSGO RACE!</div>
      </div>
      <div className="rules__decore"></div>
    </div>
  );
};

export default Rules;