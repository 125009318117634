import React from 'react'
import './ButtonYellow.scss'
import PropTypes from "prop-types";

export const ButtonYellow = ({children, onClick, className, href, disabled}) => {
    if(href) return <a href={href} className={'yellow-button ' + className} onClick={onClick}>{children}</a>

    return (
        <button className={'yellow-button ' + className} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    )
}

ButtonYellow.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    href: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

ButtonYellow.defaultProps = {
    className: '',
    href: '',
    disabled: false,
    onClick: undefined,
};

export default ButtonYellow;