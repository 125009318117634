import {
    ADD_ROULLETE_BET,
    ADD_ROULLETE_ROUND,
    CLEAR_ROULLETE_BETS_ALL,
    CLEAN_ROLLETE,
    GET_ROULLETE,
    GET_ROULLETE_PREVIOUS,
    MUTE_ROULLETE,
    ROULLETE_INTERVAL,
    ROULLETE_SECONDS,
    SET_ROULLETE_STAGE,
    UPDATE_ROULLETE_TIME, UPDATE_STREETRACE_TIME,
    WINNER_ROULLET
} from "./types"

const initialState = {
    roullete: null,
    stage: null,
    previous: [],
    seconds: 0,
    interval: null,
    bets: [],
    betsAll: [],
    speed: 0,
    winner: null,
    mute: false,
    spinningStage: null,
}

export const roulleteReducer = ( state = initialState, action ) => {
    switch(action.type){
        case GET_ROULLETE:
            return { 
                ...state, 
                roullete: action.payload.roullete, 
                stage: action.payload.roullete.stage,
                betsAll: action.payload.roullete.bets || [],
                winner: action.payload.roullete.color,
                start: action.payload.roullete.start,
                spinningStage: action.payload.roullete.spinningStage
            }
        case GET_ROULLETE_PREVIOUS:
            return { ...state, previous: action.payload, winner: state.winner || action.payload[0].color }
        case ROULLETE_INTERVAL:
            return { ...state, interval: action.payload }
        case ROULLETE_SECONDS:
            return { ...state, seconds: action.payload }
        case SET_ROULLETE_STAGE:
            return { ...state, stage: action.payload }
        case ADD_ROULLETE_ROUND:
            return {
                ...state,
                previous: [{ color: action.payload.c, moment: action.payload.m, number: action.payload.n, roundId: action.payload.r_i}].concat(state.previous), 
                roullete: { ...state.roullete, previousHash: action.payload.r_h } 
            }
        case ADD_ROULLETE_BET:
            return { ...state, betsAll: state.betsAll.concat(action.payload) }
        case CLEAR_ROULLETE_BETS_ALL:
            return { ...state, betsAll: [] }
        case WINNER_ROULLET:
            return { ...state, winner : action.payload }
        case CLEAN_ROLLETE:
            return {
                ...state,
                roullete: null,
                stage: null,
                previous: [],
                speed: 0,
                winner: null,
            }
        case UPDATE_ROULLETE_TIME:
            return { ...state, start: action.payload }
        case MUTE_ROULLETE: 
            return { ...state, mute : !state.mute }
        default:
            return state
    }
}