import React from 'react'

import {useSelector} from 'react-redux'
import Profile from '../../UI/Profile/Profile';

import './RouletteList.scss'
import Coin from "../../UI/Coin/Coin";

//eslint-disable-next-line
export default ({bets, type, activeRoulette}) => {

  const user = useSelector(state => state.app.user)
  const stage = useSelector(state => state.roullete.stage)
  const previous = useSelector(state => state.roullete.previous.slice(0, 10))
  const roullete = useSelector(state => state.roullete.roullete)
  const streamer = useSelector(state => state.app.streamer)

  return (
    <ul className={'mobile-list ' + (activeRoulette ? 'active' : '')}>
      {bets.map((item, index) => <li key={index} className={"bet-item " + (user?.steamId === item.s_i ? 'mobile-is-me' : '')}>
        <Profile item={item}/>
        <span>{streamer && user?.steamId !== item.s_i ? '*'.repeat(item.u.length) : item.u}</span>
        <div className="bet-value">
          <Coin className={(stage === 0 ? (previous[0].color === type ? 'green' : 'red' ) : '')}/>
          <div className={"bet " + (stage === 0 ? (previous[0].color === type ? 'green' : 'red' ) : '')}>
            {stage === 0 ? (previous[0].color === type ? '+'
              + (item.b_a * (type === 'Green' ? 14 : 2) > roullete.maxPayout
                ? roullete.maxPayout : item.b_a * (type === 'Green' ? 14 : 2)).toFixed(2)
              : '-' + item.b_a) : item.b_a}
          </div>
        </div>
      </li>)}
    </ul>
  )
}