import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { roulleteBetHandler } from '../../../redux/actions'
import { useTranslation } from 'react-i18next'

import './RouletteBets.scss'
import Coin from "../../UI/Coin/Coin";

//eslint-disable-next-line
export default ({ bets, className = '', type }) => {

    const { t } = useTranslation(['roullete'])

    const user = useSelector(state => state.app.user)
    const amount = useSelector(state => state.amount.amount)
    const maxAmount = useSelector(state => state.amount.maxAmount)
    const stage = useSelector(state => state.roullete.stage)
    const previous = useSelector(state => state.roullete.previous.slice(0, 10))
    const winner = useSelector(state => state.roullete.winner)

    const dispatch = useDispatch()

    return (
        <div className={"roulette-column " + className}>
            <div className="roulette-column-x">{type === 'Green' ? '14' : '2'}x</div>
            <div className={"roulette-column-bankroll " + (stage === 0 ? (previous[0]?.color === type ? 'green' : 'red' ) : '')}>
                <Coin/>
                <span>{stage === 0 ? (previous[0]?.color === type ? '+'
                    + (bets.reduce((counter, value) => counter + value.b_a, 0)).toFixed(2) : '-'
                    + (bets.reduce((counter, value) => counter + value.b_a, 0)).toFixed(2))
                    : (bets.reduce((counter, value) => counter + value.b_a, 0)).toFixed(2)}
                </span>
            </div>
            <button 
                className={"roulette-column-btn " + (winner === type && stage === 0 ? 'winner' : '')}
                disabled={!(+amount <= maxAmount) || stage === 2 || !user}
                onClick={() => +amount <= maxAmount ? dispatch(roulleteBetHandler(type)) : false}>
                {t('roullete:placeBet')}
            </button>
        </div>
    )
}