import {
    CHANGE_DEPOSIT_AMOUNT,
    CHANGE_DEPOSIT_METHOD, GET_ADDRESS, GET_CURRENCIES,
    GET_DEPOSIT,
    REFERRAL_CODE,
    SKINSBACK_LINK,
    WAXPEER_LINK
} from "./types"

const initialState = {
    refferal_bonus: 0,
    waxpeer_link: null,
    skinsback_link: null,
    address: null,
    currencies: {},
    method: null,
    minSumm: null,
    amount: '',
    coinbaseId: ''
}

export const depositReducer = (state = initialState, action) => {
    switch(action.type){
        case REFERRAL_CODE:
            return { ...state, refferal_bonus: action.payload }
        case WAXPEER_LINK:
            return { ...state, waxpeer_link: action.payload }
        case SKINSBACK_LINK:
            return { ...state, skinsback_link: action.payload }
        case GET_CURRENCIES:
            return { ...state, currencies: action.payload }
        case GET_ADDRESS:
            return { ...state, address: action.payload }
        case CHANGE_DEPOSIT_METHOD:
            return { ...state, method: action.payload.method === state.method ? null : action.payload.method, minSumm: action.payload.method === state.method ? null : action.payload.minSumm }
        case CHANGE_DEPOSIT_AMOUNT:
            let value = action.payload
            if(isNaN(value)){
                value = ''
            }
            if( value > 5000 ){
                value = 5000
            }
            return {
                ...state,
                amount: value
            }
        case GET_DEPOSIT:
            return { ...state, coinbaseId: action.payload.coinbaseId }
        default:
            return state
    }
}