import React from 'react';

import './GameOverDaily.scss'
import Coin from "../../../components/UI/Coin/Coin";

const GameOverDaily = ({dailyTotal}) => {
  return (
    <div className='daily-bonus-result'>
      <div className='daily-bonus-result__wrapper'>
        <div className='daily-bonus-result__card'>
          <p className='daily-bonus-result__title'>Congratulations!</p>
          <p className='daily-bonus-result__text'>The prize will be automatically credited to your account</p>
          <div className="daily-bonus-result__bet">
            <Coin/>
            <span>{dailyTotal}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameOverDaily;