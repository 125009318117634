import React from 'react';

import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import Popup from "../../../../components/Popups/PopupInfo/Popup";

import {Link} from "react-router-dom";

import './Achievements.scss';

const Achievements = ({title, text, crown, className}) => {
  const profileLink = useSelector(state => state.app.user.steamId);
  const {t} = useTranslation(['bonuses']);

  return (
    <Link to={"/profile/" + profileLink} className={'bonuses__card card bonuses__card-' + className}>
      <div className="card__title">
        <h4>{title}</h4>
        <Popup className="card__popup" popupText={t('bonuses:popupText')}/>
      </div>
      <div className="card__subtitle">
        <div className="card__info">{text}</div>
        <div className="card__amount">
          <div>
            <span>{crown}</span>
            /8
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Achievements;