import React, {useEffect, useRef, useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {changeLoader} from '../../../redux/actions'

import Track from '../../../assets/track.svg'
import TrackNight from '../../../assets/track-night.svg'
import {ReactComponent as Racer1} from '../../../assets/cars/racer1.svg'
import {ReactComponent as Racer2} from '../../../assets/cars/racer2.svg'
import {ReactComponent as Racer3} from '../../../assets/cars/racer3.svg'
import {ReactComponent as Racer4} from '../../../assets/cars/racer4.svg'

import './StreetraceAnimate.scss'

export default function StreetraceAnimated() {

    const [audioStart] = useState(new Audio('./audio/Streetrace_Start.mp3'));
    const [audioEnd] = useState(new Audio('./audio/Streetrace_Finish.mp3'));

    const track = useRef()

    const stage = useSelector(state => state.streetrace.stage)
    const start = useSelector(state => state.streetrace.start)
    const spinningStage = useSelector(state => state.streetrace.streetrace?.spinningStage)
    const bettingStage = useSelector(state => state.streetrace.streetrace?.bettingStage)
    const winner = useSelector(state => state.streetrace.winner)
    const mute = useSelector(state => state.streetrace.mute)
    const _timeout = useRef(0)
    const [isNight, setIsNight] = useState(false)
    const [carBetween, setCarBetween] = useState([new Date(), [0, 0, 0, 0]]);
    const [timeDiff, setTimeDiff] = useState(new Date() - new Date(start))
    const dispatch = useDispatch()

    useEffect(() => {
        audioStart.pause()
        audioEnd.pause()
        audioStart.currentTime = 0.0;
        audioEnd.currentTime = 0.0;
        setIsNight(new Date().getHours() >= 18 || new Date().getHours() < 9)
        return () => {
            audioStart.pause()
            audioEnd.pause()
            audioStart.remove()
            audioEnd.remove()
            clearTimeout(_timeout.current)
        }
    }, [])


    useEffect(() => {
        audioStart.muted = mute
        audioEnd.muted = mute
    }, [mute])

    useEffect(() => {
        audioStart.volume = 0.25
        audioEnd.volume = 0.25

        const currentTimeDiff = new Date() - new Date(start);
        if (stage === 2) {
            const _timeDiff = currentTimeDiff > spinningStage || currentTimeDiff < 0 ? 0 : currentTimeDiff;
            const timeToFinish = spinningStage - 2750

            audioStart.pause();
            audioStart.currentTime = 0.0;
            audioEnd.currentTime = _timeDiff <= timeToFinish ? 0 : (_timeDiff - timeToFinish) / 1000;
            _timeout.current = setTimeout(function audioEndPlay() {
                audioEnd.play().catch(() => false)
            }, timeToFinish - _timeDiff)
        } else if (stage === 1) {
            const _timeDiff = currentTimeDiff > bettingStage || currentTimeDiff < 0 ? 0 : currentTimeDiff;
            const timeToStart = bettingStage - 3000
            audioEnd.pause();
            audioEnd.currentTime = 0.0;
            audioStart.currentTime = _timeDiff <= timeToStart ? 0 : (_timeDiff - timeToStart) / 1000;
            _timeout.current = setTimeout(() => {
                audioStart.play().catch(() => false)
            }, timeToStart - _timeDiff)
        }
    }, [stage])

    const tick = () => {
        setTimeDiff(new Date() - new Date(start))
    };

    const getRandomNumber = () => {
        return (Math.random() * 70) - 20;
    }

    const setRandomBetween = () => {
        setCarBetween([new Date(), [getRandomNumber(), getRandomNumber(), getRandomNumber(), getRandomNumber()]]);
    }

    useEffect(() => {
        const timerID1 = setInterval(() => tick(), 100);
        return () => {
            clearInterval(timerID1)
        };
    })

    function calcTrack() {
        if (stage === 2) {
            return Math.max(Math.min((timeDiff > spinningStage ? 0 : timeDiff) / (spinningStage - 2000) * 100, 85), 0)
        }

        return 0;
    }

    function calcRacer(carId) {
        if (stage === 2 && timeDiff < spinningStage && timeDiff > 0) {
            if (new Date() - carBetween[0] > 500) {
                setRandomBetween();
            }

            const winCar = carId === winner && timeDiff > 4500 ? -20 : 0;
            return (timeDiff > 4500 ? -200 : -50)
              + (timeDiff > 4500 ? winCar || 30 : carBetween[1][carId])
              + (timeDiff > 4500 ? Math.random() * 30 - 1 : Math.random() * 4 - 2)
        }

        return -3;
    }

    return (
        <div className="streetrace-track">
            <div className="test"></div>
            <div className="track-wrapper">
                <img className='track' style={{transform: `translateY(${calcTrack()}%)`}} src={isNight ? TrackNight : Track} onLoad={() => dispatch(changeLoader(true))} alt="track" ref={track} />
                <div className="cars">
                    <Racer1 style={{
                        transform: `translateY(${stage === 0 ? 20 : calcRacer(0)}%)`,
                        opacity: stage === 0 ? 0 : 100,
                        transition: stage === 0 ? '' : 'all 1.5s linear'
                    }} />
                    <Racer2 style={{
                        transform: `translateY(${stage === 0 ? 20 : calcRacer(1)}%)`,
                        opacity: stage === 0 ? 0 : 100,
                        transition: stage === 0 ? '' : 'all 1.5s linear'
                    }} />
                    <Racer3 style={{
                        transform: `translateY(${stage === 0 ? 20 : calcRacer(3)}%)`,
                        opacity: stage === 0 ? 0 : 100,
                        transition: stage === 0 ? '' : 'all 1.5s linear'
                    }} />
                    <Racer4 style={{
                        transform: `translateY(${stage === 0 ? 20 : calcRacer(2)}%)`,
                        opacity: stage === 0 ? 0 : 100,
                        transition: stage === 0 ? '' : 'all 1.5s linear'
                    }} />
                </div>
            </div>
        </div>
    )
}