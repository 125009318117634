import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux";
import {createMines, getMinesBattleHandler} from "../redux/actions";

import { Link, useHistory } from 'react-router-dom'

import Back from '../assets/arrow-bug.svg'

import GameNav from '../components/Sections/GameNav/GameNav'
import GameHeader from '../components/Games/GameHeader/GameHeader'
import Button from '../components/Games/Mines/MinesButton'
import Setting from '../components/Games/Mines/MinesDetails'
import MinesRules from '../components/Games/Mines/MinesRules'
import Probability from '../components/Games/Mines/MinesProbability'
import Maps from '../components/Games/Mines/MinesMaps'

import './MinesCreate.scss'

const MinesCreate = () => {
  const { t } = useTranslation(['main'])
  const dispatch = useDispatch();
  const history = useHistory();
  const mines = useSelector(state => state.mines.mines);
  const games = useSelector(state => state.mines.games);
  const user = useSelector(state => state.app.user);
  const settings = useSelector(state => state.mines.settings);
  const game = games.find(game => game?.p[0].s_i === user?.steamId);

  function checkSettings() {
    return (settings?.b_a >= mines?.minBet && settings?.b_a <= mines?.maxBet) && settings?.f_t !== null && settings?.s !== null && settings?.f_s !== null && settings?.a !== null
  }

  function createMinesGame() {
    dispatch(createMines({ ...settings }))
  }

  useEffect(() => {
    dispatch(getMinesBattleHandler());
  }, [])

  useEffect(() => {
    if (game) {
      history.push("/mines");
    }
  }, [games])

  return (
    <div className="mines">
      <GameNav />
      <div className="mines__header">
        <div className="mines__nav">
          <Link className="mines__back" to="/mines"><img src={Back} alt="" /></Link>
          <GameHeader title='Mines battle' popupText={'Mines battle is an online game where 2 players are given a field with cards, and each player, in turn, opens the cards with coins inside. The player who has collected the most coins from all cards wins, and the winner takes the entire bank.'} cut={true} />
        </div>
        {
          checkSettings()
            ?
            <div className="mines__next" onClick={createMinesGame}>
              <Button className="mines__button" />
            </div>
            :
            <div className="mines__next">
              <Button className="mines__button" isActive={false}/>
            </div>
        }
      </div>
      <div className="mines__body">
        <Setting />
        <Probability />
        <Maps />
        <MinesRules />
      </div>
    </div>
  )
}

export default MinesCreate