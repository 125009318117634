import React from 'react';

import {useDispatch} from "react-redux";

import {updateBalance} from "../../../redux/actions";

import {DAILY_BONUS} from "../../../redux/types";

import './GameCards.scss'

const GameCards = ({dailyBonusResult, id, gameState, dailyTotal = 0, setDailyTotal = () => false}) => {
  const dispatch = useDispatch();

  function getResult() {
    const gameCard = document.getElementById(`game__card-${id}`);

    if(gameState && !gameCard.classList.contains('game__card-active') && dailyBonusResult?.length) {
      const res = dailyBonusResult[0];
      const newBonus = []

      for(let i = 0; i < dailyBonusResult.length; i++) {
        if(i === 0) continue;
        newBonus.push(dailyBonusResult[i]);
      }
      dispatch({ type: DAILY_BONUS, payload: newBonus});

      if(res) {
        const cardFace = document.querySelector(`#game__card-${id} .game__card-face`);
        cardFace.classList.add('game__card-face-prize');
        cardFace.innerHTML = `<span>${res}</span>`;
        dispatch(updateBalance(+res));
        setDailyTotal((+dailyTotal + +res).toFixed(2));
      } else {
        document.querySelector(`#game__card-${id} .game__card-face`).classList.add('game__card-face-fail');
      }
      gameCard.classList.add('game__card-active');

    }
  }


  return (
    <div className="game__card" id={`game__card-${id}`} onClick={getResult}>
      <div className="game__card-face"></div>
      <div className="game__card-shirt"></div>
    </div>
  );
};

export default GameCards;