import React, {useEffect, useState} from 'react';

import {useDispatch, useSelector} from "react-redux";
import {dailyBonusHandler, setGameState} from "../../../redux/actions";

import MoneyBlock from "../../../components/UI/MoneyBlock/MoneyBlock";
import GameCards from "./GameCards";
import Lock from "../../../components/UI/Lock/Lock";
import ChooseAttemptsBlock from "./ChooseAttemptsBlock";
import AttemptsLeftBlock from "./AttemptsLeftBlock";

import locker from "../../../assets/locker.svg"

import GameOverDaily from "../GameOverDaily/GameOverDaily";
import './Game.scss';

const Game = ({maxAttempts, lastOpening}) => {
  const dispatch = useDispatch();
  const isPlay = useSelector(state => state.bonuses.daily_game);
  const dailyBonusResult = useSelector(state => state.bonuses.daily_bonus);
  const user = useSelector(state => state.app.user)
  const dayMilliseconds = 86400000;

  const [attemptsAmount, setAttemptsAmount] = useState(1);
  const [over, setOver] = useState(false);
  const [[h, m, s], setTime] = useState([null, null, null]);
  const [dailyTotal, setDailyTotal] = useState((0).toFixed(2))
  const [isGameOver, setIsGameOver] = useState(false)

  const windowWidth = window.innerWidth;
  let fieldSize = 25;

  const tick = () => {
    if (over || h === null || m === null || s === null) return;

    if (h === 0 && m === 0 && s === 0) {
      setOver(true);
    } else if (+m === 0 && +s === 0) {
      setTime([ getNumberFormat(+h - 1), 59, 59]);
    } else if (+s === 0) {
      setTime([getNumberFormat(+h), getNumberFormat(+m - 1), 59]);
    } else {
      setTime([getNumberFormat(+h), getNumberFormat(+m), getNumberFormat(s - 1)]);
    }
  };

  function getNumberFormat(num) {

    if(num < 10) {
      return `0${num}`
    }
    else {
      return num
    }
  }

  function getButtons(max) {
    const res = [];
    for(let i = 1; i <= max; i+=2) {
      res.push(
        <button className={"game__button" + (i === attemptsAmount ? " game__button--active" : "")}
                onClick={() => setAttemptsAmount(i)}
                key={i} id={i}>×{i}</button>
      )
    }
    return res;
  }

  function createGameField(amountEl) {
    const res = [];
    for (let i = 0; i < amountEl; i++) {
      res.push(
        <GameCards dailyBonusResult={dailyBonusResult} id={i} key={i} gameState={isPlay} dailyTotal={dailyTotal} setDailyTotal={setDailyTotal} />
      )
    }
    return res;
  }

  function startGame() {
    dispatch(dailyBonusHandler({attempts: attemptsAmount}));
    dispatch(setGameState(true));
  }

  function setTimerFunc() {
    const nowDate = new Date();
    let timeleft = dayMilliseconds - (nowDate - (lastOpening ? new Date(lastOpening) : 0));
    if (timeleft <= 0) {
      setOver(true);
    } else {
      timeleft = Math.floor(timeleft / 1000);
      const hours = Math.floor(timeleft / 60 / 60);
      const minutes = Math.floor(timeleft / 60) - (hours * 60);
      const seconds = timeleft % 60;
      setOver(false)
      setTime([ hours, minutes, seconds ])
    }
  }

  useEffect(() => {
    setTimerFunc()
  }, [lastOpening])

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  useEffect(() => {
    if (isPlay && dailyBonusResult?.length === 0) {
      setIsGameOver(true)
    }
    return () => {
      setIsGameOver(false)
    }
  }, [isPlay, dailyBonusResult])

  useEffect(() => {
    dispatch(setGameState(false));
    setDailyTotal((0).toFixed(2));
  }, [])

  useEffect(() => {
    if (windowWidth <= 1440) {
      fieldSize = 25;
    } else {
      fieldSize = 36;
    }
  }, [windowWidth])
  return (
    <div className="game">
      <div className="game__header">
        <div className="game__title">Try your luck!</div>
        {!isPlay
          ?
          <ChooseAttemptsBlock getButtons={() => {return getButtons(maxAttempts)}} />
          :
          <AttemptsLeftBlock attempts={attemptsAmount} dailyBonusResult={dailyBonusResult} />
        }
        <MoneyBlock money={dailyTotal} icon={true}/>
      </div>
      <div className="game__field">
        { isGameOver ? <GameOverDaily dailyTotal={dailyTotal} /> : '' }
        {
          !isPlay
            ? !user?.conditionsMet
              ?
              <Lock icon={locker} text={"Please, met the requirments \nto collect the bonus "} whiteColor={false}/>
              : (over
                ?
                <Lock btnText={'Play now'} btnDisabled={!attemptsAmount} whiteColor={false} onClick={startGame}/>
                :
                <Lock text={"Daily bonus will be opened in:"} btnText={h + ':' + m + ':' + s} btnDisabled={false}
                      whiteColor={false}/>)
            : ''
        }
        {createGameField(36)}
      </div>
    </div>
  );
};

export default Game;