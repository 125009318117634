import React, { useEffect, useState } from 'react'

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useParams } from 'react-router';

import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getProfileHandler, getProfileReferalHandler, editCodeHandler, getRedeemHandler } from "../redux/actions";

import { ReactComponent as Copy } from '../assets/copy-ref.svg'

import './Refferals.scss'
import Coin from "../components/UI/Coin/Coin";

//eslint-disable-next-line
export default ({ streamer = false }) => {

    const { t } = useTranslation(['refferals'])

    const dispatch = useDispatch()
    const user = useSelector(state => state.app.user)
    const [promo, setPromo] = useState('')

    const { id } = useParams()
    const referal = useSelector(state => state.profile.referal)

    console.log(referal)

    useEffect(() => {
        if(referal){
            setPromo((referal.profit.code))
        }
    }, [referal])

    useEffect(() => {
        if(!streamer){
            dispatch(getProfileHandler(id))
        }
        dispatch(getProfileReferalHandler())
        // eslint-disable-next-line
    }, [])

    if(!referal) return false

    return (
        <div className="refferals">
            {streamer ? <div className="refferals-level-header">{t('refferals:analytics')}</div> : ''}
            {!streamer ? <div className="refferals-level">
                {user?.permissions.status !== 1 ? <div className="refferals-level-header">{t('refferals:ref')} </div> : '' }
                <div className="refferals-level-content">
                    <div className="level-data">
                        <div className="number-wrapper">
                            <div className="number">{
                                referal.profit.minimumLevel
                                > Array.from(referal.profit.level).findIndex(item => referal.profit.totalDeposit < item)
                                  ? referal.profit.minimumLevel : Array.from(referal.profit.level).findIndex(item => referal.profit.totalDeposit < item) }</div>
                        </div>
                        <div className="info">
                            <div className="info-title">{t('refferals:level')}</div>
                            <div className="info-fuel">
                                <Coin/>
                                <span>{referal.profit.totalDeposit}/{ referal.profit.minimumLevel
                                > Array.from(referal.profit.level).findIndex(item => referal.profit.totalDeposit < item)
                                  ? Array.from(referal.profit.level)[referal.profit.minimumLevel - 1]
                                  : Array.from(referal.profit.level).findIndex(item => referal.profit.totalDeposit < item)
                                }</span>
                            </div>
                            <div className="info-line"><span style={{width: (referal.profit.totalDeposit / (Array.from(referal.profit.level).find(item => referal.profit.totalDeposit < item) / 100)) + '%'}}></span></div>
                        </div>
                    </div>
                    <div className="balance">
                        <div className="balance-data">
                            <span>{t('refferals:balance')}</span>
                            <div className="fuel">
                                <span>{referal.profit.available}</span>
                                <Coin/>
                            </div>
                        </div>
                        <button className="balance-with" onClick={() => dispatch(getRedeemHandler())}>{t('refferals:me')}</button>
                    </div>
                </div>
            </div> : '' }
            <div className="refferals-info">
                <div className="refferals-links">
                    <div className="title">Info</div>
                    <div className="input-wrapper">
                        <span>{t('refferals:refLink')}</span>
                        <div className="input-content">
                            <input type="text" disabled value={ promo ? 'https://csgorace.com/referrals/' + (promo).toUpperCase() : "https://csgorace.com" } />
                            <CopyToClipboard text={ promo ? 'https://csgorace.com/referrals/' + (promo).toUpperCase() : "https://csgorace.com" }><Copy/></CopyToClipboard>
                        </div>
                    </div>
                    <div className="input-wrapper">
                        <span>{t('refferals:promo')}</span>
                        <div className="input-content">
                            <input type="text"  value={promo} onChange={event => setPromo(event.target.value)} />
                            <CopyToClipboard text={promo} ><Copy/></CopyToClipboard>
                            <button onClick={() => dispatch(editCodeHandler(promo))}>{t('refferals:save')}</button>
                        </div>
                    </div>
                </div>
                <div className="refferals-statistic">
                    <div className="title">Statistics</div>
                    <div className="statistic-items">
                        <div className="statistic-item">
                            <span>{t('refferals:bonus')}</span>
                            <div className="fuel">
                                <Coin/>
                                <span>{referal.profit.minimumLevel
                                > Array.from(referal.profit.level).findIndex(item => referal.profit.totalDeposit < item)
                                  ? referal.profit.minimumLevel : Array.from(referal.profit.level).findIndex(item => referal.profit.totalDeposit < item)}%</span>
                            </div>
                        </div>
                        <div className="statistic-item">
                            <span>{t('refferals:invite')}</span>
                            <div className="fuel">
                                <span>{referal.profit.count}</span>
                            </div>
                        </div>
                        <div className="statistic-item">
                            <span>{t('refferals:deposit')}</span>
                            <div className="fuel">
                                <Coin/>
                                <span>{referal.profit.totalDeposit}</span>
                            </div>
                        </div>
                        <div className="statistic-item">
                            <span>{t('refferals:allWith')}</span>
                            <div className="fuel">
                                <Coin/>
                                <span>{referal.profit.redeemedProfit}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="refferals-statistic">
                <div className="title">Affiliate system</div>
                <div className="statistics-levels-lists">
                    <div className="statistic-levels">
                        <div className="statistic-levels-header">
                            <span>{t('refferals:lvl')}</span><span>{t('refferals:deposits')}</span>
                            <span>{t('refferals:yourBonus')}</span><span>{t('refferals:refBonus')}</span>
                        </div>
                        <div className="statistic-levels-list">
                            { referal.profit.level ? referal.profit.level.slice(0, 5).map((item, index) => {
                                return (
                                  <div className="level-item" key={index}>
                                      <div className={"level-wrapper " + (index + 1 == referal.profit.minimumLevel ||
                                        (index + 1 >= referal.profit.minimumLevel && referal.profit.totalDeposit >= item
                                      && referal.profit.totalDeposit < (referal.profit.level.slice(0, 5)[index + 1]
                                        || referal.profit.level.slice(0, 5).slice(-1))) ? 'active' : '')}>
                                          <div className="level-number">{index + 1}</div>
                                      </div>
                                      <div className="deposit-value"><Coin/><span>{item}</span></div>
                                      <div className="bonus-value">{index + 1}%</div>
                                      <div className="ref-bonus-value">{ +(referal.profit.referralBonus * 100)
                                        .toFixed(0) }% {t('refferals:toDeposit')}</div>
                                  </div>
                                )
                            }) : '' }
                        </div>
                    </div>
                    <div className="statistic-levels">
                        <div className="statistic-levels-header">
                            <span>{t('refferals:lvl')}</span><span>{t('refferals:deposits')}</span>
                            <span>{t('refferals:yourBonus')}</span><span>{t('refferals:refBonus')}</span>
                        </div>
                        <div className="statistic-levels-list">
                            { referal.profit.level ? referal.profit.level.slice(5, 10).map((item, index) => {
                                return (
                                  <div className="level-item" key={index}>
                                      <div className={"level-wrapper " + (index + 6 == referal.profit.minimumLevel ||
                                        (index + 6 >= referal.profit.minimumLevel &&
                                        referal.profit.totalDeposit >= item
                                      && referal.profit.totalDeposit < (referal.profit.level.slice(5, 10)[index + 1]
                                        || Infinity)) ? 'active' : '')}>
                                          <div className="level-number">{index + 6}</div>
                                      </div>
                                      <div className="deposit-value"><Coin/><span>{item}</span></div>
                                      <div className="bonus-value">{index + 6}%</div>
                                      <div className="ref-bonus-value">{ +(referal.profit.referralBonus * 100)
                                        .toFixed(0) }% {t('refferals:toDeposit')}</div>
                                  </div>
                                )
                            }) : '' }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}