import React from 'react';

const ChooseAttemptsBlock = ({getButtons}) => {

  return (
    <div className="game__choose">
      <div className="game__label">Choose the number of attempts:</div>
      <div className="game__attempts">
        {getButtons().map(item => item)}
      </div>
    </div>
  );
};

export default ChooseAttemptsBlock;