import React from 'react';

import { useDispatch } from "react-redux";

import {getMinesBattleCurrentRoundHandler, joinMines} from "../../../redux/actions";

import MinesPlayer from "./MinesPlayer";
import MinesRounds from "./MinesRounds"
import MinesJoinButton from "./MinesJoinButton";

import './MinesItem.scss'

export default function MinesItem({firstPlayer, secondPlayer, betAmount, roundId, game}) {
  const dispatch = useDispatch();

  // const game = useSelector(state => state.mines.games).find(game => game.r_i === roundId);
  const currentRound = game?.m_s ? Math.floor(game?.m_s / 2) + 1 : '';
  // 3 : 5 : 100 (inf)

  function acceptGame() {
    dispatch(joinMines(roundId, betAmount))
  }

  function viewGame() {
    if (game && game.p[0] && game.p[1]) {
      dispatch(getMinesBattleCurrentRoundHandler(game.r_i))
    }
  }

  return (
    //'mines__item ' + 'waiting' + 'first-win' + 'second-win'
    <div className={'mines__item waiting'} onClick={game && game.p[0] && game.p[1] ? viewGame : null}>
        <MinesPlayer betAmount={betAmount} player={firstPlayer}/>
        {
          secondPlayer
            ?
            <MinesPlayer betAmount={betAmount} player={secondPlayer} className="two"/>
            :
            <MinesJoinButton onClick={acceptGame} bet={betAmount}/>
        }
        <MinesRounds rounds={game?.a} currentRound={currentRound} map={game?.f_t}/>
    </div>
  );
};