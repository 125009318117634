// import React from "react";
import React, {useRef} from 'react'

import {useDispatch, useSelector} from "react-redux";

import {
  activityRewardHandler,
  banUserHandler,
  changeMuteHours,
  muteUserHandler,
  sendDeleteMessage,
  showError,
} from '../../../redux/actions';

import {useTranslation} from 'react-i18next'
import {ReactComponent as Ban} from '../../../assets/admin-menu/ban.svg'
import {ReactComponent as Mute} from '../../../assets/admin-menu/mute.svg'
import {ReactComponent as Delete} from '../../../assets/admin-menu/delete.svg'
import {ReactComponent as Steam} from '../../../assets/admin-menu/steam.svg'

import Fin from '../../../assets/modal/fin.svg'
import Fdep from '../../../assets/modal/fdep.svg'
import Bets from '../../../assets/modal/bets.svg'
import X20 from '../../../assets/modal/x20.svg'
import Tdep from '../../../assets/modal/tdep.svg'
import Ref from '../../../assets/modal/ref.svg'
import Top3 from '../../../assets/modal/top3.svg'

import Chat from '../../../assets/modal/chat.svg'
import "./Modal.scss";
import {ReactComponent as Check} from "../../../assets/check.svg";
import Coin from "../Coin/Coin";


const Modal = ({messageId, active, profile, x, y, setModalActive}) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.app.user)
  const muteData = useSelector(state => state.popup.muteData)

  const {t} = useTranslation(['modal'])
  const modal = useRef();
  let top = null;
  let arrowTop = 45;
  if(modal.current) {
    if(y + modal.current.clientHeight > window.innerHeight) {
      top = window.innerHeight - modal.current.clientHeight;
      arrowTop = arrowTop + y - top
    } else {
      top = y;
    }
  }

  const percent = (profile?.level === 100
    ? +(8000 * Math.pow(1.11, profile?.level)).toFixed()
    : +(profile?.xp - (8000 * (Math.pow(1.11, profile?.level) - 1) / (1.11 - 1))).toFixed() /
    +(8000 * Math.pow(1.11, profile?.level)).toFixed()) * 100

  const dateFix = (reg) => {
    if (reg !== undefined) {
      return reg.split('T')[0].replace(/-/g, '.').split(".").reverse().join(".");
    } else {
      return null;
    }
  }

  function getColor(level) {
    return (level < 10 ? '#A3A5B7' : level < 20 ? '#93C7F4' : level < 40 ? '#E8E13F' : level < 60 ? '#E8903F'
      : level < 80 ? '#BC93FE' : level < 90 ? '#E8533F' : level <= 100 ? '#FD6228  ' : '')
  }


  return (
    <div className={active ? "modal active" : "modal"} ref={modal} style={{top: (top || 0) + 'px', left: ((x - modal.current?.clientWidth) || 0) + 'px'}}
         onMouseLeave={() => {
           setModalActive(false)
       }}
    >
      <div className="modal__arrow" style={{top: arrowTop}} />
      <div className="modal__content" onClick={e => {
        e.stopPropagation();
      }}>
        <div
          className={"modal__header " + (profile?.level < 10 ? 'grey' : profile?.level < 20 ? 'blue' : profile?.level < 40 ? 'yellow' : profile?.level < 60 ? 'orange' : profile?.level < 80 ? 'violet' : profile?.level < 90 ? 'red' : profile?.level <= 100 ? 'fire' : '')}>
          <div className="modal__avatar">
            <img className="modal__img " src={profile?.profileAvatar} alt="avatar"/>
          </div>
          <div className="modal__inf">
            <div className="modal__nickname">{profile?.nickname}</div>
            <div className="modal__exp-block">
              <div className="modal__lvl">{profile?.level}</div>
              <div className="modal__exp">
                <div className="modal_color-block">
                  <div className="line-wrapper">
                    <div className="line"
                         style={{width: profile?.level === 100 ? '100%' : (profile?.xp - (8000 * (Math.pow(1.11, profile?.level) - 1) / (1.11 - 1))) / (8000 * Math.pow(1.11, profile?.level) / 100) + "%"}}>
                    </div>
                    <div
                      className="line-xp-value"
                      style={{ background: `linear-gradient(90deg,#141720 ${percent}%,${getColor(profile?.level)} ${percent}%)` }}
                    >{new Intl.NumberFormat('ru-RU').format(profile?.level === 100 ? +(8000 * Math.pow(1.11, profile?.level)).toFixed() : +(profile?.xp - (8000 * (Math.pow(1.11, profile?.level) - 1) / (1.11 - 1))).toFixed())}/{new Intl.NumberFormat('ru-RU').format(+(8000 * Math.pow(1.11, profile?.level)).toFixed())}</div>
                  </div>
                </div>
                <div className="line-xp-text">XP</div>
              </div>
            </div>

          </div>
        </div>


        <div className="modal__achievement">
          <div className="modal__achievement-line">
            <div className={"modal__achievement-item-wrapper " + (profile?.achievements?.fbets > 1 ? 'blue' : 'grey')}>
              <div className={"modal__achievement-item "}>
                <img className="modal__achievement-img" src={Fdep} alt=""/>
              </div>
            </div>
            <div className={"modal__achievement-item-wrapper " + (profile?.achievements?.ref > 1 ? 'blue' : 'grey')}>
              <div className={"modal__achievement-item "}>
                <img className="modal__achievement-img" src={Ref} alt=""/>
              </div>
            </div>
            <div className={"modal__achievement-item-wrapper " + (profile?.achievements?.x20 > 1 ? 'blue' : 'grey')}>
              <div className={"modal__achievement-item "}>
                <img className="modal__achievement-img" src={X20} alt=""/>
              </div>
            </div>
            <div className={"modal__achievement-item-wrapper " + (profile?.achievements?.tdep > 1 ? 'blue' : 'grey')}>
              <div className={"modal__achievement-item "}>
                <img className="modal__achievement-img" src={Tdep} alt=""/>
              </div>
            </div>
            <div className={"modal__achievement-item-wrapper " + (profile?.achievements?.bets > 1 ? 'blue' : 'grey')}>
              <div className={"modal__achievement-item "}>
                <img className="modal__achievement-img" src={Bets} alt=""/>
              </div>
            </div>
            <div className={"modal__achievement-item-wrapper " + (profile?.achievements?.top3 > 1 ? 'blue' : 'grey')}>
              <div className={"modal__achievement-item "}>
                <img className="modal__achievement-img" src={Top3} alt=""/>
              </div>
            </div>
            <div className={"modal__achievement-item-wrapper " + (profile?.achievements?.chat > 1 ? 'blue' : 'grey')}>
              <div className={"modal__achievement-item "}>
                <img className="modal__achievement-img" src={Chat} alt=""/>
              </div>
            </div>
            <div className={"modal__achievement-item-wrapper " + (profile?.achievements?.fin > 1 ? 'blue' : 'grey')}>
              <div className={"modal__achievement-item "}>
                <img className="modal__achievement-img" src={Fin} alt=""/>
              </div>
            </div>
          </div>
        </div>

        <div className="modal__stats-title">Statistics</div>
        <div className="modal__stats">
          <div className="modal__stats-wrapper">
            <div className="modal__stats-item">
              <div className="modal__stats-item-text">{t('modal:totalWin')}</div>
              <div className="modal__stats-item-value"><Coin/>{profile?.totalWin}</div>
            </div>
            <div className="modal__stats-item">
              <div className="modal__stats-item-text">Winrate:</div>
              <div
                className="modal__stats-item-value">{((profile?.totalWin / profile?.totalBet * 100) === Infinity || isNaN(profile?.totalWin / profile?.totalBet * 100) ? 0 : (profile?.totalWin / profile?.totalBet * 100)).toFixed(2).substring(0, 5)}</div>
            </div>
            <div className="modal__stats-item">
              <div className="modal__stats-item-text">{t('modal:rateSite')}</div>
              <div className="modal__stats-item-value">{profile?.ranking}</div>
            </div>
            <div className="modal__stats-item">
              <div className="modal__stats-item-text">{t('modal:totalWithdraw')}</div>
              <div className="modal__stats-item-value"><Coin/>{profile?.totalWithdraw}</div>
            </div>
            <div className="modal__stats-item">
              <div className="modal__stats-item-text">{t('modal:connect')}</div>
              <div className="modal__stats-item-value"> {dateFix(profile?.createdAt)}</div>
            </div>
          </div>
        </div>

        {user?.permissions.status >= 3
          ?
          <div className="modal__admin-menu admin-menu">
            <div className="admin-menu__rights">
              <div className="admin-menu__item-mute">
                <div className="admin-menu__item">
                  <span>Mute user</span>
                </div>
                <div className="admin-menu__item hours">
                  <input type="text" value={muteData.hours} onChange={event => dispatch(changeMuteHours(event.target.value))} />
                    <div className="admin-menu__check" onClick={event => {
                      event.preventDefault()
                      if (+muteData.hours === 0) {
                        dispatch(showError({text: 'Enter hours', translate: false}))
                      } else {
                        dispatch(muteUserHandler({mute: muteData.mute, hours: +muteData.hours}))
                      }
                    }}>
                      {+muteData.hours === 0 ? '' : <Check />}
                    </div>
                  <div className="admin-menu__icon mute">
                    <Mute/>
                  </div>
                </div>
              </div>
              <div className="admin-menu__item" onClick={() => {
                dispatch(sendDeleteMessage(messageId))
                // dispatch(showError({text: 'Message deleted', translate: false, success: true}))
              }
              }>
                <div className="admin-menu__icon delete">
                  <Delete/>
                </div>
                <span>Delete</span>
              </div>
              <div className="admin-menu__item" onClick={() => {
                navigator.clipboard.writeText(profile?.steamId);
                // dispatch(showError({text: `Steam ID copied: ${profile?.steamId}`, translate: false, success: true}))
              }}>
                <div className="admin-menu__icon steam-id">
                  <Steam/>
                </div>
                <span>Copy id</span>
              </div>
              <div className="admin-menu__item ban" onClick={() => dispatch(banUserHandler(messageId))}>
                <div className="admin-menu__icon ban">
                  <Ban/>
                </div>
                <span>Ban</span>
              </div>
            </div>
            <button className="admin-menu__reward" onClick={() => {dispatch(activityRewardHandler(profile?.steamId))}}>
              Reward for activity
            </button>
          </div>
          :
          ''
        }
      </div>
    </div>
  );
};

export default Modal;

