import React from "react";

import Profile from '../../UI/Profile/Profile';

import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {createMines, getMinesBattleHandler} from '../../../redux/actions'

import './GameOverMines.scss'
import {CLEAR_CURRENT_GAME, REMOVE_MINES} from "../../../redux/types";
import Coin from "../../UI/Coin/Coin";

export default function GameOver({currentGame, firstPlayer, secondPlayer, bet, winner}) {
  const { t } = useTranslation('race')

  const dispatch = useDispatch()
  const user = useSelector(state => state.app.user)
  const gameOver = useSelector(state => state.mines.game_over)
  const settings = useSelector(state => state.mines.settings);


  function createSameGame() {
    dispatch({type: CLEAR_CURRENT_GAME})
    dispatch({type: REMOVE_MINES, payload: currentGame?.r_i})
    dispatch(createMines(settings));
  }

  function returnToList() {
    dispatch({type: REMOVE_MINES, payload: currentGame?.r_i})
    dispatch({type: CLEAR_CURRENT_GAME})
    dispatch(getMinesBattleHandler())
  }

  return (
    <div className='mines-game-over__wrapper'>
      <div className='mines-game-over'>
        <div className='mines-game-over__card'>
          <p className='mines-game-over__text'>{t('race:gameOver.winner')}</p>
          <div className='mines-game-over__winner'>
            <Profile className='mines-game-over__profile' item={gameOver?.w === user?.steamId
              ? (user?.steamId === firstPlayer?.s_i ? firstPlayer : secondPlayer)
              : (gameOver?.w === firstPlayer?.s_i ? firstPlayer : secondPlayer)}/>
            <p className='mines-game-over__nickname'>{gameOver?.w === user?.steamId
              ? (user?.steamId === firstPlayer?.s_i ? firstPlayer?.u : secondPlayer?.u)
              : (gameOver?.w === firstPlayer?.s_i ? firstPlayer?.u : secondPlayer?.u)}</p>
          </div>
          <div className="mines-game-over__bet">
            <Coin/>
            <span>{ +(gameOver?.w_a).toFixed(2) }</span>
          </div>
        </div>
        { user?.steamId !== firstPlayer?.s_i && user?.steamId !== secondPlayer?.s_i ? '' :
          <button className='mines-game-over__create' disabled={!user} onClick={createSameGame}>
            <span>{t('race:gameOver.create')}</span>
          </button>
        }
        <button className='mines-game-over__back' onClick={returnToList}>
          <span>{t('race:gameOver.getBack')}</span>
        </button>
      </div>
    </div>
  );
}