import React from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {streetraceBetHandler} from '../../../redux/actions'
import {useTranslation} from 'react-i18next'

import './StreetraceBets.scss'
import Coin from "../../UI/Coin/Coin";

//eslint-disable-next-line
export default ({bets, className = '', type}) => {

    const {t} = useTranslation(['streetrace'])

    const user = useSelector(state => state.app.user)

    const amount = useSelector(state => state.amount.amount)
    const maxAmount = useSelector(state => state.amount.maxAmount)
    const stage = useSelector(state => state.streetrace.stage)
    const previous = useSelector(state => state.streetrace.previous.slice(0, 10))
    const winner = useSelector(state => state.streetrace.winner)

    const dispatch = useDispatch()

    return (
        <div className={"streetrace-column " + className}>
            <div className="streetrace-column-x">3.75x</div>
            <div
                className={"streetrace-column-bankroll " + (stage === 0 ? (previous[0]?.car === type ? 'green' : 'red') : '')}>
                <Coin/>
                <span>{stage === 0 ? (previous[0]?.car === type
                    ? '+' + (bets.reduce((counter, value) => counter + value.b_a, 0)).toFixed(2)
                    : '-' + (bets.reduce((counter, value) => counter + value.b_a, 0)).toFixed(2))
                    : (bets.reduce((counter, value) => counter + value.b_a, 0)).toFixed(2)}</span>
            </div>
            <button
                className={"streetrace-column-btn " + (winner === type ? 'winner' : '')}
                disabled={!(+amount <= maxAmount) || stage === 2 || !user}
                onClick={() => +amount <= maxAmount ? dispatch(streetraceBetHandler(type)) : false}>
                {t('streetrace:placeBet')}
            </button>
        </div>
    )
}