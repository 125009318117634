import React, {useEffect, useState} from 'react'

import {useTranslation} from 'react-i18next'

import {useDispatch, useSelector} from 'react-redux'
import {showTicketCreatePopup} from '../redux/actions'
import {bugData} from '../utilites/bug'

import ButtonYellow from '../components/UI/ButtonYellow/ButtonYellow'

import {ReactComponent as Ticket} from '../assets/ticket.svg'
import {ReactComponent as Arrow} from '../assets/arrow-right.svg'
import {Link} from 'react-router-dom'

import Back from '../assets/arrow-bug.svg'
import CoinImg from '../assets/coin.png'

import './Foundbug.scss'

export default () => {

  const {t} = useTranslation(['tickets'])
  const dispatch = useDispatch()
  const activeCount = useSelector(state => state.ticket.tickets.filter(item => item.status === 0).length)
  const resolvedCount = useSelector(state => state.ticket.tickets.filter(item => item.status === 1).length)
  const user = useSelector(state => state.app.user)

  const [questions] = useState(bugData)

  const defaultLanguage = 'en';

  useEffect(() => {
    const answer3 = document.querySelector('.item__answer-2')
    const answer4 = document.querySelector('.item__answer-3')

    const arrayAnswer3 = answer3.innerHTML.split(t('tickets:coins'));
    const arrayAnswer4 = answer3.innerHTML.split(t('tickets:coins'));

    answer3.innerHTML = `${arrayAnswer3[0]}<img src=${CoinImg} width="13" height="13" alt=""/>${arrayAnswer3[1]}`;
    answer4.innerHTML = `${arrayAnswer4[0]}<img src=${CoinImg} width="13" height="13" alt=""/>${arrayAnswer4[1]}`;
  }, [])

  return (
    <div className="foundbug">
      <Link className="foundbug__back" to="/faq"><img src={Back} alt=""/></Link>
      <h1 className="foundbug__header">Found a bug?</h1>
      <div className="foundbug__under-header">{t('tickets:foundBug')}</div>
      <div className="foundbug__body">
        <div className="foundbug__body-content">
          {questions.map((item, i) =>
            <div className="foundbug__item item" key={item.id}>
              <div className="item__header">
                <div className="item__number">{item.id}</div>
                <div className="item__title">
                  {item[user?.language || defaultLanguage]?.title}
                </div>
              </div>
              <div className="item__body">
                <div className="item__answer">
                  {item[user?.language || defaultLanguage]?.answer}
                </div>
                <div className="item__container">
                  <div className={(item[user?.language || defaultLanguage]?.itemOne) ? 'item__point' : 'nope'}>
                    <div className={"item__answer " + `item__answer-${i}`}>
                      {item[user?.language || defaultLanguage]?.itemOne}
                    </div>
                  </div>
                  <div className={(item[user?.language || defaultLanguage]?.itemTwo) ? 'item__point' : 'nope'}>
                    <div className="item__answer-under">
                      {item[user?.language || defaultLanguage]?.itemTwo}
                    </div>
                  </div>
                </div>
                <div className={(item[user?.language || defaultLanguage]?.answerTwo) ? 'item__answ-two' : 'nope'}>
                  <div className="item__answer">
                    {item[user?.language || defaultLanguage]?.answerTwo}
                  </div>
                </div>
              </div>
            </div>)}
        </div>
        <div className="foundbug__body-content-nav">

          {user ? <div className="support-body-nav">
            <Link to="/tickets/all"><Ticket/>{t('tickets:yourTickets')}<Arrow/></Link>
            <ul className="support-body-nav-list">
              <li className="support-body-nav-list-item">
                <Link to="/tickets/active">
                  <span>{t('tickets:activeTickets')}</span>
                  <span className="count count-active">{activeCount}</span>
                </Link>
              </li>
              <li className="support-body-nav-list-item">
                <Link to="/tickets/resolved">
                  <span>{t('tickets:resolvedTickets')}</span>
                  <span className="count">{resolvedCount}</span>
                </Link>
              </li>
            </ul>
            <ButtonYellow className={'create-ticket'}
                          onClick={() => dispatch(showTicketCreatePopup())}>{t('tickets:createTicket')}</ButtonYellow>
          </div> : ''}
        </div>
      </div>
    </div>
  )
}