import React from 'react';

import { Link } from "react-router-dom";

import './BackArrow.scss'

const BackArrow = ({size = 24, arrowWidth = 8, arrowHeight = 2, link}) => {
  return (
    <Link
      to={link}
      className="arrow" style={{width: `${size}px`, height: `${size}px`}}>
      <div className="arrow__bg">
        <div className="arrow__top" style={{width: `${arrowWidth}px`, height: `${arrowHeight}px`}}></div>
        <div className="arrow__bottom" style={{width: `${arrowWidth}px`, height: `${arrowHeight}px`}}></div>
      </div>
    </Link>
  );
};

export default BackArrow;