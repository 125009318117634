import React, {useEffect} from 'react';

import DepositCryptoCard from "./Cards/DepositCryptoCard";

import {ReactComponent as Arrow} from "../../assets/deposit/arrow.svg";
import {ReactComponent as Tether} from "../../assets/deposit/Crypto/tether.svg";
import {ReactComponent as Tron} from "../../assets/deposit/Crypto/tron.svg";
import {ReactComponent as USDC} from "../../assets/deposit/Crypto/usdc.svg";
import {ReactComponent as Ethereum} from "../../assets/deposit/Crypto/etherium.svg";
import {ReactComponent as Litecoin} from "../../assets/deposit/Crypto/litecoin.svg";
import {ReactComponent as Bitcoin} from "../../assets/deposit/Crypto/bitcoin.svg";
import {ReactComponent as Binance} from "../../assets/deposit/Crypto/binance.svg";
import {ReactComponent as DAI} from "../../assets/deposit/Crypto/dai.svg";

import "./DepositCrypto.scss"
import {useDispatch, useSelector} from "react-redux";
import {getDepositCurrenciesHandler} from "../../redux/actions";

const DepositCrypto = () => {
  const dispatch = useDispatch();
  const currencies = useSelector(state => state.deposit.currencies);

  const cryptoCard = {
    BTC: {
      color: 'orange',
      logo: <Bitcoin />
    },
    LTC: {
      color: 'blue',
      logo: <Litecoin />
    },
    BNB: {
      color: 'yellow',
      logo: <Binance />
    },
    ETH: {
      color: 'purple',
      logo: <Ethereum />
    },
    TRX: {
      color: 'red',
      logo: <Tron />
    },
    DAI: {
      color: 'orange',
      logo: <DAI />
    },
    USDT: {
      color: 'green',
      logo: <Tether />
    },
    UDSC: {
      color: 'blue',
      logo: <USDC />
    }
  }

  useEffect(() => {
    dispatch(getDepositCurrenciesHandler())
  }, [])

  return (
    <div className="deposit__crypto crypto">
      <p className="crypto__title">Crypto</p>
      <div className="crypto__cards">
        {currencies?.currencies?.map(crypto =>
          <DepositCryptoCard
            key={crypto.name}
            crypto={crypto}
            logo={cryptoCard[crypto.short].logo}
            qr={currencies.QRCode}
            classForHover={cryptoCard[crypto.short].color}
          />
        )}
      </div>
      <div className="crypto__buy">
        <p>Need to buy cryptocurrency?</p>
        <p>We got you! Here is a list of services, where you can buy cryptocurrencies safely </p>
        <div className="crypto__list">
          <a target="_blank" rel="noreferrer" href="https://www.moonpay.com/buy">Moonpay <Arrow className="crypto__arrow" /></a>
          <a target="_blank" rel="noreferrer" href="https://www.binance.com/en-IN/buy-sell-crypto">Binance <Arrow className="crypto__arrow" /></a>
          <a target="_blank" rel="noreferrer" href="https://www.coinbase.com/buy-bitcoin">Coinbase <Arrow className="crypto__arrow" /></a>
        </div>
      </div>
    </div>
  );
};

export default DepositCrypto;