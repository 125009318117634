import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from "react-redux";
import {getMinesBattleHandler} from "../redux/actions";
import {Link, useHistory} from 'react-router-dom'
import { ReactComponent as Lock } from "../assets/mines/green-lock.svg";
import { useTranslation } from 'react-i18next'


import GameNav from "../components/Sections/GameNav/GameNav";
import GameHeader from "../components/Games/GameHeader/GameHeader";
import MinesChoose from "../components/Games/Mines/MinesChoose";
import Button from '../components/Games/Mines/MinesButton'
import MinesGame from "../components/Games/Mines/MinesGame";
import MinesPopupPrivate from "../components/Popups/MinesPrivate/MinesPopupPrivate";
import {MINES_ON, REMOVE_MINES} from "../redux/types";

import './Mines.scss'

const Mines = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.app.user);
  const games = useSelector(state => state.mines.games);
  const currentGameState = useSelector(state => state.mines.current_game);
  const currentGame = games.find(game => user?.steamId === game?.p[0]?.s_i || user?.steamId === game?.p[1]?.s_i);

  const [openPopup, setOpenPopup] = useState(false)
  const { t } = useTranslation(['mines'])

  useEffect(() => {
    dispatch({type: MINES_ON, payload: {isMainPage: true, minesOn: true}})
    dispatch(getMinesBattleHandler())

    return () => {
      dispatch({type: MINES_ON, payload: {isMainPage: true, minesOn: false}})
    }
  }, [])

  useEffect(() => {
    if (currentGame || Object.keys(currentGameState).length) {
      history.push('/mines/' + (currentGame?.r_i || currentGameState?.r_i))
    }
  }, [currentGame, currentGameState])

  return (
    <div className="mines">
        <GameNav />
        <div className="mines__header">
          <GameHeader title={'Mines battle'}
                      popupText={'Mines battle is an online game where 2 players are given a field with cards, and each player, in turn, opens the cards with coins inside. The player who has collected the most coins from all cards wins, and the winner takes the entire bank.'}
                      cut={true} />
          {user && <div className="mines__buttons">
            <button className="mines__private" onClick={() => {
              setOpenPopup(true)
            }}>
              <Lock/>
              <span>Join the private battle</span>
            </button>
            <button className="mines__creategame">
              <Link to="/mines/create_game">
                <Button/>
              </Link>
            </button>
          </div>}
        </div>
        <MinesChoose games={games} />
        {openPopup ? <MinesPopupPrivate setOpen={setOpenPopup} games={games} /> : ''}
    </div>
  )
}
export default Mines;