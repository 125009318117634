import React from 'react'

import {ReactComponent as Info} from "../../../assets/info.svg";

import "./Popup.scss";

const Popup = ({popupText = ''}) => {
  return <div className="information">
    <Info className="information__svg"/>
    <div className="information__popup">
      <div className="popup__text">{popupText}</div>
    </div>
  </div>
}
export default Popup;