import React, {useEffect, useState} from 'react';

import {ReactComponent as Close} from "../../../assets/close.svg";
import {ReactComponent as Equal} from "../../../assets/deposit/eq.svg";
import {ReactComponent as CopyButton} from "../../../assets/deposit/copy-icon.svg";
import {ReactComponent as Successful} from "../../../assets/deposit/succes-icon.svg";

import Back from '../../../assets/arrow-bug.svg'

import {useDispatch, useSelector} from "react-redux";
import {getDepositAddressHandler} from "../../../redux/actions";

import "./DepositCryptoPopup.scss"
import Coin from "../../UI/Coin/Coin";

const DepositCryptoPopup = ({setOpen, name, logo, className, info, qr, minDeposit, fee, short, wallet, rate}) => {
  const dispatch = useDispatch();
  const address = useSelector(state => state.deposit.address);

  const [cryptoValue, setCryptoValue] = useState('')
  const [coinValue, setCoinValue] = useState('')
  const [successful, setSuccessful] = useState(false)

  function convertCryptoCoin(ev) {
    const newValue = (ev.target.value).replaceAll(/[^.\d]/g, '')
    setCryptoValue(newValue);
    setCoinValue((+(newValue) * rate).toFixed(2))
  }
  function convertCoinCrypto(ev) {
    const newValue = (ev.target.value).replaceAll(/[^.\d]/g, '')
    setCoinValue(newValue);
    setCryptoValue((+(newValue) / rate).toFixed(2));
  }

  useEffect(() => {
    if(wallet) {
      dispatch(getDepositAddressHandler(wallet))
    }
  }, [wallet])

  return (
    <div className="deposit-crypto" >
      <div className="deposit-crypto__background" onClick={() => {setOpen(false)}}/>
      <div className="deposit-crypto__popup">
        <Close className="deposit-crypto__close" onClick={() => {setOpen(false)}}/>
        <div className="deposit-crypto__section">
          <div className="deposit-crypto__mobile-header">
            <img src={Back} alt="<" onClick={() => {setOpen(false)}}/>
            <span>{name} deposit</span>
          </div>
          <div className={"deposit-crypto__title " + (className || '')}>
            <div className="deposit-crypto__logo">{logo}</div>
            {name}
            {
              info && <div className={"deposit-crypto__info " + (className || '')}>
                {info}
              </div>
            }
          </div>
          <div className="deposit-crypto__make">
            <div className="deposit-crypto__inner">
              <p>Copy the code or scan the QR to make a deposit</p>
              <p>You`ll receive balance after sending BTC to the address displayed below </p>
              <div className="deposit-crypto__input">
                <input className="deposit-crypto__copy" value={address || ''} type="text"/>
                {
                  !successful
                  ?
                  <CopyButton className="deposit-crypto__copy-button" onClick={() => {
                  navigator.clipboard.writeText(address);
                  setSuccessful(true);
                }}/>
                    :
                  <Successful className="deposit-crypto__copy-button" />
                }
              </div>
              <div>
                <span>Min deposit: ${minDeposit}</span>
                <span>Deposit fee: {((1 - fee) * 100).toFixed(0)}%</span>
              </div>
            </div>
            <div className="deposit-crypto__qr">
              <img src={qr+address} alt=""/>
            </div>
          </div>
          <div className="deposit-crypto__note note">
            <div className="note__alert">!</div>
            <span className="note__title">Note</span>
            <p>1. Depositing less than the minimum amount of <span>{minDeposit}$</span> will result in a loss of sent value</p>
            <p>2. Please double check the address before sending your crypto</p>
          </div>
        </div>
        <div className="deposit-crypto__convert">
          <div className="deposit-crypto__text">Currency converter</div>
          <div className="deposit-crypto__conversion">
            <div className="deposit-crypto__currency">
              {short}
              <input className="deposit-crypto__currency-fuel" maxLength="10" type="text" value={cryptoValue} onChange={convertCryptoCoin}/>
            </div>
            <div className="deposit-crypto__equal">
              <Equal />
            </div>
            <div className="deposit-crypto__currency fuel">
              <Coin/>
              <input className="deposit-crypto__currency-fuel" type="text" maxLength="10" value={coinValue} onChange={convertCoinCrypto} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositCryptoPopup;