import React, {useEffect, useRef, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'

import {
  getProfileHandler,
} from '../redux/actions'

import {ReactComponent as Fire1} from '../assets/fire1.svg'
import {ReactComponent as Fire2} from '../assets/fire2.svg'

import './Message.scss'

import {GET_CHAT_PROFILE} from '../redux/types'

const Message = ({setMessageId, message, setCoord, setModalActive, onClick = () => false}) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.app.user)
  const streamer = useSelector(state => state.app.streamer)
  const menu = useRef();
  const [animate, setAnimate] = useState(false)
  const [open, setOpen] = useState(false)
  const history = useHistory()
  const [profiles, setProfiles] = useState({});
  let profile = useSelector(state => state.profile.chat_profile)

  const topOp = localStorage.getItem('hidePay');

  const clickHandler = event => {
    return menu.current?.contains(event.target) || setOpen(false)
  }

  useEffect(() => {
    if (animate) {
      setTimeout(() => {
        setAnimate(false)
      }, 1000)
    }
    //eslint-disable-next-line
  }, [animate])

  useEffect(() => {
    document.addEventListener('click', clickHandler)
    return () => {
      document.removeEventListener('click', clickHandler)
    }
  }, []);

  const getProfileByID = (id) => {
    if (profiles[id]) {
      dispatch({type: GET_CHAT_PROFILE, payload: {...profiles[id]}})
    } else {
      dispatch(getProfileHandler(id, true))
    }
  }
  useEffect(() => {
    if (!profiles[profile?.steamId]) {
      let tmpProfiles = {...profiles};
      tmpProfiles[profile?.steamId] = profile;
      setProfiles(tmpProfiles)
    }
  }, [profile])

  return (
    <div
      className={"message " + (animate ? 'animate ' : '') + (open ? 'active ' : '') + ((user && message.s_i === user?.steamId) ? 'is-me ' : '') + (message.s > 2 ? 'gold ' : '') + (message.s === 2 ? 'youtube ' : '') + (streamer && message.s_i !== user?.steamId ? 'streamer' : '')}
      onClick={() => {
        onClick()
        setAnimate(true)
      }}
      >
      <div className="message-image" onMouseEnter={(ev) => {
        getProfileByID(message.s_i);
        const msg = ev.target.closest('.message');
        if(msg) {
          const el = msg.getBoundingClientRect();
          setCoord([el.x, el.y - 45])
          setModalActive(true);
          setMessageId(message.i)
        }
      }}
      >
        <Link to={'/profile/' + message.s_i}
              className={"message-profile-link " + (message.l < 10 ? 'grey' : message.l < 20 ? 'blue' : message.l < 40 ? 'yellow' : message.l < 60 ? 'orange' : message.l < 80 ? 'violet' : message.l < 90 ? 'red' : message.l <= 100 ? 'fire' : '')}>
          <img src={message.a} alt="avatar"/>
        </Link>
      </div>
      <div className="message-data">
        <div className={"message-data-username"} id={message.i} ref={menu}>
          {user && message.s_i === user?.steamId ? (message.s >= 2 ? <span
              className={message.s === 2 ? 'message-data-youtube' : 'message-data-admin'}>{message.s === 2 ? 'YouTube' : message.s === 3 ? 'Moderator' : (message.s > 4 && message.s) < 8 ? 'Support' : message.s === 8 ? 'Staff' : message.s === 9 ? 'Admin' : message.s === 10 ? 'Owner' : ''}</span> :
            <span
              className={"message-data-level " + (message.l < 10 ? 'grey' : message.l < 20 ? 'blue' : message.l < 40 ? 'yellow' : message.l < 60 ? 'orange' : message.l < 80 ? 'violet' : message.l < 90 ? 'red' : message.l <= 100 ? 'fire' : '')}>{message.l >= 90 ? <>
              <Fire1/><Fire2/></> : ''} <span>{message.l}</span> </span>) : ''}
          <div className={"name-wrapper " + (message.s >= 2 ? 'cut' : "")} onClick={event => {
            return user?.permissions.status < 2 ? history.push('/profile/' + message.s_i) : (message.i === +menu.current?.id ? setOpen(prev => !prev) : setOpen(false))
          }}>
            {message.n}
          </div>
          {user && message.s_i !== user?.steamId ? (message.s >= 2 ? <span
              className={message.s === 2 ? 'message-data-youtube' : 'message-data-admin'}>{message.s === 2 ? 'YouTube' : message.s === 3 ? 'Moderator' : (message.s > 4 && message.s) < 8 ? 'Support' : message.s === 8 ? 'Staff' : message.s === 9 ? 'Admin' : message.s === 10 ? 'Owner' : ''}</span> :
            <span
              className={"message-data-level " + (message.l < 10 ? 'grey' : message.l < 20 ? 'blue' : message.l < 40 ? 'yellow' : message.l < 60 ? 'orange' : message.l < 80 ? 'violet' : message.l < 90 ? 'red' : message.l <= 100 ? 'fire' : '')}>{message.l >= 90 ? <>
              <Fire1/><Fire2/></> : ''}<span>{message.l}</span></span>) : ''}
        </div>
        <div className="message-data-text" onMouseOver={() => {setModalActive(false)}}>
          {message.t}
        </div>
      </div>
    </div>
  )
}
export default Message;