import React, { useEffect } from 'react';

import { useDispatch, useSelector } from "react-redux";

import { Link } from 'react-router-dom'

import Back from '../../assets/arrow-bug.svg'

import { changeLoader, getHangarsHandler } from "../../redux/actions";

import GameHeader from '../../components/Games/GameHeader/GameHeader'
import Requirements from "./Requirements/Requirements";
import Game from "./Game/Game";
import Depend from "./Depend/Depend";
import Rules from "./Rules/Rules";

import './DailyBonusPage.scss';

const DailyBonusPage = () => {
  const dispatch = useDispatch();
  const lastOpening = useSelector(state => state.hangars.lastOpening);
  const allDailyBonuses = useSelector(state => state.hangars.hangars);
  const userLevel = useSelector(state => state.app.user.level);

  const activeDailyBonus = findBonus(userLevel, allDailyBonuses);

  function findBonus(level, bonusesArray) {
    for (let i = 0; i < bonusesArray.length + 1; i++) {
      if (level >= (bonusesArray[i - 1]?.level || 0) && level < (bonusesArray[i]?.level || 101)) {
        return bonusesArray[i - 1];
      }
    }
    return null;
  }

  useEffect(() => {
    dispatch(changeLoader(true))
    dispatch(getHangarsHandler());
  }, [])

  // useEffect(() => {
  //   const popupText = document.querySelector('.popup__text')

  //   const arrayPopupText = popupText.innerHTML.split('$');
  //   popupText.innerHTML = `${arrayPopupText[0]}&nbsp;<img src=${CoinImg} width="13" height="13" alt=""/>&nbsp;${arrayPopupText[1]}&nbsp;<img src=${CoinImg} width="13" height="13" alt=""/>&nbsp;${arrayPopupText[2]}`;
  // }, [])

  return (
    <div className="daily">
      <div className="daily__header">
        <Link className="daily__back" to="/free"><img src={Back} alt="" /></Link>
        <GameHeader title='Daily bonus' popupText={'Before the game starts, you should select the number of cards you want to open. There are two types of cards you can get: \n \n 1. Card with winnings. Your winning can be 0$ - 100$ from one card. \n \n 2. Card “FAIL” means that you lost the game, you lose your bonus balance and your game ends for today.'} cut={true} />
      </div>
      <div className="daily__content">
        <Requirements />
        <Game maxAttempts={activeDailyBonus?.maxAttempts} lastOpening={lastOpening} />
        <Depend allDailyBonuses={allDailyBonuses} activeDailyBonus={activeDailyBonus} />
        <Rules />
      </div>
    </div>
  );
};

export default DailyBonusPage;