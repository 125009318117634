import React from 'react';

import MinesGrid from "./MinesGrid"

import './MinesField.scss'

const MinesField = ({firstPlayer, secondPlayer, fieldType, fieldSize, fields, roundId, currentStage}) => {
  const mapMines = fieldType === 0 ? 'default' : (fieldType === 1 ? 'desert' : 'forest');

  const mines = fieldSize ** 2;
  // 16 : 25 : 36 (36 by default)
  if(!fields) return '';
  return (
    <div className={'mines__field ' + mapMines}>
      <MinesGrid mines={mines} turn={currentStage === 1} player={firstPlayer} roundId={roundId} field={fields[0]}  className={"first-player " + (currentStage === 1 ? " " : "none")} />
      <MinesGrid mines={mines} turn={currentStage === 2} player={secondPlayer} roundId={roundId} field={fields[1]} bot={currentStage === 0} className={"second-player " + (currentStage === 1 ? "none" : " ")}
      />
      <div className="mines__decor">
        <div className="mines__bubble blue"></div>
        <div className="mines__bubble yellow"></div>
        <div className="mines__bubble green"></div>
      </div>
      {/*<MinesGrid mines={mines} player={"me"} disabled={false}/>*/}
      {/*<MinesGrid mines={mines} player={"enemy"} className={"with-bot"} disabled={false}/>*/}
    </div>
  );
};
export default MinesField;