import React, {useState} from 'react';

import {ReactComponent as Arrow} from '../../../assets/bonuses/toggle-arrow.svg';

import './Level.scss';

const Level = ({bonus, level}) => {
  const activeBonus = findBonus(level, bonus);
  const levels = reformatLevels(bonus);
  const [openList, setOpenList] = useState(false);

  function findBonus(level, array) {
    for (let i = 0; i < array.length; i++) {
      if (level >= (array[i-1]?.level || 0) && level < array[i].level) {
        return array[i];
      }
    }
    return null;
  }

  function reformatLevels(arr) {
    const res = [];
    for (let i = 0; i < arr.length + 1; i++) {
      res.push({
        level: arr[i]?.level,
        name: `${arr[i - 1]?.level || 0}-${arr[i]?.level || 100}`,
        bonus: ((arr[i - 1]?.bonus || 0)*100).toFixed(0),
        isActive: arr[i]?.level === activeBonus?.level
      });
    }
    return res;
  }

  return (
    <div className="rakeback__depend depend">
      <div className="depend__title">Rakeback depends on your level
        <div onClick={() => {setOpenList(!openList)}} className={"depend__toggle " + (openList ? "active" : '')}>
          <Arrow />
        </div>
      </div>
      <ul className={"depend__list " + (openList ? "active" : '')}>
        {levels.map(level =>
          <li className="depend__point" key={level.level}>
            <div className={"depend__num level-" + level.level}>LVL {level.name}</div>
            <div className="depend__line"></div>
            <div className={"depend__percent " + (level.isActive ? "depend__percent-active" : "")}>{level.bonus} %</div>
          </li>)}
      </ul>
    </div>
  );
};

export default Level;