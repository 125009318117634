import React from 'react';

import {Link} from "react-router-dom";

import Lock from "../../../../components/UI/Lock/Lock";

import locker from "../../../../assets/locker.svg"

import './DailyBonus.scss';

const DailyBonus = ({title, text, className, access}) => {

  return (
    <Link to={access ? "/free/daily" : "/free"} className={'bonuses__card card bonuses__card-' + className + (access ? " card-hover" : "")}>

      { access ? '' : <Lock icon={locker} text={"Reach 5 level to open"} whiteColor={true}/> }
      <h4 className="card__title">{title}</h4>
      <div className="card__text">{text}</div>
    </Link>
  );
};

export default DailyBonus;