import React, { useState, useEffect } from 'react'

import { Link, useLocation } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux' 

import { useTranslation } from 'react-i18next'

import { changeLang, changeNav, hideMobileMenu } from '../../../redux/actions'

import Popup from '../Popup/Popup'
import Account from '../../Account'

import { ReactComponent as Twitter } from '../../../assets/social/tw.svg'
import { ReactComponent as Instagram } from '../../../assets/social/in.svg'
import { ReactComponent as Telegram } from '../../../assets/social/tg.svg'
import { ReactComponent as Arrow } from '../../../assets/arrow-down.svg'

import './MobileMenu.scss'
//eslint-disable-next-line
export default () => {

    const { i18n, t } = useTranslation(['main'])
    

    const mobileMenu = useSelector(state => state.popup.mobileMenu)
    const user = useSelector(state => state.app.user)
    const nav = useSelector(state => state.nav.nav)
    const showTot = useSelector(state => state.app.server?.tot)

    const location = useLocation()
    const dispatch = useDispatch()

    const [lang, setLang] = useState(false)

    useEffect(() => {
        dispatch(changeNav())
    }, [location])

    return (
        <Popup popup={mobileMenu} handler={hideMobileMenu} className={'mobile-menu'} mobile={true}>
            <div className="mobile-menu-menu">
                <Account mobile={true}/>
                <ul className="mobile-menu-menu-list">
                    {nav.map((item, index) => (
                      (item.refferal && !user) || (item.logout && !user) || (item.bonus && !user) || (item.tot && !showTot) ? ''
                        : <li onClick={() => dispatch(hideMobileMenu())} className={"mobile-menu-menu-list-item " + (item.active ? 'active' : '')} key={index}>
                        {
                          !item.logout
                            ? <Link to={!item.refferal ? (item.link || '/') : '/profile/' + user?.steamId + item.link}> {item.icon}
                              <span>{t('main:header.' + item.translate)}</span></Link>
                            : <a href={item.link}> {item.icon} <span>{t('main:header.' + item.translate)}</span></a>
                        }
                        </li>
                    ))}
                </ul>
            </div>
            <div className="mobile-menu-social">
            <div className={"mobile-lang "  + (lang ? 'active' : '')} onClick={() => setLang(prev => !prev)}>
                <div className="mobile-lang-current"><span>{(user?.language || i18n.language) === 'ru' ? '' : (user?.language || i18n.language) === 'es' ? '' : (user?.language || i18n.language) === 'de' ? '' : (user?.language || i18n.language) === 'pt' ? '' : ''}{user?.language || i18n.language }</span><Arrow/></div>
                <div className={"mobile-lang-list"}>
                    {user?.language === 'en' || i18n.language === 'en' || user?.language === 'de' || i18n.language === 'de' || user?.language === 'pt' || i18n.language === 'pt' || i18n.language === 'es' ? <span onClick={() => {
                        dispatch(changeLang('ru'))
                        i18n.changeLanguage('ru')
                    }}>ru</span> : ''}
                    {user?.language === 'ru' || i18n.language === 'ru' || user?.language === 'de' || i18n.language === 'de' || user?.language === 'pt' || i18n.language === 'pt' || i18n.language === 'es' ? <span onClick={() => {
                        dispatch(changeLang('en'))
                        i18n.changeLanguage('en')
                    }}>en</span> : '' }
                    {user?.language === 'ru' || i18n.language === 'ru' || user?.language === 'en' || i18n.language === 'en' || user?.language === 'pt' || i18n.language === 'pt' || i18n.language === 'es' ? <span onClick={() => {
                        dispatch(changeLang('de'))
                        i18n.changeLanguage('de')
                    }}>de</span> : '' }
                    {user?.language === 'ru' || i18n.language === 'ru' || user?.language === 'en' || i18n.language === 'en' || user?.language === 'de' || i18n.language === 'de' || i18n.language === 'es' ? <span onClick={() => {
                        dispatch(changeLang('pt'))
                        i18n.changeLanguage('pt')
                    }}>pt</span> : ''}
                    {user?.language === 'ru' || i18n.language === 'ru' || user?.language === 'en' || i18n.language === 'en' || user?.language === 'de' || i18n.language === 'de'  || user?.language === 'pt' || i18n.language === 'pt' ? <span onClick={() => {
                        dispatch(changeLang('es'))
                        i18n.changeLanguage('es')
                    }}>es</span> : ''}
                </div>
            </div>
                <div className="links">
                    <a className={'mobile-menu-social-item'} rel="noreferrer" href="https://twitter.com/csgorace_com" target="_blank"><Twitter/></a>
                    <a className={'mobile-menu-social-item'} rel="noreferrer" href="tg://resolve?domain=csgorace" target="_blank"><Telegram/></a>
                    <a className={'mobile-menu-social-item'} href="https://www.instagram.com/csgorace_official/" rel="noreferrer" target="_blank"><Instagram/></a>
                    {/*<a className={'mobile-menu-social-item'} href="https://vk.com/csgorace_official" rel="noreferrer"  target="_blank"><Vk/></a>*/}
                </div>
            </div>
            <div className="mobile-menu-links">
                <Link onClick={() => dispatch(hideMobileMenu())} className="mobile-menu-links-item" to="/fair"><span>{t('main:footer.fair')}</span></Link>
                <Link onClick={() => dispatch(hideMobileMenu())} className="mobile-menu-links-item" to="/privacy"><span>{t('main:footer.privacy')}</span></Link>
                <Link onClick={() => dispatch(hideMobileMenu())} className="mobile-menu-links-item" to="/tos"><span>{t('main:footer.terms')}</span></Link>
                <Link onClick={() => dispatch(hideMobileMenu())} className="mobile-menu-links-item" to="/faq"><span>{t('main:footer.faq')}</span></Link>
            </div>
        </Popup>
    )
}