import React from 'react';

import coinImg from '../../../assets/coin.png'

import './MoneyBlock.scss'

const MoneyBlock = ({money, title, subtitle, icon}) => {
  return (
    <div className="money">
      {
        title || subtitle
        ?
          <div className="money__header">
            { title ? <div className="money__subtitle">{title}</div> : '' }
            { subtitle ? <div className="money__label">{subtitle}</div> : '' }
          </div>
        :
        ''
      }
      <div className="money__wrapper">
        {icon ? <img className="money__coin" src={coinImg} alt="C"/> : ''}
        <div className="money__num">{money}</div>
      </div>
    </div>
  );
};

export default MoneyBlock;